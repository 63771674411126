import React from 'react'
import { Box, Card, CardContent } from '@mui/material'
import LoadingSkeleton from '../../components/LoadingSkeleton'

/**
 * Represents a loading page component (when auth or app context is loading).
 * @component
 */
export const LoadingPage = ({ dataTestId }) => (
  <Card data-testid={dataTestId || 'loading-page'}>
    <CardContent sx={{ padding: 0, height: '90vh' }}>
      <Box sx={{ paddingX: 2, paddingTop: 1, width: '100%', height: '100%' }}>
        <LoadingSkeleton variant="rectangular" width="100%" height="90vh" />
      </Box>
    </CardContent>
  </Card>
)
