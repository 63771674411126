import React from 'react'
import { getClinicBenchmarkTrend } from '../../services/apis'
import { useToastContext } from '../../context/ToastContext/useToastContext'

/**
 * Custom hook for fetching clinic benchmark trend data.
 *
 * @param {Object} options - Options for the hook.
 * @param {boolean} options.defaultIsLoading - Default value for isLoading state.
 * @returns {Object} - An object containing isLoading, error, clinicTrendData, and fetchClinicBenchmarkTrend.
 */
export const useFetchClinicBenchmarkTrend = options => {
  const defaultIsLoading = options?.defaultIsLoading || false
  const { addToast } = useToastContext()

  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)

  const [clinicTrendData, setClinicTrendData] = React.useState([])
  /**
   * Fetches clinic benchmark trend data.
   *
   * @param {string} accessToken - Access token for authentication.
   * @param {string} errorToastId - ID of the error toast.
   * @param {Object} benchmarkTrendParams - Parameters for fetching benchmark trend data.
   * @param {string} benchmarkTrendParams.units_payload.rate - The rate unit of measurement.
   * @param {string} benchmarkTrendParams.units_payload.time_granularity - The time granularity.
   * @param {string} benchmarkTrendParams.scenarioid - The scenario ID.
   * @param {string} benchmarkTrendParams.metric - The metric value.
   * @param {string} benchmarkTrendParams.clinic_id - The clinic ID.
   * @returns {Promise<void>}
   */
  const fetchClinicBenchmarkTrend = React.useCallback(
    async ({ accessToken, errorToastId, benchmarkTrendParams }) => {
      try {
        setIsLoading(true)
        if (!accessToken || !errorToastId || !benchmarkTrendParams) {
          throw new Error('accessToken, errorToastId, and benchmarkTrendParams are required.')
        }
        const res = await getClinicBenchmarkTrend(accessToken, benchmarkTrendParams)
        setClinicTrendData(res?.data?.monthly_trend || [])
      } catch (e) {
        console.error(e)
        setError(e)
        // reset state to default
        setClinicTrendData([])
        // throw toast
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading clinic benchmark trend data - ${e?.message || 'unknown error'}`,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast],
  )

  return {
    isLoading,
    error,
    clinicTrendData,
    fetchClinicBenchmarkTrend,
  }
}
