import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'
import LoadingSkeleton from '../../../../../../components/LoadingSkeleton'
import { RadarChart } from '../../../../../../components/RadarChart/RadarChart'

// empty data set to show when no data is available
const FALLBACK_DATA = {
  labels: ['Appointments', 'Visits', 'Billable Visits', 'IE', 'Units'],
  datasets: [],
}

/**
 * Renders the BestInClassAnalysisGraph component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Indicates whether the component is in a loading state.
 * @param {string} props.title - The title of the graph.
 * @param {Object} props.radarChartProps - Additional optional props to pass to the Radar component.
 * @param {Object} props.loadingSkeletonProps - Additional optional props to pass to the LoadingSkeleton component.
 * @param {Object} props.data - The data for the chart. It should be an object with the following properties:
 *   - labels (array of strings): The labels for the chart.
 *   - datasets (array of objects): The datasets for the chart. Each object in the array should have the following properties:
 *     - label (string, required): The label for the dataset.
 *     - data (array of numbers or strings): The data points for the dataset.
 *     - fill (boolean, required): Whether to fill the area under the line.
 *     - backgroundColor (string, required): The background color for the dataset.
 *     - borderColor (string, required): The border color for the dataset.
 *     - pointBackgroundColor (string, required): The background color for the points.
 *     - pointBorderColor (string, required): The border color for the points.
 *     - pointHoverBackgroundColor (string, required): The background color for the points when hovered.
 *     - pointHoverBorderColor (string, required): The border color for the points when hovered.
 * @returns {JSX.Element} The rendered BestInClassAnalysisGraph component.
 */
export const BestInClassAnalysisGraph = ({
  isLoading,
  title,
  data,
  radarChartProps = {},
  loadingSkeletonProps = {},
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: { xs: 3, md: 0 },
        maxWidth: {
          xs: '500px',
          md: '100%',
        },
      }}
    >
      <Typography sx={{ fontSize: { xs: '20px', md: '18px', lg: '20px' }, fontWeight: 700, textAlign: 'center' }}>
        {title}
      </Typography>

      <RadarChart
        isLoading={isLoading}
        renderLoading={() => (
          <LoadingSkeleton variant="rectangular" width="98%" height="440px" {...loadingSkeletonProps} />
        )}
        data={data}
        fallbackData={FALLBACK_DATA}
        {...radarChartProps}
      />
    </Box>
  </Box>
)

BestInClassAnalysisGraph.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  radarChartProps: PropTypes.object,
  loadingSkeletonProps: PropTypes.object,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        fill: PropTypes.bool.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        borderColor: PropTypes.string.isRequired,
        pointBackgroundColor: PropTypes.string.isRequired,
        pointBorderColor: PropTypes.string.isRequired,
        pointHoverBackgroundColor: PropTypes.string.isRequired,
        pointHoverBorderColor: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }),
}
