import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'

import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useSidebarContext } from '../../../../context/SidebarContext/useSidebarContext'
import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { useFetchClinicBenchmarkTrend } from '../../../../hooks/useFetchClinicBenchmarkTrend/useFetchClinicBenchmarkTrend'
import { usePrevious } from '../../../../hooks/usePrevious'
import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import { targetClinicRadarPropTypes } from '../../clinicExplorer.proptypes'

/**
 * Renders the ClinicExplorerGraphWrapper component.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child function to call with to render props.
 * @param {number} props.defaultAppointmentDateYear - The default appointment date year.
 * @param {string} props.selectedClinicId - The selected clinic ID.
 * @param {string} props.selectedMetricValue - The selected metric value.
 * @param {Array} props.yearlyDataPerformanceScoreCard - The yearly data for the clinic.
 * @returns {React.ReactNode} The rendered component.
 */
export const ClinicExplorerGraphWrapper = ({
  children,
  defaultAppointmentDateYear,
  selectedClinicId,
  selectedMetricValue,
  yearlyDataPerformanceScoreCard,
}) => {
  const { accessToken } = useAuthContext()
  const { scenarioParamsWithMetadata } = useSidebarContext()
  const { selectedScenario, unitOfMeasurement, unitOfTime } = useFilterContext()

  const {
    isLoading: isLoadingGetClinicBenchmarkTrend,
    clinicTrendData,
    fetchClinicBenchmarkTrend,
  } = useFetchClinicBenchmarkTrend()

  const [selectedYear, setSelectedYear] = React.useState() // selectedYear is undefined or an integer

  const clinicBenchmarkTrendParams = React.useMemo(() => {
    if (
      !unitOfMeasurement ||
      !unitOfTime ||
      !selectedScenario?.scenarioid ||
      !selectedMetricValue ||
      !selectedClinicId
    ) {
      return
    }

    return {
      units_payload: {
        rate: unitOfMeasurement,
        time_granularity: unitOfTime,
      },
      scenarioid: selectedScenario?.scenarioid,
      metric: selectedMetricValue,
      clinic_id: selectedClinicId,
    }
  }, [unitOfMeasurement, unitOfTime, selectedScenario?.scenarioid, selectedMetricValue, selectedClinicId])

  /**
   * This useEffect will be called each time the cached object - clinicBenchmarkTrendParams - is updated.
   */
  React.useEffect(() => {
    if (accessToken && clinicBenchmarkTrendParams) {
      fetchClinicBenchmarkTrend({
        accessToken,
        errorToastId: TOAST_IDS.fetchClinicBenchMarkTrendErr,
        benchmarkTrendParams: clinicBenchmarkTrendParams,
      })
    }
  }, [clinicBenchmarkTrendParams, accessToken, fetchClinicBenchmarkTrend])

  const previousSelectedScenarioParams = usePrevious(scenarioParamsWithMetadata)
  const hasDetectedChangeInScenarioParams =
    !!previousSelectedScenarioParams && !isEqual(scenarioParamsWithMetadata, previousSelectedScenarioParams)
  const isChangeFromSelectedScenarioUpdate =
    previousSelectedScenarioParams?.scenario_metadata?.[0]?.scenarioid !==
    scenarioParamsWithMetadata?.scenario_metadata?.[0]?.scenarioid
  const shouldRefetchForScenarioParamUpdate = hasDetectedChangeInScenarioParams && !isChangeFromSelectedScenarioUpdate
  /**
   * This useEffect is used to refetch the clinic benchmark trend data when the scenario params are updated using the sidebar.
   * Note that we need this because the useEffect above does not cover this case becuase the params do not change
   */
  React.useEffect(() => {
    if (accessToken && clinicBenchmarkTrendParams && shouldRefetchForScenarioParamUpdate) {
      fetchClinicBenchmarkTrend({
        accessToken,
        errorToastId: TOAST_IDS.refetchClinicBenchMarkTrendErr,
        benchmarkTrendParams: clinicBenchmarkTrendParams,
      })
    }
  }, [clinicBenchmarkTrendParams, accessToken, fetchClinicBenchmarkTrend, shouldRefetchForScenarioParamUpdate])

  // evaluated values
  const yearList = React.useMemo(() => {
    return (
      scenarioParamsWithMetadata?.scenario_parameters
        ?.find(item => item.param === 'years')
        ?.param_value?.map(year => ({ name: Number(year), value: Number(year) }))
        ?.sort((a, b) => a?.value - b?.value) || []
    )
  }, [scenarioParamsWithMetadata?.scenario_parameters])

  const previousDefaultAppointmentYear = usePrevious(defaultAppointmentDateYear)
  React.useEffect(() => {
    const yearListValues = yearList?.map(item => item.value)

    if (
      previousDefaultAppointmentYear !== defaultAppointmentDateYear &&
      yearListValues.includes(defaultAppointmentDateYear)
    ) {
      setSelectedYear(defaultAppointmentDateYear)
      return
    }
    if (yearListValues?.length > 0 && !yearListValues.includes(selectedYear)) {
      /**
       * The following code addressed the edge case of the years scenario param being updated to remove the
       * currently selected year from the scenario params.  This will reset year in dropdown to default if selected year is no longer in list
       */
      setSelectedYear(defaultAppointmentDateYear || yearListValues[0])
    }
  }, [yearList, selectedYear, defaultAppointmentDateYear, previousDefaultAppointmentYear])

  const selectedYearSummaryData = React.useMemo(() => {
    return (
      yearlyDataPerformanceScoreCard
        ?.filter(opt => opt.appointment_date_year === selectedYear && opt.clinic_id === selectedClinicId)
        ?.map(filteredOpt => filteredOpt) || []
    )
  }, [yearlyDataPerformanceScoreCard, selectedYear, selectedClinicId])

  const handleYearSelect = e => {
    const selectedValueAsInt = parseInt(e.target.value, 10)
    setSelectedYear(selectedValueAsInt)
  }

  return children({
    clinicTrendData,
    handleYearSelect,
    isLoadingGetClinicBenchmarkTrend,
    selectedYear,
    selectedYearSummaryData,
    yearList,
  })
}

ClinicExplorerGraphWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  defaultAppointmentDateYear: PropTypes.number,
  selectedClinicId: PropTypes.string,
  selectedMetricValue: PropTypes.string,
  yearlyDataPerformanceScoreCard: PropTypes.arrayOf(PropTypes.shape(targetClinicRadarPropTypes)),
}
