import React from 'react'
import { Grid, Typography } from '@mui/material'

import EfficiencyTable from './pageComponents/efficiencyTable/EfficiencyTable'
import PerformanceDistribution from './pageComponents/PerformanceDistribution/PerformanceDistribution'
import BenchmarkTrendGraph from '../../components/BenchmarkTrendGraph/BenchmarkTrendGraph'
import OverviewMetricList from './overviewMetricList/OverviewMetricList'
import withRouterWrapper from '../../components/withRouterWrapper/withRouterWrapper'

const PAGE = 'overview'

const DEFAULT_METRIC_OPTION = {
  value: 'completed_appointments',
  name: 'Completed Appointments',
}

const Overview = () => {
  const [selectedMetric, setSelectedMetric] = React.useState(DEFAULT_METRIC_OPTION)
  const [selectedValueDisplay, setSelectedValueDisplay] = React.useState('actuals')
  const [isLoadingTrendSpinner, setIsLoadingTrendSpinner] = React.useState(true)
  const [isLoadingSpinnerDistribution, setIsLoadingSpinnerDistribution] = React.useState(false)

  return (
    <>
      <Grid container padding={0}>
        <Grid item xs={12} sx={{ backgroundColor: '#FFFFFF', padding: 2, minHeight: '100px', marginBottom: 1 }}>
          <Typography sx={{ fontWeight: 700 }}>Overview</Typography>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }} display="flex" alignItems="center">
            Efficiency Metrics
          </Typography>
        </Grid>
        <Grid item xs={12} padding={0} sx={{ marginBottom: 1 }}>
          <EfficiencyTable page={PAGE} />
        </Grid>
      </Grid>
      <Grid container sx={{ minHeight: '95px' }}>
        <Grid
          item
          xs={12}
          sm={6}
          lg={8}
          sx={{ backgroundColor: '#FFFFFF', padding: 2, display: 'flex', alignItems: 'center' }}
        >
          <Typography sx={{ fontSize: '20pt', fontWeight: 700 }}>Benchmark Trends</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={4}
          sx={{
            background: '#fff',
            paddingY: 2,
            paddingX: {
              xs: 2,
              md: 4,
            },
            display: 'flex',
            justifyContent: { xs: 'flex-start', sm: 'flex-end' },
          }}
        >
          <OverviewMetricList
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            isLoadingTrendSpinner={isLoadingTrendSpinner}
            isLoadingSpinnerDistribution={isLoadingSpinnerDistribution}
          />
        </Grid>
      </Grid>
      <Grid container padding={0}>
        <Grid item xs={12} sx={{ minHeight: '500px', marginTop: 1 }}>
          <BenchmarkTrendGraph
            selectedMetric={selectedMetric}
            selectedValueDisplay={selectedValueDisplay}
            handleSetSelectedValueDisplay={id => setSelectedValueDisplay(id)}
            isLoadingTrendSpinner={isLoadingTrendSpinner}
            setIsLoadingTrendSpinner={setIsLoadingTrendSpinner}
          />
        </Grid>
        <Grid item xs={12} sx={{ minHeight: '425px' }}>
          <PerformanceDistribution
            cardHeight="425px"
            graphHeight="275px"
            selectedMetric={selectedMetric}
            selectedValueDisplay={selectedValueDisplay}
            isLoadingSpinnerDistribution={isLoadingSpinnerDistribution}
            setIsLoadingSpinnerDistribution={setIsLoadingSpinnerDistribution}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default withRouterWrapper(Overview)({ shouldRedirectNewUser: true })
