import React from 'react'
import DimensionAnalysisCard from '../../../../components/dimensionAnalysisCard/dimensionAnalysisCard'
import { buildSelectedUOM } from '../../../../context/FilterContext/filterContextHelpers'
import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { useToastContext } from '../../../../context/ToastContext/useToastContext'
import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useAppContext } from '../../../../context/AppContext/useAppContext'
import { getBenchmarkByDimension } from '../../../../services/apis'
import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import ValueDisplaySelector from '../../../../components/valueDisplaySelector/valueDisplaySelector'
import { VALUE_DISPLAY_ID } from '../../../../components/valueDisplaySelector/valueDisplay.constants'
import { usePrevious } from '../../../../hooks/usePrevious'
import isEqual from 'lodash.isequal'
import { useSidebarContext } from '../../../../context/SidebarContext/useSidebarContext'
import TitledDropdown from '../../../../components/titledDropdown'
import LoadingSkeleton from '../../../../components/LoadingSkeleton'

const DimensionAnalysis = ({
  handleDimensionSelect,
  handleSetSelectedValueDisplay,
  selectedDimension,
  selectedValueDisplay,
  selectedMetric,
  isLoadingByDimension,
  setIsLoadingByDimension,
}) => {
  const { unitOfMeasurement, unitOfTime, selectedScenario, isLoadingFetchScenarios } = useFilterContext()
  const { addToast } = useToastContext()
  const { accessToken } = useAuthContext()
  const { menuOptions, isLoadingFetchMenuOptions } = useAppContext()
  const { scenarioParamsWithMetadata, isLoadingFetchScenarioParams } = useSidebarContext()
  const theme = useTheme()

  const [byDimensionData, setByDimensionData] = React.useState(null)

  const scenarioid = selectedScenario?.scenarioid

  const unitOfMeasure = React.useMemo(
    () => buildSelectedUOM({ unitOfMeasurement, unitOfTime }),
    [unitOfMeasurement, unitOfTime],
  )

  const dimensionList = React.useMemo(() => {
    const list =
      menuOptions
        ?.filter(opt => opt.menu === 'dimensions' && opt.option_id !== 'visit_type') // hide visit_type from the dimension list, which should be reviewed in depth in the MBM-383 ticket
        ?.map(filteredOpt => ({ value: filteredOpt.option_id, name: filteredOpt.option_value })) || []

    return [...list]?.sort((a, b) => a?.name?.localeCompare(b?.name)) || []
  }, [menuOptions])

  const fetchBenchmarkByDimension = React.useCallback(async () => {
    try {
      if (unitOfMeasurement && unitOfTime && scenarioid && selectedMetric?.value && selectedValueDisplay) {
        const getBenchmarkDimensions = {
          units_payload: {
            rate: unitOfMeasurement,
            time_granularity: unitOfTime,
          },
          scenarioid: scenarioid,
          metric: selectedMetric.value,
          value_display: selectedValueDisplay,
          dimension: selectedDimension.value,
        }
        setIsLoadingByDimension(true)
        const res = await getBenchmarkByDimension(getBenchmarkDimensions, accessToken)
        setByDimensionData(res.data)
      }
    } catch (error) {
      addToast({
        toastId: TOAST_IDS.getBenchmarkByDimensionForBenchmarkExplorer,
        variant: 'error',
        msg: `Error loading benchmark by dimension - ${error?.message || 'unknown error'}`,
      })
      setByDimensionData([])
    } finally {
      setIsLoadingByDimension(false)
    }
  }, [
    unitOfMeasurement,
    unitOfTime,
    scenarioid,
    selectedMetric?.value,
    selectedValueDisplay,
    selectedDimension?.value,
    accessToken,
    addToast,
    setIsLoadingByDimension,
  ])

  React.useEffect(() => {
    fetchBenchmarkByDimension()
  }, [fetchBenchmarkByDimension])

  const previousSelectedScenarioParams = usePrevious(scenarioParamsWithMetadata)
  React.useEffect(() => {
    // only run use effect if there were previously scenarioParamsWithMetadata defined && now the previous render values does not equal current value
    if (!!previousSelectedScenarioParams && !isEqual(scenarioParamsWithMetadata, previousSelectedScenarioParams)) {
      fetchBenchmarkByDimension()
    }
  }, [previousSelectedScenarioParams, fetchBenchmarkByDimension, scenarioParamsWithMetadata])

  const isLoading =
    isLoadingByDimension || isLoadingFetchMenuOptions || isLoadingFetchScenarioParams || isLoadingFetchScenarios

  return (
    <Grid
      container
      sx={{
        background: theme.palette.background.paper,
        padding: 2,
        alignItems: 'center',
      }}
    >
      <Grid item xs={12} sm={6} md={3} lg={4}>
        <Typography display="flex" alignItems="center">
          Dimension Analysis
        </Typography>
        <Typography color="#9FACB8" sx={{ fontWeight: 400, fontSize: 14 }}>
          {unitOfMeasure}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={4} sx={{ display: 'flex', justifyContent: { md: 'center' } }}>
        <Box sx={{ width: { xs: '100%', sm: '100%', md: '200px', lg: '350px' } }}>
          <TitledDropdown
            id="benchmark-explorer-dimension-dropdown"
            label="Select Dimension"
            isDisabled={isLoading}
            menuOptions={dimensionList}
            value={selectedDimension.value}
            onChange={handleDimensionSelect}
            selectStyles={{ borderRadius: '5px', width: '100%', height: '56px' }}
            formControlStyles={{ width: '100%' }}
            renderLoading={() => <LoadingSkeleton variant="rounded" height="56px" width="100%" />}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' } }}>
        <Box sx={{ maxWidth: '300px' }}>
          <ValueDisplaySelector
            handleValueDisplay={handleSetSelectedValueDisplay}
            selectedValueDisplay={selectedValueDisplay}
            disabled={isLoading}
            visibleButtons={[VALUE_DISPLAY_ID.actuals]}
          >
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'center' } }}>
              <Typography color="#9FACB8" sx={{ fontWeight: 400, fontSize: 14 }}>
                Value Display
              </Typography>
            </Box>
          </ValueDisplaySelector>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DimensionAnalysisCard
          data={byDimensionData}
          dimensionPrettyName={selectedDimension.name}
          selectedMetricPrettyName={selectedMetric.name}
          isLoading={isLoadingByDimension}
        />
      </Grid>
    </Grid>
  )
}

export default DimensionAnalysis
