import React from 'react'
import { useFetchTokenSilently } from './hooks/useFetchTokenSilently'
import { useAuth0 } from '@auth0/auth0-react'
import { useValidatePermissions } from './hooks/useValidateUserPermissions/useValidateUserPermissions'

export const AuthContext = React.createContext()

/**
 * Provides authentication context for the application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export const AuthContextProvider = ({ children }) => {
  const { getAccessTokenSilently, loginWithRedirect, getIdTokenClaims } = useAuth0()

  const {
    isLoading: isLoadingFetchTokenSilently,
    error: fetchTokenSilentlyError,
    accessToken,
    fetchTokenSilently,
  } = useFetchTokenSilently({
    defaultIsLoading: true,
    getAccessTokenSilently,
    loginWithRedirect,
  })

  const {
    isLoading: isLoadingValidatePermissions,
    error: validatePermissionsError,
    isAuthorizedWithPermission,
    validatePermissions,
  } = useValidatePermissions({
    defaultIsLoading: true,
    getIdTokenClaims,
  })

  React.useEffect(() => {
    if (accessToken) return
    fetchTokenSilently()
  }, [accessToken, fetchTokenSilently])

  React.useEffect(() => {
    if (!accessToken) return
    validatePermissions({ accessToken })
  }, [accessToken, validatePermissions])

  return (
    <AuthContext.Provider
      value={{
        isLoadingFetchTokenSilently,
        fetchTokenSilentlyError,
        accessToken,
        isLoadingValidatePermissions,
        validatePermissionsError,
        isAuthorizedWithPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
