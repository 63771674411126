import React from 'react'
import { ToastContext } from './ToastContext'

/**
 * A custom hook for adding/removing/viewing open toasts
 * @returns {Object} The Toast Context
 * @property {function} addToast - Adds a toast to the stack
 * @property {function} removeToast - Removes a toast from the stack
 * @property {Array} toasts - The array of toasts
 */
export const useToastContext = () => {
  /**
   * @constant {Object} context - The context object
   * @type {Object}
   * @property {function} addToast - Adds a toast to the stack
   * @property {function} removeToast - Removes a toast from the stack
   * @property {Array} toasts - The array of toasts
   */
  const context = React.useContext(ToastContext)

  if (context === undefined) {
    throw new Error('useToastContext must be used within Toast Context Provider')
  }

  return context
}
