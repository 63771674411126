import React from 'react'
import PropTypes from 'prop-types'
import Edit from '@mui/icons-material/Edit'
import { Box, IconButton } from '@mui/material'

import DeleteScenario from './DeleteScenario'
import { useFilterContext } from '../../../../../context/FilterContext/useFilterContext'
import { DefaultScenario } from './DefaultScenario/DefaultScenario'
import { StaticScrollTable } from '../../../../../components/Table'
import {
  SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
  SORT_COLUMN_BY_BOOLEAN,
} from '../../../../../components/Table/tableSort'

/**
 * Renders a table component for displaying Scenarios.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isUnitTest - A flag to determine if the component is being rendered in a unit test.
 * @param {Function} props.handleEditRowClick - The function to handle when a row is clicked.
 * @returns {JSX.Element} The rendered table component.
 */
export function ScenarioTable({ handleEditRowClick }) {
  const { isLoadingFetchScenarios, scenarios } = useFilterContext()

  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        header: 'ID', // The text to display in the header cell
        accessorFn: row => row.id, // How the data should be accessed from the row object
        cell: info => info.getValue(), // How the data should be displayed in the cell
        enableSorting: true,
        sortDescFirst: false, // without this, the id is sorted in descending order first for some reason :shrug
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 1, // To style width of column -> This is the shorthand for flex-grow, flex-shrink and flex-basis combined (see https://css-tricks.com/snippets/css/a-guide-to-flexbox/)
      },
      {
        id: 'scenario_title',
        header: 'Scenario Name',
        accessorFn: row => row.scenario_title,
        cell: info => info.getValue(),
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 3,
      },
      {
        id: 'portfolio_title',
        header: 'Portfolio',
        accessorFn: row => row.portfolio_title,
        cell: info => info.getValue(),
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 3,
      },
      {
        id: 'default_scenario_flag',
        accessorFn: row => row.default_scenario_flag,
        header: () => <Box paddingX={1}>Default Scenario</Box>,
        cell: params => (
          <Box sx={{ width: '100%', height: '100%', paddingX: 1 }}>
            <DefaultScenario row={params.row.original} />
          </Box>
        ),
        enableSorting: true,
        invertSorting: true, // this makes it so that the "true" value appears first
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_BOOLEAN,
        flex: 2,
      },
      {
        id: 'edit',
        accessorKey: 'Edit',
        header: () => <Box paddingX={1}>Edit</Box>,
        cell: params => (
          <IconButton
            data-testid={`edit-portfolio-${params.row.original.portfolioid}`}
            onClick={() => handleEditRowClick(params.row.original)}
            sx={{ color: '#707070', cursor: 'pointer', padding: 1 }}
          >
            <Edit />
          </IconButton>
        ),
        enableSorting: false,
        flex: 1,
      },
      {
        id: 'delete',
        accessorKey: 'Delete',
        header: 'Delete',
        cell: params => (
          <DeleteScenario row={params.row.original} iconButtonStyles={{ padding: 1 }} /> // what renders in a given table cell
        ),
        enableSorting: false,
        flex: 1,
      },
    ],
    [handleEditRowClick],
  )

  return (
    <StaticScrollTable
      memoizedData={scenarios}
      memoizedColumns={columns}
      isLoading={isLoadingFetchScenarios}
      tableHeight={310}
      noRecordsOverlay="No scenarios found."
      initialSortState={[{ id: 'default_scenario_flag', desc: false }]}
    />
  )
}

ScenarioTable.propTypes = {
  handleEditRowClick: PropTypes.func.isRequired,
}
