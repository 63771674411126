import React from 'react'
import { Box, Button, Card, Grid, Typography, useTheme } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

/**
 * Renders the Unauthorized page component.
 * This component displays a message indicating that the user is unable to login because auth0 metadata does not include benchmarking permission.
 * It also provides a logout button to allow the user to log out of the application.
 */
export const Unauthorized = () => {
  const theme = useTheme()
  const { logout } = useAuth0()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ margin: 2 }}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, padding: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '90vh', width: '100%', alignItems: 'center' }}>
            <Typography component="p" align="left" color="text.primary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Unable to login, please check with your administrator to gain access to Market Benchmarking.
            </Typography>
            <Box sx={{ height: '100%' }}>
              <Button
                variant="contained"
                role="button"
                onClick={() =>
                  logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  })
                }
                sx={{ maxWidth: '200px' }}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}
