import React from 'react'
import { Box, Button, Grid, Skeleton } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

/**
 * Profile component that displays user profile information and logout button.
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Flag indicating if the component is in a loading state.
 * @returns {JSX.Element|null} The rendered profile component or null if user is not authenticated.
 */
export const ProfileComponent = ({ isLoading }) => {
  const { logout, isAuthenticated } = useAuth0()

  if (!isLoading && !isAuthenticated) {
    return null
  }

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })

  return (
    <Box>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          padding: 2,
          minHeight: '10vh',
          marginBottom: '3px',
          width: '100%',
        }}
      >
        <Grid>
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={'74px'}
              height="36px"
              data-testid="loading-logout-btn"
            />
          ) : (
            <Button variant="contained" onClick={() => logoutWithRedirect()}>
              Logout
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
