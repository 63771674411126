import React from 'react'
import { Slider } from '@mui/material'
import PropTypes from 'prop-types'

/**
 * A custom slider component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.defaultLowerValue - The default lower value of the slider.
 * @param {number} props.defaultUpperValue - The default upper value of the slider.
 * @param {Function} props.handleChange - The callback function to handle slider change.
 * @param {Object} ...sliderProps - Additional props for the Slider component.
 * @returns {JSX.Element} The SliderStyle component.
 */
export const SliderStyle = ({ defaultLowerValue, defaultUpperValue, handleChange, ...sliderProps }) => {
  const [lowerVal, setLowerValue] = React.useState(defaultLowerValue)
  const [upperVal, setUpperValue] = React.useState(defaultUpperValue)

  React.useEffect(() => {
    // if the default values change, then update the state to reflect change in default values
    setLowerValue(defaultLowerValue)
    setUpperValue(defaultUpperValue)
  }, [defaultLowerValue, defaultUpperValue])

  /**
   * Handles the change event of the slider.
   *
   * @param {Event} event - The change event object.
   * @returns {void}
   */
  const handleSliderChange = event => {
    const change_range = event.target.value
    setLowerValue(change_range[0])
    setUpperValue(change_range[1])
    handleChange({ lowerValue: change_range[0], upperValue: change_range[1], event })
  }

  return (
    <>
      <Slider
        {...sliderProps}
        getAriaLabel={() => 'Slider'}
        size={sliderProps.size || 'small'}
        value={[lowerVal, upperVal]}
        valueLabelDisplay="auto"
        onChange={handleSliderChange}
      />
    </>
  )
}

const sliderProps = {
  disabled: PropTypes.bool,
  marks: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number, label: PropTypes.node })),
  ]),
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  name: PropTypes.string, // Name attribute of the hidden input element.
  onChange: PropTypes.func, // function(event: Event, value: number | Array, activeThumb: number) => void
  step: PropTypes.number, // The granularity with which the slider can step through values. (A "discrete" slider.
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]), // The value of the slider.
}

SliderStyle.propTypes = {
  ...sliderProps,
  marks: PropTypes.bool.isRequired,
  defaultLowerValue: PropTypes.number.isRequired,
  defaultUpperValue: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired, // function({ lowerValue: number, upperValue: number, event: Object }) => void
}
