import React from 'react'
import { useFilterContext } from '../FilterContext/useFilterContext/useFilterContext'
import { useAuthContext } from '../AuthContext/useAuthContext'
import { useFetchPorfolios } from './hooks/useFetchPortfolios'
import { useFetchScenarioParams } from './hooks/useFetchScenarioParams/useFetchScenarioParams'
import { TOAST_IDS } from '../ToastContext/constants'

/**
 * Context object for the sidebar component.
 * @type {React.Context}
 */
export const SidebarContext = React.createContext()

/**
 * Provides the sidebar context for the application.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components.
 * @returns {React.ReactNode} The rendered component.
 */
export const SidebarContextProvider = ({ children }) => {
  const { accessToken } = useAuthContext()
  const { selectedScenario } = useFilterContext()
  const scenarioId = selectedScenario?.scenarioid

  const {
    isLoading: isLoadingFetchPortfolios,
    portfoliosSorted,
    fetchPortfolios,
  } = useFetchPorfolios({ defaultIsLoading: true })

  const hasPerformedInitialFetchPortfolios = React.useRef(false)
  React.useEffect(() => {
    if (!accessToken || hasPerformedInitialFetchPortfolios.current) return

    hasPerformedInitialFetchPortfolios.current = true
    fetchPortfolios({ accessToken, errorToastId: TOAST_IDS.getPortfolioListErrorForSidebar })
  }, [accessToken, fetchPortfolios])

  const {
    isLoading: isLoadingFetchScenarioParams,
    scenarioParamsWithMetadata: scenarioParamsWithMetadata,
    fetchScenarioParams,
  } = useFetchScenarioParams({ defaultIsLoading: true })

  React.useEffect(() => {
    if (!accessToken || !scenarioId) return
    fetchScenarioParams({ accessToken, scenarioId, errorToastId: TOAST_IDS.getScenarioParamsErrorForSidebarContext })
  }, [fetchScenarioParams, accessToken, scenarioId])

  return (
    <SidebarContext.Provider
      value={{
        // portfolios
        isLoadingFetchPortfolios,
        portfoliosSorted,
        refetchPortfolios: fetchPortfolios,
        // scenario params
        isLoadingFetchScenarioParams,
        scenarioParamsWithMetadata,
        refetchScenarioParams: fetchScenarioParams,
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}
