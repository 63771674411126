import React from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardContent, Grid } from '@mui/material'

import ClinicPerformanceGraph from './ClinicPerformanceGraph'
import TitledDropdown from '../../../../../components/titledDropdown'
import ClinicStats from './ClinicStats'
import LoadingSkeleton from '../../../../../components/LoadingSkeleton'
import { FALLBACK_CLINIC_GRAPH_DATA, FALLBACK_CLINIC_STATS } from '../../../clinicExplorer.constants'

/**
 * Renders the Single Clinic Trend component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.clinicGraphData - The data for the clinic performance graph.
 * @param {Object} props.clinicStats - The statistics for the clinic.
 * @param {Function} props.handleYearSelect - The function to handle year selection.
 * @param {boolean} props.isLoadingClinicGraphData - Indicates if the clinic graph data is loading.
 * @param {boolean} props.isLoadingClinicStats - Indicates if the clinic stats are loading.
 * @param {string} props.selectedBenchmark - The selected benchmark.
 * @param {string} props.selectedYear - The selected reporting year.
 * @param {boolean} [props.shouldDisableYearDropdown=false] - Indicates if the year dropdown should be disabled.
 * @param {Array} props.yearList - The list of available reporting years.
 * @returns {JSX.Element} The rendered Single Clinic Trend component.
 */
export const SingleClinicTrend = ({
  clinicGraphData,
  clinicStats,
  handleYearSelect,
  isLoadingClinicGraphData,
  isLoadingClinicStats,
  selectedBenchmark,
  selectedYear,
  shouldDisableYearDropdown = false,
  yearList,
}) => (
  <Card sx={{ padding: 2, minHeight: '516px' }}>
    <CardContent>
      <Grid container>
        <Grid item xs={12} md={4} lg={5}>
          <Box sx={{ maxWidth: { xs: '400px', lg: '300px' } }}>
            <TitledDropdown
              formControlStyles={{ width: '100%' }}
              id="single-clinic-trend-year-dropdown"
              isDisabled={shouldDisableYearDropdown}
              isLoading={isLoadingClinicStats}
              label="Year"
              menuOptions={yearList}
              onChange={handleYearSelect}
              renderLoading={() => (
                <LoadingSkeleton variant="rounded" height="56px" data-testid="loading-single-clinic-year-dropdown" />
              )}
              selectStyles={{ borderRadius: '5px', width: '100%', height: '56px' }}
              value={selectedYear}
            />
          </Box>
          <Box sx={{ maxWidth: '400px', width: '100%' }}>
            <ClinicStats isLoading={isLoadingClinicStats} data={clinicStats} fallbackData={FALLBACK_CLINIC_STATS} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={7}>
          <ClinicPerformanceGraph
            data={clinicGraphData}
            fallbackData={FALLBACK_CLINIC_GRAPH_DATA}
            isLoading={isLoadingClinicGraphData}
            selectedBenchmark={selectedBenchmark}
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
)

SingleClinicTrend.propTypes = {
  clinicGraphData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        backgroundColor: PropTypes.string,
        borderColor: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number),
        label: PropTypes.string,
        year: PropTypes.number,
      }).isRequired,
    ),
  }),
  clinicStats: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
  ),
  handleYearSelect: PropTypes.func,
  isLoadingClinicGraphData: PropTypes.bool.isRequired,
  isLoadingClinicStats: PropTypes.bool.isRequired,
  selectedBenchmark: PropTypes.oneOf([
    'Scheduled Appointments',
    'Completed Appointments',
    'Billable Visits',
    'IE Visits',
    'Units per Visit',
    'IE to Visit Ratio',
  ]),
  selectedYear: PropTypes.number,
  shouldDisableYearDropdown: PropTypes.bool,
  yearList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ),
}
