import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CreateScenarioForm, {
  initialValuesForCreateScenarioForm,
  createScenarioValidationSchema,
  initialTouchedForCreateScenarioForm,
} from '../../../../../forms/CreateScenarioForm'
import { Formik } from 'formik'
import { Save } from '@mui/icons-material'
import BaseModalContent from '../../../../../components/modals/BaseModalContent'
import StyledFormDialog from '../../../../../components/modals/FormModal/StyledFormDialog'
import { TOAST_IDS } from '../../../../../context/ToastContext/constants'
import { useToastContext } from '../../../../../context/ToastContext/useToastContext'
import { useAuthContext } from '../../../../../context/AuthContext/useAuthContext'
import { createScenario } from '../../../../../services/apis'
import { ApiError } from '../../../../../utils/ApiError'
import ErrorMessage from '../../../../../components/ErrorMessage'
import { useAppContext } from '../../../../../context/AppContext/useAppContext'
import { useFilterContext } from '../../../../../context/FilterContext/useFilterContext'

/**
 * We use a no-op function to handle the onClose event for the modal
 * to require the member to either click the "X" button or to click the "Cancel" button.
 * With this function, users will not be able to click "ESC" key to exit or click outside the modal to exit.
 *
 */
const blockExitFromModal = () => undefined

/**
 * Renders the Create Scenario Modal & Form.
 *
 * @returns {React.ReactNode} The Create Scenario component.
 */
export function CreateScenario() {
  const { defaultScenarioId, updateDefaultScenarioId } = useAppContext()
  const { addToast } = useToastContext()
  const { accessToken } = useAuthContext()
  const { refetchScenarios, updateSelectedScenario } = useFilterContext()

  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false)
  const [formLevelError, setFormLevelError] = React.useState(undefined)

  const handleSubmit = async (formikValues, formikActions) => {
    setFormLevelError(undefined)
    const { setFieldError } = formikActions
    try {
      const res = await createScenario({
        accessToken,
        data: {
          details: {
            scenario_title: formikValues.scenario_title,
          },
          scenario_params: {
            visit_type: [formikValues.visit_type],
            therapist_type: formikValues.therapist_type,
            therapy_type: [formikValues.therapy_type],
            clinic_size: { min: formikValues.clinic_size[0], max: formikValues.clinic_size[1] },
            portfolioid: formikValues.portfolioid,
            years: formikValues.years,
          },
        },
      })
      const createdScenarioId = res?.data?.scenario_metadata?.scenarioid
      addToast({
        toastId: TOAST_IDS.createScenarioSuccess,
        variant: 'success',
        msg: `Scenario "${res?.data?.scenario_metadata?.scenario_title}" was created successfully.`,
      })

      // We only update the default scenario id if the user does not have a default scenario yet
      if (!defaultScenarioId) {
        updateDefaultScenarioId(createdScenarioId)
      }

      // // When a scenario is created, we must update the filter bar to contain scenario in dropdown
      const updatedScenarios = await refetchScenarios({
        accessToken,
        errorToastId: TOAST_IDS.refetchScenariosErrorForCreateScenarioCard,
      })
      const newSelectedScenario = updatedScenarios.find(scenario => scenario.scenarioid === createdScenarioId)
      // Because the user will most likely want to see the graphs associated with new scenario, we update the selected scenario to equal this new scenario
      updateSelectedScenario({
        scenario: newSelectedScenario,
        errorToastId: TOAST_IDS.updateErrorForCreateScenarioCard,
      })

      setIsCreateDialogOpen(false)
    } catch (error) {
      if (error instanceof ApiError) {
        if (['scenario_title'].includes(error?.errorTarget)) {
          return setFieldError(error.errorTarget, error?.message)
        }
      }

      setFormLevelError(error.message)
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="info"
          variant="contained"
          role="button"
          onClick={() => setIsCreateDialogOpen(true)}
          sx={{ maxWidth: '200px' }}
        >
          Create Scenario
        </Button>
      </Box>
      {/* 
          Note that StyledFormDialog does not render unless isCreateDialogOpen is true.
          Then, StyledFormDialog will render as a React portal. 
      */}
      <StyledFormDialog onClose={blockExitFromModal} open={isCreateDialogOpen}>
        <Formik
          initialValues={initialValuesForCreateScenarioForm}
          initialTouched={initialTouchedForCreateScenarioForm}
          onSubmit={handleSubmit}
          validationSchema={createScenarioValidationSchema}
        >
          {formikProps => {
            return (
              <BaseModalContent
                handleClickCloseButton={() => setIsCreateDialogOpen(false)}
                hasContentDividers={true}
                modalTitle="Create Scenario"
                closeIconBtnAttributes={{
                  'data-testid': 'create-scenario-close-button',
                  disabled: formikProps.isSubmitting,
                }}
                renderModalFooter={() => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingX: 1,
                      marginY: 1,
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    {formLevelError && (
                      <ErrorMessage sx={{ fontSize: { xs: '12px', sm: '14px' }, paddingRight: 1 }}>
                        {formLevelError}
                      </ErrorMessage>
                    )}
                    <Box sx={{ minWidth: '240px' }}>
                      <Button
                        variant="outlined"
                        onClick={() => setIsCreateDialogOpen(false)}
                        sx={{ marginRight: 1 }}
                        color="error"
                        disabled={formikProps.isSubmitting}
                        data-testid="create-scenario-cancel-button"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<Save />}
                        onClick={formikProps.handleSubmit}
                        disabled={formikProps.isSubmitting}
                        data-testid="create-scenario-save-button"
                      >
                        Save Scenario
                      </Button>
                    </Box>
                  </Box>
                )}
              >
                <CreateScenarioForm {...formikProps} />
              </BaseModalContent>
            )
          }}
        </Formik>
      </StyledFormDialog>
    </>
  )
}
