import React from 'react'
import { Box, Typography } from '@mui/material'
import { Formik } from 'formik'

import { useSidebarContext } from '../../context/SidebarContext/useSidebarContext'
import { useFilterContext } from '../../context/FilterContext/useFilterContext'

import { runBenchmark, updateScenario } from '../../services/apis'
import { useToastContext } from '../../context/ToastContext/useToastContext'
import { TOAST_IDS } from '../../context/ToastContext/constants'
import { useAuthContext } from '../../context/AuthContext/useAuthContext'
import EditScenarioForm, {
  editScenarioValidationSchema,
  initialTouchedForEditScenarioForm,
  parseInValuesForEditScenario,
  parseOutValuesForEditScenario,
} from '../../forms/EditScenarioForm'
import FormButton from '../../forms/FormButton'
import { initialStatusForEditScenarioForm } from '../../forms/EditScenarioForm/EditScenarioForm'
import { ApiError } from '../../utils/ApiError'

const checkShouldRefetchScenarios = ({ initialValuesForEditScenarioForm, scenarioParams }) => {
  return (
    initialValuesForEditScenarioForm?.portfolioid !== scenarioParams?.portfolioid ||
    initialValuesForEditScenarioForm?.scenario_title !== scenarioParams?.scenario_title
  )
}

export const Sidebar = () => {
  const { addToast } = useToastContext()
  const { accessToken } = useAuthContext()
  const { selectedScenario, refetchScenarios } = useFilterContext()
  const { isLoadingFetchScenarioParams, scenarioParamsWithMetadata, refetchScenarioParams } = useSidebarContext()

  const scenarioId = selectedScenario?.scenarioid

  const initialValuesForEditScenarioForm = parseInValuesForEditScenario(scenarioParamsWithMetadata)

  const handleSubmit = async (formikValues, { setFieldError }) => {
    try {
      const payload = parseOutValuesForEditScenario({ formikValues, scenarioId })
      const updatedScenario = await updateScenario({ accessToken, data: payload })
      const scenarioParams = updatedScenario?.scenario_parameters || {}

      // runBenchmark is required to update the cache tables in the backend so the content is updated
      await runBenchmark(accessToken, { scenarioid: scenarioId })

      const shouldRefetchScenarios = checkShouldRefetchScenarios({
        initialValuesForEditScenarioForm,
        scenarioParams,
      })

      await Promise.all([
        refetchScenarioParams({
          accessToken,
          scenarioId: scenarioId,
          errorToastId: TOAST_IDS.refetchScenarioParamsErrorForSidebarComponent,
        }),
        shouldRefetchScenarios
          ? refetchScenarios({
              accessToken,
              errorToastId: TOAST_IDS.refetchScenariosErrorForSidebarComponent,
              nextSelectedScenarioId: scenarioId,
            })
          : Promise.resolve(),
      ])

      addToast({
        toastId: TOAST_IDS.updateScenarioParamsSuccess,
        variant: 'success',
        msg: `Update to scenario parameters was successful.`,
      })
    } catch (error) {
      console.error(error)
      if (error instanceof ApiError) {
        if (['scenario_title'].includes(error?.errorTarget)) {
          return setFieldError(error.errorTarget, error?.message)
        }
      }
      addToast({
        toastId: TOAST_IDS.updateScenarioError,
        variant: 'error',
        msg: `Error updating scenario parameters - ${error?.message || 'unknown error'}`,
      })
    }
  }

  return (
    <Box
      sx={{
        position: 'static',
        borderRight: '0',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box',
        paddingX: {
          xs: 2,
          sm: 14,
          md: 2,
        },
        paddingY: 2,
        width: '100%',
        height: '100%',
      }}
    >
      <Typography textAlign="center" sx={{ paddingBottom: 1, fontSize: '16px', fontWeight: 'bold' }}>
        Benchmarking Parameters
      </Typography>

      <Formik
        initialValues={initialValuesForEditScenarioForm}
        initialTouched={initialTouchedForEditScenarioForm}
        initialStatus={initialStatusForEditScenarioForm}
        onSubmit={handleSubmit}
        validationSchema={editScenarioValidationSchema}
        enableReinitialize={true} // allows the form to update initialValues when the scenarioParamsWithMetadata changes
      >
        {formikProps => {
          return (
            <EditScenarioForm
              {...formikProps}
              isLoading={isLoadingFetchScenarioParams}
              shouldDisplayClinicTable={false}
              renderFormButtons={formikProps => (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ paddingX: 2 }}>
                    <FormButton
                      fullWidth
                      onClick={e => {
                        formikProps.setStatus({
                          shouldReset: true,
                        })
                        formikProps.resetForm(e)
                      }}
                      disabled={formikProps.isSubmitting}
                    >
                      Reset
                    </FormButton>
                  </Box>
                  <Box sx={{ paddingX: 2 }}>
                    <FormButton fullWidth type="submit" disabled={formikProps.isSubmitting}>
                      Update
                    </FormButton>
                  </Box>
                </Box>
              )}
            />
          )
        }}
      </Formik>
    </Box>
  )
}
