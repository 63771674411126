/**
 * @function parseInValuesForEditScenario
 * @description Parses the initial values for the edit scenario form.  It assumes that the argument follows the format of scenarioParamsWithMetadata
 * @param {Object} scenarioParamsWithMetadata
 * @param {Object} scenarioParamsWithMetadata.scenario_metadata - The scenario metadata.
 * @param {Object} scenarioParamsWithMetadata.scenario_metadata[0].scenario_title - The scenario title.
 * @param {Array} scenarioParamsWithMetadata.scenario_parameters - The scenario parameters.
 * @param {Object} scenarioParamsWithMetadata.scenario_parameters[0].param - The parameter name.
 * @param {Object} scenarioParamsWithMetadata.scenario_parameters[0].param_value - The parameter value.
 * @returns {Object} The initial values object for the edit scenario form with the following properties:
 * @property {Array} clinic_size - The clinic size.
 * @property {String} portfolioid - The portfolio ID.
 * @property {String} scenario_title - The scenario title.
 * @property {Array} therapist_type - The therapist type.
 * @property {String} therapy_type - The therapy type.
 * @property {String} visit_type - The visit type.
 * @property {Array} years - The years.
 */
export const parseInValuesForEditScenario = scenarioParamsWithMetadata => {
  const clinicSizeRecord = scenarioParamsWithMetadata?.scenario_parameters?.find(p => p.param === 'clinic_size')
    ?.param_value || { max: 0, min: 0 }

  const clinic_size = [clinicSizeRecord.min, clinicSizeRecord.max]
  const portfolioid =
    scenarioParamsWithMetadata?.scenario_parameters?.find(p => p.param === 'portfolioid')?.param_value || ''
  const scenario_title = scenarioParamsWithMetadata?.scenario_metadata?.[0]?.scenario_title || ''
  const therapist_type =
    scenarioParamsWithMetadata?.scenario_parameters?.find(p => p.param === 'therapist_type')?.param_value || []
  const therapy_type =
    scenarioParamsWithMetadata?.scenario_parameters?.find(p => p.param === 'therapy_type')?.param_value?.[0] || ''
  const visit_type =
    scenarioParamsWithMetadata?.scenario_parameters?.find(p => p.param === 'visit_type')?.param_value?.[0] || ''
  const years =
    scenarioParamsWithMetadata?.scenario_parameters
      ?.find(p => p.param === 'years')
      ?.param_value?.map(item => item.toString()) || []

  return {
    clinic_size,
    portfolioid,
    scenario_title,
    therapist_type,
    therapy_type,
    visit_type,
    years,
  }
}
