export const UNIT_OF_MEASURE_OPTION_VALUE = {
  per_provider: 'per_provider',
  per_clinic: 'per_clinic',
}

export const UNIT_OF_TIME_OPTION_VALUE = {
  quarter: 'quarter',
  month: 'month',
  week: 'week',
}

export const UNIT_NAME_MAP_BY_OPTION_VALUE = {
  per_provider: 'Per Provider',
  per_clinic: 'Per Clinic',
  quarter: 'Per Quarter',
  month: 'Per Month',
  week: 'Per Week',
}
