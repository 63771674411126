import React from 'react'
import PropTypes from 'prop-types'
import Delete from '@mui/icons-material/Delete'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'

import { deletePortfolio } from '../../../../../../services/apis'
import { useAuthContext } from '../../../../../../context/AuthContext/useAuthContext'
import { useSidebarContext } from '../../../../../../context/SidebarContext/useSidebarContext'
import { TOAST_IDS } from '../../../../../../context/ToastContext/constants'
import { useToastContext } from '../../../../../../context/ToastContext/useToastContext'
import { IconButton } from '@mui/material'

/**
 * Component for deleting a portfolio.
 * @param {Object} props - The component props.
 * @param {Object} props.iconButtonStyles - The styles for the icon button.
 * @param {Object} props.row - The row data for the portfolio.
 * @param {Object} props.row.create_date - The creation date of the portfolio.
 * @param {Object} props.row.created_by_userid - The user ID of the creator.
 * @param {Object} props.row.list_of_clinics - The list of clinics associated with the portfolio.
 * @param {Object} props.row.portfolio_description - The description of the portfolio.
 * @param {Object} props.row.portfolio_title - The title of the portfolio.
 * @param {Object} props.row.portfolioid - The ID of the portfolio.
 * @returns {JSX.Element} The delete portfolio component.
 */
export const DeletePortfolio = ({ iconButtonStyles = {}, row }) => {
  const { accessToken } = useAuthContext()
  const { refetchPortfolios } = useSidebarContext()
  const { addToast } = useToastContext()
  const [isLoading, setIsLoading] = React.useState(false)

  /**
   * Handles the click event when deleting a row.
   * @param {Object} row - The row data for the portfolio.
   * @returns {Promise<void>} A promise that resolves when the portfolio is deleted.
   */
  const handleDeleteRowClick = async row => {
    try {
      setIsLoading(true)
      const deletedPortfolio = await deletePortfolio({ accessToken: accessToken, portfolioid: row.portfolioid })
      addToast({
        toastId: TOAST_IDS.deletePortfolioSuccess,
        variant: 'success',
        msg: `The portfolio "${deletedPortfolio.portfolio_title}" has been successfully deleted.`,
      })

      // refetch portfolios on success to update table rows
      await refetchPortfolios({ accessToken, errorToastId: TOAST_IDS.refetchPortfoliosOnDeletePortfolioError })
    } catch (error) {
      console.error(error)
      addToast({
        toastId: TOAST_IDS.deletePortfolioError,
        variant: 'error',
        msg: error.message,
        truncateLength: 300, // increase truncate length so that more scenario names are visible with 422 error
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <DeleteTwoToneIcon data-testid={`delete-portfolio-loading-${row.portfolioid}`} sx={{ color: '#707070' }} />
  }

  return (
    <IconButton
      data-testid={`delete-portfolio-${row.portfolioid}`}
      onClick={() => handleDeleteRowClick(row)}
      sx={{ color: '#707070', cursor: 'pointer', padding: 0, ...iconButtonStyles }}
    >
      <Delete />
    </IconButton>
  )
}

DeletePortfolio.propTypes = {
  iconButtonStyles: PropTypes.object,
  row: PropTypes.shape({
    create_date: PropTypes.string.isRequired,
    created_by_userid: PropTypes.string.isRequired,
    list_of_clinics: PropTypes.arrayOf(PropTypes.string).isRequired,
    portfolio_description: PropTypes.string,
    portfolio_title: PropTypes.string.isRequired,
    portfolioid: PropTypes.string.isRequired,
  }).isRequired,
}
