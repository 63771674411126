import React from 'react'
import PropTypes from 'prop-types'
import Edit from '@mui/icons-material/Edit'
import omit from 'lodash.omit'
import { Box, IconButton } from '@mui/material'

import { useSidebarContext } from '../../../../../../context/SidebarContext/useSidebarContext'

import DeletePortfolio from '../DeletePortfolio'
import { StaticScrollTable } from '../../../../../../components/Table'
import { SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE } from '../../../../../../components/Table/tableSort'

const TABLE_HEIGHT = 310

/**
 * Renders a table component for displaying portfolios.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleEditRowClick - The function to handle when a row is clicked.
 * @returns {JSX.Element} The rendered table component.
 */
export function StaticPortfolioTable({ handleEditRowClick }) {
  const { isLoadingFetchPortfolios, portfoliosSorted } = useSidebarContext()

  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        header: 'ID', // The text to display in the header cell
        accessorFn: row => row.id, // How the data should be accessed from the row object
        cell: info => info.getValue(), // How the data should be displayed in the cell
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 1, // To style width of column -> This is the shorthand for flex-grow, flex-shrink and flex-basis combined (see https://css-tricks.com/snippets/css/a-guide-to-flexbox/)
      },
      {
        id: 'portfolio_title',
        header: 'Portfolio Title',
        accessorFn: row => row.portfolio_title,
        cell: info => info.getValue(),
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 3,
      },
      {
        id: 'edit',
        accessorKey: 'Edit',
        header: () => <Box sx={{ paddingX: 1 }}>Edit</Box>,
        cell: params => {
          const rowWithoutArbitraryId = omit(params.row.original, ['id'])
          return (
            <IconButton
              data-testid={`edit-portfolio-${rowWithoutArbitraryId.portfolioid}`}
              onClick={() => handleEditRowClick(rowWithoutArbitraryId)}
              sx={{ color: '#707070' }}
            >
              <Edit />
            </IconButton>
          )
        },
        enableSorting: false,
        flex: 1,
      },
      {
        id: 'delete',
        accessorKey: 'Delete',
        header: 'Delete',
        cell: params => {
          const rowWithoutArbitraryId = omit(params.row.original, ['id'])
          return (
            <DeletePortfolio row={rowWithoutArbitraryId} iconButtonStyles={{ padding: 1 }} /> // what renders in a given table cell
          )
        },
        enableSorting: false,
        flex: 1,
      },
    ],
    [handleEditRowClick],
  )

  /**
   * "ID" does not match any value and so we need to iterate through list and add this property
   */
  const dataWithArbitraryIds = React.useMemo(() => {
    return portfoliosSorted.map((row, index) => {
      if (typeof row?.id === 'undefined') {
        return {
          ...row,
          id: index + 1,
        }
      }

      return row
    })
  }, [portfoliosSorted])

  return (
    <StaticScrollTable
      memoizedData={dataWithArbitraryIds}
      memoizedColumns={columns}
      isLoading={isLoadingFetchPortfolios}
      tableHeight={TABLE_HEIGHT}
      noRecordsOverlay="No portfolios found."
      initialSortState={[{ id: 'portfolio_title', desc: false }]}
    />
  )
}

StaticPortfolioTable.propTypes = {
  handleEditRowClick: PropTypes.func.isRequired,
}
