import React from 'react'
import { SidebarContext } from './SidebarContext'

/**
 * useSidebarContext is a custom hook to access the SidebarContext.
 *
 * @returns {object} SidebarContext - the shared page context
 * @returns {Array} object.portfoliosSorted - the portfolio list
 * @returns {boolean} object.isLoadingFetchPortfolios - whether the portfolio list is loading
 * @returns {function} object.refetchPortfolios - function to refetch the portfolio list
 * @returns {Array} object.scenarioParamsWithMetadata - the selected scenario parameters
 * @returns {boolean} object.isLoadingFetchScenarioParams - whether the scenario parameters are loading
 * @returns {function} object.refetchScenarioParams - function to refetch the scenario parameters
 *
 * @throws {Error} Throws an error if used outside of the SidebarContext provider.
 */
export const useSidebarContext = () => {
  const context = React.useContext(SidebarContext) // see SidebarContextProvider for values that are returned

  if (context === undefined) {
    throw new Error('useSidebarContext must be used within SidebarContext Provider')
  }

  return context
}
