import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import LoadingSkeleton from '../LoadingSkeleton'

const DefaultSkeletonLoad = ({ height }) => (
  <Box component="div">
    <LoadingSkeleton variant="rounded" width="100%" height={height || '100px'} data-testid="loading-default-skeleton" />
  </Box>
)

/**
 * Renders a titled dropdown component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Indicates whether the dropdown is in a loading state.
 * @param {boolean} props.isDisabled - Indicates whether the dropdown is disabled.
 * @param {Array} props.menuOptions - The options to be displayed in the dropdown menu.
 * @param {string} props.label - The label for the dropdown.
 * @param {function} props.onChange - The callback function to be called when the dropdown value changes.
 * @param {string} props.value - The current value of the dropdown.
 * @param {Object} props.formControlStyles - The styles to be applied to the form control component.
 * @param {Object} props.selectStyles - The styles to be applied to the select component.
 * @param {function} props.renderLoading - The function to render the loading UI of the dropdown.
 * @param {Object} props.selectAttributes - Additional attributes to be applied to the select component.
 * @param {function} props.renderHelperText - The function to render the helper text for the dropdown.
 * @returns {JSX.Element} The rendered titled dropdown component.
 */
export function TitledDropdown({
  id,
  isLoading = false,
  isDisabled = false,
  menuOptions = [],
  label,
  onChange,
  value: rawValue = '',
  formControlStyles = {},
  selectStyles = {},
  renderLoading,
  selectAttributes = {},
  renderHelperText,
}) {
  const value = rawValue === null ? '' : rawValue // setting the value to null throws a warning and replacing to empty string
  return (
    <>
      {isLoading ? (
        <>{renderLoading ? renderLoading() : <DefaultSkeletonLoad height={selectStyles?.height} />}</>
      ) : (
        <FormControl sx={formControlStyles} variant="outlined" disabled={isDisabled}>
          <InputLabel id={id}>{label}</InputLabel>
          <Select labelId={id} sx={selectStyles} label={label} onChange={onChange} value={value} {...selectAttributes}>
            {menuOptions?.map(val => (
              <MenuItem key={val.value} value={val.value} name={val.name} data-menu-content={val.name}>
                {val.name}
              </MenuItem>
            ))}
          </Select>
          {renderHelperText && renderHelperText()}
        </FormControl>
      )}
    </>
  )
}

TitledDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  ),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formControlStyles: PropTypes.object,
  selectStyles: PropTypes.object,
  renderLoading: PropTypes.func,
  selectAttributes: PropTypes.object,
  renderHelperText: PropTypes.func,
}
