import React from 'react'
import PropTypes from 'prop-types'
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'

/**
 * CheckboxGroup component.
 *
 * @component
 * @param {Array} options - The options for the checkbox group.
 * @param {number} columnCount - The number of columns for the checkbox group.
 * @param {function} handleCheckboxChange - The callback function for checkbox change event.
 * @param {function} verifyIsChecked - The function to verify if an option is checked.
 * @param {object} formControlLabelProps - Additional props for the FormControlLabel component.
 * @param {object} containerStyles - Additional styles for the container element.
 * @param {object} itemStyles - Additional styles for the checkbox item element.
 * @param {...object} checkboxProps - Additional props for the Checkbox component.
 * @returns {JSX.Element} The rendered CheckboxGroup component.
 */
export const CheckboxGroup = ({
  options = [],
  columnCount = 1,
  handleCheckboxChange,
  verifyIsChecked,
  formControlLabelProps = {},
  containerStyles = {},
  itemStyles = {},
  ...checkboxProps
}) => {
  const handleKeyDown = option => e => {
    if (e.key === 'Enter') {
      e.preventDefault() // prevent default behavior of form submission
      handleCheckboxChange(option)(e) // const callbackFn = option => e => {}
    }
  }

  const mappedCheckboxes = options.map(option => {
    const isChecked = !!verifyIsChecked(option)
    return (
      <Box
        key={option.value}
        sx={{ height: '100%', width: '100%', flexBasis: `calc(100% /${columnCount})`, ...itemStyles }}
      >
        <FormControlLabel
          {...formControlLabelProps}
          control={
            <Checkbox
              {...checkboxProps}
              type="checkbox"
              onChange={handleCheckboxChange(option)} // const callbackFn = (option) => (e) => {}
              onKeyDown={handleKeyDown(option)}
              checked={isChecked} // const verifyIsChecked = (option) => {}
              inputProps={{
                'data-testid': `checkbox-${option.value}`,
                'data-test-is-checked': `${isChecked}`,
                'aria-label': option.label,
                ...checkboxProps.inputProps,
              }}
            />
          }
          label={option.label}
        />
      </Box>
    )
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        ...containerStyles,
      }}
    >
      {mappedCheckboxes}
    </Box>
  )
}

// https://mui.com/material-ui/api/checkbox/
const checkboxPropTypes = {
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  inputProps: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium']),
}

CheckboxGroup.propTypes = {
  ...checkboxPropTypes,
  columnCount: PropTypes.number.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  verifyIsChecked: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  containerStyles: PropTypes.object,
  formControlLabelProps: PropTypes.object,
  itemStyles: PropTypes.object,
}
