const APP_PATHNAME = {
  HOME: '/',
  OVERVIEW: '/overview',
  BENCHMARK_EXPLORER: '/benchmarkExplorer',
  CLINIC_EXPLORER: '/clinicExplorer',
  LOGIN: '/login',
  DATA_MANAGER: '/dataManager',
}

const APP_PAGE_LABEL = {
  HOME: 'Overview',
  OVERVIEW: 'Overview',
  BENCHMARK_EXPLORER: 'Benchmark Explorer',
  CLINIC_EXPLORER: 'Clinic Explorer',
  LOGIN: 'Login',
  DATA_MANAGER: 'Data Manager',
}

export const APP_PAGES_MAP = {
  HOME: {
    label: APP_PAGE_LABEL.HOME,
    path: APP_PATHNAME.HOME,
  },
  OVERVIEW: {
    label: APP_PAGE_LABEL.OVERVIEW,
    path: APP_PATHNAME.OVERVIEW,
  },
  BENCHMARK_EXPLORER: {
    label: APP_PAGE_LABEL.BENCHMARK_EXPLORER,
    path: APP_PATHNAME.BENCHMARK_EXPLORER,
  },
  CLINIC_EXPLORER: {
    label: APP_PAGE_LABEL.CLINIC_EXPLORER,
    path: APP_PATHNAME.CLINIC_EXPLORER,
  },
  LOGIN: {
    label: APP_PAGE_LABEL.LOGIN,
    path: APP_PATHNAME.LOGIN,
  },
  DATA_MANAGER: {
    label: APP_PAGE_LABEL.DATA_MANAGER,
    path: APP_PATHNAME.DATA_MANAGER,
  },
}
