import React from 'react'
import { Routes, Route } from 'react-router-dom'

import BenchmarkExplorer from './pages/benchmarkExplorer/BenchmarkExplorer'
import ClinicExplorer from './pages/clinicExplorer/ClinicExplorer'
import Overview from './pages/overview/overview'
import DataManager from './pages/dataManager/DataManager'
import DataManagerUpdate from './pages/dataManagerUpdate/DataManager'
import PageNotFound from './pages/pageNotFound/pageNotFound'
import PageTemplate from './templates/PageTemplate'

import { APP_PAGES_MAP } from './components/navbar/navbar.constants'
import Unauthenticated from './pages/errorPages/Unauthenticated'

const SHOULD_SHOW_DM_UPDATE_PAGE = window.env.SHOULD_SHOW_DM_UPDATE_PAGE === 'true'
const SHOULD_REPLACE_DATA_MANAGER_PAGE = window.env.SHOULD_REPLACE_DATA_MANAGER_PAGE === 'true'

function App() {
  return (
    <PageTemplate>
      {/* Note that BrowserRouter wraps the App component in index.js */}
      <Routes>
        <Route path={APP_PAGES_MAP.HOME.path} element={<Overview />} />
        <Route path={APP_PAGES_MAP.OVERVIEW.path} element={<Overview />} />
        <Route path={APP_PAGES_MAP.BENCHMARK_EXPLORER.path} element={<BenchmarkExplorer />} />
        <Route path={APP_PAGES_MAP.CLINIC_EXPLORER.path} element={<ClinicExplorer />} />
        <Route path={APP_PAGES_MAP.LOGIN.path} element={<Unauthenticated />} />
        <Route
          path={APP_PAGES_MAP.DATA_MANAGER.path}
          element={SHOULD_REPLACE_DATA_MANAGER_PAGE ? <DataManagerUpdate /> : <DataManager />}
        />
        {SHOULD_SHOW_DM_UPDATE_PAGE && <Route path="/dataManagerUpdate" element={<DataManagerUpdate />} />}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </PageTemplate>
  )
}

export default App
