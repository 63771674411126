import React from 'react'
import { createUser, validateUserPermissions } from '../../../../services/apis'

/**
 * Custom hook for validating user permissions.
 *
 * @param {Object} options - The options for the hook.
 * @param {Function} options.getIdTokenClaims - The function to get the ID token claims.
 * @param {boolean} [options.defaultIsLoading=false] - The default value for the isLoading state.
 * @returns {Object} - An object containing the isLoading, error, isAuthorizedWithPermission, and validatePermissions.
 * @property {boolean} isLoading - Indicates whether the permissions are currently being validated.
 * @property {Error|null} error - The error that occurred during permission validation.
 * @property {boolean} isAuthorizedWithPermission - Whether the user is authorized with permission.
 * @property {Function} validatePermissions - The function for validating the user's permissions.
 * @throws {Error} If `getIdTokenClaims` or `accessToken` is undefined.
 */
export const useValidatePermissions = ({ getIdTokenClaims, defaultIsLoading = false }) => {
  const [isAuthorizedWithPermission, setAuthorizedWithPermission] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)

  const validatePermissions = React.useCallback(
    async ({ accessToken }) => {
      try {
        if (isAuthorizedWithPermission) {
          return
        }

        if (!accessToken || !getIdTokenClaims) {
          throw new Error('accessToken and getIdTokenClaims are required')
        }

        const res = await validateUserPermissions(accessToken)
        if (res?.status === 200) {
          setAuthorizedWithPermission(true)
          const claims = await getIdTokenClaims()
          const user = { id_token: claims.__raw }
          await createUser(accessToken, user)
        } else {
          setAuthorizedWithPermission(false)
        }

        setIsLoading(false)
      } catch (e) {
        console.error(e)
        setError(e)
        setAuthorizedWithPermission(false)
        setIsLoading(false) // mark as not loading so that error screen will appear in UI
      }
    },
    [getIdTokenClaims, isAuthorizedWithPermission],
  )

  return {
    isLoading,
    error,
    isAuthorizedWithPermission,
    validatePermissions,
  }
}
