import React from 'react'
import { Typography, Grid, Button, Box } from '@mui/material'
import TitledDropdown from '../../../../components/titledDropdown'
import DynamicEditableTextField from '../../../../components/dynamicEditableTextField/dynamicEditableTextField'
import LoadingSkeleton from '../../../../components/LoadingSkeleton'

import { Save } from '@mui/icons-material'
import { createScenario } from '../../../../services/apis'

import Spinner from '../../../../components/spinner'

import { useAppContext } from '../../../../context/AppContext/useAppContext'
import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useToastContext } from '../../../../context/ToastContext/useToastContext'
import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { useSidebarContext } from '../../../../context/SidebarContext/useSidebarContext'
import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import PortfolioCard from './PortfolioCard'
import ScenarioParametersCard from './ScenarioParametersCard'
import { composeCheckboxChange } from '../../../../components/checkboxMenu/checkboxHelpers'

export default function CreateScenarioCard() {
  const { defaultScenarioId, updateDefaultScenarioId } = useAppContext()
  const { accessToken } = useAuthContext()
  const { addToast } = useToastContext()
  const { refetchScenarios, updateSelectedScenario, scenarios } = useFilterContext()
  const { portfoliosSorted, isLoadingFetchPortfolios } = useSidebarContext()

  // state
  const [selectedPortfolio, setSelectedPortfolio] = React.useState(null)
  const [loadedPortfolioName, setLoadedPortfolioName] = React.useState(undefined)

  const [selectedYears, setSelectedYears] = React.useState([])
  const [selectedTherapistTypes, setSelectedTherapistTypes] = React.useState([])
  const [selectedVisitTypeOption, setSelectedVisitTypeOption] = React.useState('all')
  const [clinicUpperVal, setClinicUpperVal] = React.useState(12)
  const [clinicLowerVal, setClinicLowerVal] = React.useState(1)
  const [selectedTherapyOption, setSelectedTherapyOption] = React.useState('all')
  const [scenarioName, setScenarioName] = React.useState('Set Scenario Name')
  const [isSaveScenarioInProgress, setIsSaveScenarioInProgress] = React.useState(false)
  const [selectedClinicList, setSelectedClinicList] = React.useState([])

  const [sliderKey, setSliderKey] = React.useState(0)

  // Handle Saving of Scenarios
  const saveScenario = async () => {
    // Start Refresh Spinner
    try {
      // Check if scenario name already exists
      if (scenarios?.some(scenario => scenario.scenario_title === scenarioName)) {
        addToast({
          toastId: TOAST_IDS.createPortfolioError,
          variant: 'error',
          msg: `The scenario named "${scenarioName}" already exists. Please choose a different one.`,
        })
        return
      }
      setIsSaveScenarioInProgress(true)
      if (selectedPortfolio === null) {
        addToast({
          toastId: TOAST_IDS.createScenarioMissingPortfolioError,
          variant: 'error',
          msg: `A portfolio must be selected to create a new scenario.`,
        })
        return
      }

      if (selectedYears?.length < 1) {
        addToast({
          toastId: TOAST_IDS.createScenarioNoYearsSelectedError,
          variant: 'error',
          msg: `Select at least 1 year in benchmark parameters to create a new scenario.`,
        })
        return
      }

      // Build Payload to submit
      const apiParams = {
        details: {
          scenario_title: scenarioName,
        },
        scenario_params: {
          visit_type: [selectedVisitTypeOption],
          therapist_type: selectedTherapistTypes,
          therapy_type: [selectedTherapyOption],
          clinic_size: { min: clinicLowerVal, max: clinicUpperVal },
          portfolioid: selectedPortfolio,
          years: selectedYears,
        },
      }

      // Call Create Scenario API
      const res = await createScenario({ accessToken, data: apiParams })

      addToast({
        toastId: TOAST_IDS.createScenarioSuccess,
        variant: 'success',
        msg: `Scenario "${res?.data?.scenario_metadata?.scenario_title}" was created successfully.`,
      })

      // Reset Parameters
      setSelectedPortfolio(null)
      setSelectedYears([])
      setSelectedTherapistTypes([])
      setSelectedVisitTypeOption('all')
      setClinicUpperVal(12)
      setClinicLowerVal(1)
      setSelectedTherapyOption('all')
      setScenarioName('Set Scenario Name')
      setSliderKey(prevValue => prevValue + 1)

      const createdScenarioId = res?.data?.scenario_metadata?.scenarioid
      // We only update the default scenario id if the user does not have a default scenario yet
      if (!defaultScenarioId) {
        updateDefaultScenarioId(createdScenarioId)
      }

      // When a scenario is created, we must update the filter bar to contain scenario in dropdown
      const updatedScenarios = await refetchScenarios({
        accessToken,
        errorToastId: TOAST_IDS.refetchScenariosErrorForCreateScenarioCard,
      })
      const newSelectedScenario = updatedScenarios.find(scenario => scenario.scenarioid === createdScenarioId)
      // Because the user will most likely want to see the graphs associated with new scenario, we update the selected scenario to equal this new scenario
      updateSelectedScenario({
        scenario: newSelectedScenario,
        errorToastId: TOAST_IDS.updateErrorForCreateScenarioCard,
      })
    } catch (error) {
      addToast({
        toastId: TOAST_IDS.createScenarioError,
        variant: 'error',
        msg: `Error creating scenario - ${error?.message || 'unknown error'}`,
      })
    } finally {
      setIsSaveScenarioInProgress(false)
    }
  }

  const handleYearCheckboxSelect = composeCheckboxChange(setSelectedYears)
  const handleTherapistCheckboxSelect = composeCheckboxChange(setSelectedTherapistTypes)

  const handleTherapyOptionMapSelect = e => {
    setSelectedTherapyOption(e.target.value)
  }

  const handlePortfolioSelection = (e, i) => {
    setSelectedPortfolio(e.target.value)
    setLoadedPortfolioName(i.props.name)

    // The follow updates the clinic list to preselect the values of the clinics linked to the selected portfolio
    const selectedPortfolioDetails = portfoliosSorted?.find(portfolio => portfolio.portfolioid === e.target.value)
    setSelectedClinicList(selectedPortfolioDetails?.list_of_clinics || [])
  }

  const handleVisitType = e => {
    setSelectedVisitTypeOption(e.target.value)
  }

  // Handle independent portfoliosSorted updates
  const portfolioOptionMap = React.useMemo(() => {
    return portfoliosSorted?.map(opt => ({ value: opt.portfolioid, name: opt.portfolio_title })) || []
  }, [portfoliosSorted])

  return (
    <Box sx={{ width: '100%', paddingX: 4, height: '100%' }}>
      <Box sx={{ border: '2px solid #F5F8F9', padding: 2, position: 'relative' }}>
        {isSaveScenarioInProgress && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: 5,
              bottom: 5,
              left: 0,
              right: 0,
              background: '#FFF',
              zIndex: 4,
            }}
          >
            <Spinner
              width="400px"
              title="Saving New Scenario - Do Not Leave Page"
              boxContainerStyles={{ backgroundColor: '#FFF' }}
            />
          </Box>
        )}
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Typography sx={{ fontSize: '20pt', fontWeight: 700 }}>Create Scenario:</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={9} xl={10}>
            <DynamicEditableTextField
              textValue={scenarioName}
              setTextValue={setScenarioName}
              setFieldEdited={() => undefined}
              id="create-scenario-name"
            />
          </Grid>

          <Grid item xs={12} sx={{ marginTop: 1, marginBottom: 1 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
              Set benchmark parameters and select a portfolio to create a scenario.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ backgroundColor: '#F5F8F9', padding: 2 }}>
            <TitledDropdown
              id="create-scenario-portfolio-dropdown"
              label="Load Portfolio"
              isLoading={isLoadingFetchPortfolios}
              menuOptions={portfolioOptionMap}
              value={selectedPortfolio}
              onChange={handlePortfolioSelection}
              selectStyles={{
                borderRadius: '5px',
                width: '100%',
                maxWidth: '400px',
                minHeight: '62px',
                backgroundColor: '#FFFFFF',
              }}
              formControlStyles={{ width: '100%' }}
              renderLoading={() => <LoadingSkeleton variant="rounded" width="400px" height={62} />}
            />
          </Grid>
        </Grid>

        <Grid container sx={{ backgroundColor: '#F5F8F9', paddingX: 2, height: '100%' }}>
          <Grid item xs={12} md={6} lg={6} sx={{ paddingBottom: 2, paddingRight: { xs: 0, md: 2 } }}>
            <ScenarioParametersCard
              clinicLowerVal={clinicLowerVal}
              clinicUpperVal={clinicUpperVal}
              handleTherapistCheckboxSelect={handleTherapistCheckboxSelect}
              handleTherapyOptionMapSelect={handleTherapyOptionMapSelect}
              handleVisitType={handleVisitType}
              handleYearCheckboxSelect={handleYearCheckboxSelect}
              selectedTherapyOption={selectedTherapyOption}
              selectedVisitTypeOption={selectedVisitTypeOption}
              setClinicLowerVal={setClinicLowerVal}
              setClinicUpperVal={setClinicUpperVal}
              setSelectedVisitTypeOption={setSelectedVisitTypeOption}
              selectedYears={selectedYears}
              selectedTherapistTypes={selectedTherapistTypes}
              sliderKey={sliderKey}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ paddingBottom: 2, height: '100%' }}>
            <PortfolioCard
              portfolioOptionMap={portfolioOptionMap}
              setSelectedPortfolio={setSelectedPortfolio}
              selectedClinicList={selectedClinicList}
              setSelectedClinicList={setSelectedClinicList}
              loadedPortfolioName={loadedPortfolioName}
              setLoadedPortfolioName={setLoadedPortfolioName}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ background: '#fff' }}>
          <Grid item xs={12} sx={{ marginTop: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              disabled={isSaveScenarioInProgress || !selectedPortfolio}
              classes="inactive"
              startIcon={<Save />}
              onClick={saveScenario}
            >
              Save Scenario
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
