import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Search from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'
import DebouncedTextField from '../../components/DebouncedTextField'
import { useAppContext } from '../../context/AppContext/useAppContext'
import { formikPropTypes } from '../formikPropTypes'
import { FormHelperText } from '@mui/material'
import { filterClinicsBySearchTerm } from '../../utils/filterClinicsBySearchTerm'
import { StaticScrollTable } from '../../components/Table'
import {
  SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
  SORT_COLUMN_BY_ALPHANUMERIC_SENSITIVE,
} from '../../components/Table/tableSort'

const ERROR_MESSAGE_CONTAINER_STYLES = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '26px',
}

/**
 * Initial Form Values
 */
export const initialValuesForCreatePortfolioForm = { portfolio_title: '', list_of_clinics: [] }

export const initialTouchedForCreatePortfolioForm = {
  portfolio_title: false,
  list_of_clinics: false,
}

/**
 * Yup schema for form validations.  Note that this is passed into Formik in CreatePortfolio.js.
 */
export const createPortfolioValidationSchema = yup.object({
  portfolio_title: yup
    .string()
    .required('Portfolio name is required.')
    .test('no-whitespace', 'A portfolio name cannot consist of only spaces.', value => value?.trim()?.length > 0)
    .test(
      'three-characters-no-whitespace',
      'Portfolio name must be at least 3 characters long.',
      value => value?.trim()?.length >= 3,
    ),
  list_of_clinics: yup
    .array()
    .test('non-empty', 'At least 1 clinic must be selected from the table below.', value => value?.length > 0),
})

const ICON_BUTTON_PADDING = 1

/**
 * CreatePortfolioForm component represents a form for creating a portfolio.
 *
 * ****IMPORTANT****
 * DOCUMENTED BELOW IS ONLY A SUBSET OF ALL THE FORMIK PROPS PASSED TO THE COMPONENT
 * (SEE PROP-TYPES BELOW FOR FULL LIST)
 * ****IMPORTANT***
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.errors - The formik errors object.
 * @param {Function} props.handleBlur - The formik handleBlur function.
 * @param {Function} props.handleChange - The formik handleChange function.
 * @param {Function} props.handleFocus - The formik handleFocus function.
 * @param {Function} props.handleSubmit - The formik handleSubmit function.
 * @param {boolean} props.isSubmitting - The formik isSubmitting state.
 * @param {boolean} [props.isUnitTest=false] - Determines if the component is being tested.
 * @param {Function} props.setFieldTouched - The formik setFieldTouched function.
 * @param {Function} props.setFieldValue - The formik setFieldValue function.
 * @param {Object} props.touched - The formik touched object.
 * @param {Object} props.values - The formik values object.
 * @returns {JSX.Element} The rendered CreatePortfolioForm component.
 */
export const CreatePortfolioForm = ({
  errors,
  handleBlur,
  handleChange,
  handleFocus,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  touched,
  values,
}) => {
  const { isLoadingFetchClinics, clinicsSorted } = useAppContext()

  const [clinicSearchTerm, setClinicSearchTerm] = React.useState('')

  const formattedClinics = React.useMemo(
    () =>
      clinicsSorted.map(clinic => ({
        id: clinic.clinicid,
        ...clinic,
      })),
    [clinicsSorted],
  )

  const columns = React.useMemo(() => {
    const handleCheckboxChange = clinic => e => {
      const selectedClinics = values.list_of_clinics
      const isChecked = values.list_of_clinics.includes(clinic.clinicid)
      if (isChecked) {
        const clinicsWithoutSelected = selectedClinics.filter(selectedClinic => selectedClinic !== clinic.clinicid)
        setFieldValue('list_of_clinics', clinicsWithoutSelected)
      } else {
        const updatedClinics = [...selectedClinics, clinic.clinicid]
        setFieldValue('list_of_clinics', updatedClinics)
      }
    }

    return [
      {
        id: 'id',
        header: 'Clinic ID', // The text to display in the header cell
        accessorFn: row => row.clinicid, // How the data should be accessed from the row object
        cell: info => info.getValue(), // How the data should be displayed in the cell
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 1, // To style width of column -> This is the shorthand for flex-grow, flex-shrink and flex-basis combined (see https://css-tricks.com/snippets/css/a-guide-to-flexbox/)
      },
      {
        id: 'clinic_name',
        header: 'Clinic Name',
        accessorFn: row => row.clinic_name,
        cell: info => info.getValue(),
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_SENSITIVE,
        flex: 3,
      },
      {
        id: 'clinic_address',
        header: 'Location',
        accessorFn: row => row.clinic_address,
        cell: info => info.getValue(),
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 3,
      },
      {
        id: 'select',
        accessorKey: 'Selected',
        header: () => <Box paddingX={ICON_BUTTON_PADDING}>Selected</Box>, // We do this so that the padding matches the icon button padding for alignment purposes
        enableSorting: false,
        flex: 1,
        cell: params => {
          return (
            <Checkbox
              name="list_of_clinics"
              checked={values.list_of_clinics.includes(params.row.original.clinicid)}
              onChange={handleCheckboxChange(params.row.original)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              inputProps={{
                'aria-label': params.row.original.clinic_name,
                'data-testid': `clinic-checkbox-${params.row.original.clinicid}`,
              }}
              onKeyDown={e => e.key === 'Enter' && handleCheckboxChange(params.row.original)(e)} // allows for tabbing through options and checking/unchecking option with enter key on keyboard
              disabled={isSubmitting}
              sx={{
                backgroundColor:
                  !!touched.list_of_clinics && !!errors.list_of_clinics
                    ? 'rgba(211, 47, 47, 0.2) !important' // a light red border color when there is an error (using !important override so visible during hover)
                    : undefined,
              }}
            />
          )
        },
      },
    ]
  }, [
    handleFocus,
    handleBlur,
    isSubmitting,
    values.list_of_clinics,
    touched.list_of_clinics,
    errors.list_of_clinics,
    setFieldValue,
  ])

  const filteredClinics = React.useMemo(
    () => filterClinicsBySearchTerm({ formattedClinics, clinicSearchTerm }),
    [formattedClinics, clinicSearchTerm],
  )

  return (
    <form onSubmit={handleSubmit} data-testid="create-portfolio-form">
      <Grid container alignItems="flex-end">
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              width: '100%',
              height: '100',
              paddingRight: {
                xs: 0,
                sm: 1,
              },
              paddingY: { xs: 1, sm: 0 },
            }}
          >
            <Typography
              sx={{
                fontSize: '19px',
                fontWeight: 700,
                paddingRight: 2,
              }}
            >
              Portfolio:{' '}
            </Typography>

            <DebouncedTextField
              id="portfolio-name-input"
              name="portfolio_title"
              placeholder="Enter name..."
              variant="standard"
              handleInputChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={values.portfolio_title}
              disabled={isSubmitting}
              error={!!touched.portfolio_title && !!errors.portfolio_title}
              InputProps={{
                sx: {
                  'input::placeholder': {
                    color: !!touched.portfolio_title && !!errors.portfolio_title ? '#d32f2f' : 'currentColor',
                  },
                },
              }}
              inputProps={{
                'aria-describedby': 'portfolio-name-helper-text',
                'data-testid': 'portfolio-name-input',
              }}
              sx={{
                width: '100%',
                maxWidth: '300px',
              }}
              debounceIntervalInMs={300}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'flex-end',
              paddingLeft: {
                xs: 0,
                sm: 1,
              },
              paddingY: { xs: 1, sm: 0 },
            }}
          >
            <DebouncedTextField
              id="search-clinics"
              name="search-clinics-input"
              variant="outlined"
              handleInputChange={e => setClinicSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: <Search data-testid="search-icon" />,
              }}
              inputProps={{
                'data-testid': 'search-clinics-input',
              }}
              placeholder="Search Clinics"
              sx={{ paddingY: 0, marginX: { xs: 2, sm: 0 }, width: '100%', maxWidth: '400px' }}
              disabled={isSubmitting}
              debounceIntervalInMs={500}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={ERROR_MESSAGE_CONTAINER_STYLES}>
            {/**
             * We print the error message here instead of defining "helperText" as a prop for
             * DebouncedTextField to avoid jumpy behavior and to keep Portfolio aligned.
             */}
            {touched.portfolio_title && errors.portfolio_title && (
              <FormHelperText id="portfolio-name-helper-text" error={true} data-testid="portfolio-name-error-message">
                {errors.portfolio_title}
              </FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={ERROR_MESSAGE_CONTAINER_STYLES}>
            {/**
             * We print the error message here instead of defining helperText as a prop for
             * DebouncedTextField to avoid jumpy behavior.
             */}
            {touched.list_of_clinics && errors.list_of_clinics && (
              <FormHelperText
                id="portfolio-list-of-clinics-helper-text"
                error={true}
                data-testid="portfolio-clinics-list-error-message"
              >
                {errors.list_of_clinics}
              </FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%', height: '100%', paddingY: 2 }}>
            <StaticScrollTable
              memoizedData={filteredClinics}
              memoizedColumns={columns}
              isLoading={isLoadingFetchClinics}
              tableHeight={310}
              noRecordsOverlay="No clinics found."
              dataTestId="create-portfolio-clinic-table"
            />
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

CreatePortfolioForm.propTypes = {
  ...formikPropTypes, // Include all formikPropTypes
  // override specific formikPropTypes to be more specific
  values: PropTypes.shape({
    portfolio_title: PropTypes.string.isRequired,
    list_of_clinics: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    // Errors will contain the yup error message
    portfolio_title: PropTypes.string, // undefined unless there is an error
    list_of_clinics: PropTypes.string, // undefined unless there is an error
  }).isRequired,
  touched: PropTypes.shape({
    // touched indicates if the user has interacted with a given input field in the form
    portfolio_title: PropTypes.bool.isRequired,
    list_of_clinics: PropTypes.bool.isRequired,
  }).isRequired,
  initialValues: PropTypes.shape({
    portfolio_title: PropTypes.string.isRequired,
    list_of_clinics: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  initialErrors: PropTypes.shape({
    portfolio_title: PropTypes.string,
    list_of_clinics: PropTypes.string,
  }).isRequired,
  initialTouched: PropTypes.shape({
    portfolio_title: PropTypes.bool,
    list_of_clinics: PropTypes.bool,
  }).isRequired,
  // Other props that are not from formik
  isUnitTest: PropTypes.bool,
}
