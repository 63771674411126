import React from 'react'
import { useFetchMenuOptions } from './hooks/useFetchMenuOptions'
import { useFetchUserPreferences } from './hooks/useFetchUserPreferences'
import { useFetchClinics } from './hooks/useFetchClinics'
import { useLocation } from 'react-router-dom'
import { APP_PAGES_MAP } from '../../components/navbar/navbar.constants'
import { useAuthContext } from '../AuthContext/useAuthContext'
import { TOAST_IDS } from '../ToastContext/constants'

/**
 * AppContext includes any data that is needed across application pages
 */
export const AppContext = React.createContext()

export const AppContextProvider = ({ children }) => {
  const { accessToken } = useAuthContext()
  const location = useLocation()

  const {
    isLoading: isLoadingFetchUserPreferences,
    error: fetchUserPreferencesError,
    fetchUserPreferences,
    defaultScenarioId: defaultScenarioId,
    updateDefaultScenarioId,
  } = useFetchUserPreferences({ defaultIsLoading: true })

  const {
    isLoading: isLoadingFetchMenuOptions,
    error: fetchMenuOptionsError,
    fetchMenuOptions,
    menuOptions,
  } = useFetchMenuOptions({ defaultIsLoading: true })

  /**
   * @TODO when caching is implemented or when pagination is used, we will no longer need
   * to store this in the app context to avoid performance issues and can move
   * this hook to the data manager page
   */
  const {
    isLoading: isLoadingFetchClinics,
    fetchClinics,
    clinicsSorted,
    error: fetchClinicsError,
  } = useFetchClinics({ defaultIsLoading: false })

  /**
   * User preferences are updated by user via updateDefaultScenarioId and therefore
   * we only need to fetch once on the initial load
   */
  const hasFetchedUserPreferences = React.useRef(false)
  React.useEffect(() => {
    if (hasFetchedUserPreferences.current || !accessToken) return // if hasFetchedUserPreferences.current is true then we have already fetched the user preferences
    hasFetchedUserPreferences.current = true
    fetchUserPreferences({ accessToken, errorToastId: TOAST_IDS.getUserPreferenceForAppContextError })
  }, [fetchUserPreferences, accessToken])

  /**
   * Menu options rarely change (if at all) and therefore we only need to fetch once on the initial load
   */
  const hasFetchedMenuOptions = React.useRef(false)
  React.useEffect(() => {
    if (hasFetchedMenuOptions.current || !accessToken) return
    hasFetchedMenuOptions.current = true
    fetchMenuOptions({ accessToken, errorToastId: TOAST_IDS.getMenuOptionsError })
  }, [fetchMenuOptions, accessToken])

  /**
   * listAllClinics is an expensive request to be performing more than once because a user
   * can be associated with hundreds of clinics.  Also, because the data rarely changes, it
   * only needs to be fetched once.  The ref below will persist across renders and will not cause the
   * useEffect to re-run when the component re-renders because of the conditional.
   */
  const hasFetchedClinics = React.useRef(false)
  React.useEffect(() => {
    /**
     * clinicsSorted is only used by the data manager page and this is defined in the user context to
     * prevent performance issues.  The useEffect will only run if on the data manager page if the clinics data has not been fetched yet.
     * Subsequent navigation to the data manager page will not cause the useEffect to run again.
     */

    // @TODO DELETE /dataManagerUpdate from array below after feature is complete
    if (![APP_PAGES_MAP.DATA_MANAGER.path, '/dataManagerUpdate'].includes(location.pathname)) return // Never attempt to fetch if not on data manager page
    if (hasFetchedClinics.current || !accessToken) return
    hasFetchedClinics.current = true // immediately set to true to prevent future fetches
    fetchClinics({ accessToken, errorToastId: TOAST_IDS.listAllClinicsErrForDataManager })
  }, [fetchClinics, location.pathname, accessToken])

  return (
    <AppContext.Provider
      value={{
        // menu options
        isLoadingFetchMenuOptions,
        menuOptions,
        fetchMenuOptionsError,
        // user preferences
        isLoadingFetchUserPreferences,
        defaultScenarioId,
        updateDefaultScenarioId,
        fetchUserPreferencesError,
        // clinics
        isLoadingFetchClinics,
        clinicsSorted,
        fetchClinicsError,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
