import { Box, Card, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'

/**
 * Renders a connection error message that can be caused by loss of internet connection
 * or if the server is down.
 * @returns {JSX.Element} The ConnectionError component.
 */
export const ConnectionError = () => {
  const theme = useTheme()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ margin: 2 }}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, padding: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '90vh', width: '100%' }}>
            <Typography component="p" align="center" color="text.primary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              Connection Error: Please ensure you are connected to the internet and refresh the page. If issue persists,
              please contact support.
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}
