import React from 'react'
import { Card, CardContent, Typography, Grid, Box } from '@mui/material'
import PropTypes from 'prop-types'
import ClinicListTable from './ClinicListTable'
import LoadingSkeleton from '../../../../components/LoadingSkeleton'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import CircularButton from '../../../../components/CircularButton/CircularButton'
import { DebouncedTextField } from '../../../../components/DebouncedTextField/DebouncedTextField'
import { clinicsBySearchTerm } from '../../../../utils/filterClinicsBySearchTerm'

/**
 * Renders a table component for the Clinic Explorer page.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleRowClick - The function to handle row click events.
 * @param {string} setSelectedClinicId - We set for the first time the clinic id, because the data is filtered by the infiniteScroll component and we need to know the first time it brought data and for the first time get the clinic id as selected by default .
 * @param {boolean} props.isLoading - A flag indicating whether the table is currently loading.
 * @returns {JSX.Element} The rendered ClinicExplorerTable component.
 */
export function ClinicExplorerTable({ handleRowClick, setSelectedClinicId, setSelectedClinicDetails, isLoading }) {
  const [isFilterApplyOfClinicName, setIsFilterApplyOfClinicName] = React.useState(false)
  const [clinicSearchTerm, setClinicSearchTerm] = React.useState('')
  const searchTermClinic = React.useMemo(() => clinicsBySearchTerm({ clinicSearchTerm }), [clinicSearchTerm])

  const queryClient = new QueryClient()
  return (
    <Card>
      <CardContent sx={{ padding: 0 }}>
        <Grid container sx={{ paddingBottom: 0, alignItems: 'center' }}>
          <Grid item xs={12} md={6} lg={8} sx={{ paddingLeft: 2 }}>
            <Typography display="flex" alignItems="center" sx={{ fontWeight: 700, fontSize: '32px' }}>
              Clinic Explorer
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ padding: 2 }}>
            {isLoading ? (
              <LoadingSkeleton variant="rounded" width="100%" height="56px" data-testid="loading-table-search-bar" />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  justifyContent: 'flex-end',
                  paddingLeft: {
                    xs: 0,
                    sm: 1,
                  },
                  paddingY: { xs: 1, sm: 0 },
                }}
              >
                <DebouncedTextField
                  id="search-clinics"
                  name="search-clinics-input"
                  label="Search Clinics"
                  variant="outlined"
                  handleInputChange={e => setClinicSearchTerm(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <CircularButton
                        key="search"
                        onClick={() => {
                          setIsFilterApplyOfClinicName(true)
                        }}
                        style={{ cursor: 'pointer' }}
                        isActive={true}
                      >
                        Search
                      </CircularButton>
                    ),
                  }}
                  inputProps={{
                    'data-testid': 'search-clinics-input',
                  }}
                  placeholder="Search"
                  sx={{ paddingY: 0, marginX: { xs: 2, sm: 0 }, width: '100%', maxWidth: '400px' }}
                  disabled={false}
                  debounceIntervalInMs={500}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: 2, width: '100%', maxWidth: '100%' }}>
            <QueryClientProvider client={queryClient}>
              <ClinicListTable
                handleRowClick={handleRowClick}
                filteredNameClinic={searchTermClinic}
                isFilterApplyOfClinicName={isFilterApplyOfClinicName}
                setIsFilterApplyOfClinicName={setIsFilterApplyOfClinicName}
                setSelectedClinicId={setSelectedClinicId}
                setSelectedClinicDetails={setSelectedClinicDetails}
                isLoadingTable={isLoading || isFilterApplyOfClinicName}
              />
            </QueryClientProvider>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

ClinicExplorerTable.propTypes = {
  handleRowClick: PropTypes.func.isRequired,
  setSelectedClinicId: PropTypes.func.isRequired,
  setSelectedClinicDetails: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
