import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@mui/material'
import BestInClassAnalysisGraph from './BestInClassAnalysisGraph'

export const BestInClassCharts = ({ isLoading, finalBenchmarkData, finalPortfolioData }) => (
  <Box sx={{ padding: 2, width: '100%', height: '100%', minHeight: '468px' }}>
    <Grid container>
      <Grid item xs={12} md={6}>
        <BestInClassAnalysisGraph
          isLoading={isLoading}
          title="Performance Against Benchmark"
          data={finalBenchmarkData}
          radarChartProps={{ 'data-testid': 'benchmark-radar-chart' }}
          loadingSkeletonProps={{ 'data-testid': 'loading-benchmark-radar-chart' }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BestInClassAnalysisGraph
          isLoading={isLoading}
          title="Performance Against Portfolio"
          data={finalPortfolioData}
          radarChartProps={{ 'data-testid': 'portfolio-radar-chart' }}
          loadingSkeletonProps={{ 'data-testid': 'loading-portfolio-radar-chart' }}
        />
      </Grid>
    </Grid>
  </Box>
)

BestInClassCharts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  finalBenchmarkData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
        fill: PropTypes.bool.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        borderColor: PropTypes.string.isRequired,
        pointBackgroundColor: PropTypes.string.isRequired,
        pointBorderColor: PropTypes.string.isRequired,
        pointHoverBackgroundColor: PropTypes.string.isRequired,
        pointHoverBorderColor: PropTypes.string.isRequired,
      }),
    ),
  }),
  finalPortfolioData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
        fill: PropTypes.bool.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        borderColor: PropTypes.string.isRequired,
        pointBackgroundColor: PropTypes.string.isRequired,
        pointBorderColor: PropTypes.string.isRequired,
        pointHoverBackgroundColor: PropTypes.string.isRequired,
        pointHoverBorderColor: PropTypes.string.isRequired,
      }),
    ),
  }),
}
