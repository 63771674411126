import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

/**
 * A styled button component. Note that this component was created
 * to resolve the console errors thrown when setting classes={"spaced"}.
 */
const StyledButton = styled(Button)`
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 15px;
  color: #354052;
  border: 1px solid rgba(25, 118, 210, 0.5)
  text-decoration: none;

  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: rgba(25, 118, 210, 0.04);
    border: 1px solid #1976d2;
  }
`

/**
 * A button component for forms.
 *
 * @component
 * @param {Object} props - The button props.
 * @param {ReactNode} props.children - The content of the button.
 * @param {...any} muiButtonProps - Additional props to be spread on the mui button element.
 * @returns {JSX.Element} The rendered button component.
 */
export const FormButton = ({ children, ...muiButtonProps }) => (
  <StyledButton variant="outlined" {...muiButtonProps}>
    {children}
  </StyledButton>
)

FormButton.propTypes = {
  children: PropTypes.node.isRequired,
}
