import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoadingPage from '../../loadingPage'

/**
 * Renders the Unauthenticated page component.
 * This component is responsible for handling the case when a user is not authenticated.
 * It redirects the user to the login page using Auth0's loginWithRedirect function.
 * @returns {JSX.Element} The Unauthenticated page component.
 */
export const Unauthenticated = () => {
  const { loginWithRedirect, isLoading } = useAuth0()

  React.useEffect(() => {
    if (!isLoading) {
      loginWithRedirect()
    }
  }, [loginWithRedirect, isLoading])

  return <LoadingPage dataTestId="unauthenticated-page" />
}
