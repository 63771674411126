import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import React from 'react'

export default function CheckboxMenu(props) {
  const [checkboxState, setCheckboxState] = React.useState(props.checkboxStateDict)

  const handleUncheck = e => {
    const state = e.target.checked
    var updateDict = {}
    if (props.menuTag === 'years') {
      updateDict = {
        state: state,
        name: Number(e.target.name),
      }
    } else {
      updateDict = {
        state: state,
        name: e.target.name,
      }
    }

    var dict = { ...checkboxState, [e.target.id]: updateDict }

    // passback function
    props.passback(dict)

    // reset local state
    setCheckboxState(dict)
  }

  return (
    <FormGroup
      sx={{
        padding: props.padding,
        paddingLeft: props.paddingLeft,
        paddingTop: props.paddingTop,
      }}
    >
      <FormLabel component="legend">{props.formTitle}</FormLabel>
      {Object.keys(checkboxState)
        .reverse()
        .map((key, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                defaultChecked={checkboxState[key].state}
                name={checkboxState[key].name}
                id={key}
                size="small"
                key={Math.random()}
                onChange={handleUncheck}
              />
            }
            label={checkboxState[key].name}
          />
        ))}
    </FormGroup>
  )
}
