import React from 'react'
import { getBenchmarkPerformanceScoreCardTotal } from '../../services/apis'
import { useToastContext } from '../../context/ToastContext/useToastContext'

/**
 * Custom hook for fetching performance score card data.
 *
 * @param {Object} options - Options for the hook.
 * @param {boolean} options.defaultIsLoading - Default value for isLoading state.
 * @returns {Object} - Object containing isLoading, error, fetchPerformanceScoreCard, and performanceScoreCard.
 */
export const useFetchPerformanceScoreCard = options => {
  const defaultIsLoading = options?.defaultIsLoading || false
  const { addToast } = useToastContext()

  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)
  const [performanceScoreCard, setPerformanceScoreCard] = React.useState([])

  const fetchPerformanceScoreCard = React.useCallback(
    async ({ accessToken, errorToastId, performanceScoreCardParams }) => {
      try {
        setIsLoading(true)
        if (!accessToken || !errorToastId || !performanceScoreCardParams) {
          throw new Error('accessToken, errorToastId, and performanceScoreCardParams are required.')
        }
        const res = await getBenchmarkPerformanceScoreCardTotal({ accessToken, data: performanceScoreCardParams })
        setPerformanceScoreCard(res || [])
      } catch (e) {
        console.error(e)
        setError(e)
        setPerformanceScoreCard([])
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading performance score card data - ${e?.message || 'unknown error'}`,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast],
  )

  return {
    isLoading,
    error,
    fetchPerformanceScoreCard,
    performanceScoreCard,
  }
}
