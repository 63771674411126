import * as React from 'react'
import { Button, Typography, withTheme } from '@mui/material'
import { Close } from '@mui/icons-material'
import styled from '@emotion/styled'

/**
 * Toast variant colors are defined to match material ui colors.
 */
const toastVariants = {
  default: `
    color: #fff;
    background: #0288d1;
  `,
  success: `
    color: #fff;
    background: rgb(56, 142, 60);
  `,
  warning: `
    background: #ed6c02;
  `,
  error: `
    color: #fff;
    background: #d32f2f;
  `,
}

const ToastVisualStyle = styled.div`
  display: inline-block;
  padding: 25px 25px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  ${props => toastVariants[props.variant || 'default']};

  .bn-toast-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .bn-toast-action {
    margin-left: 29px;
  }

  .bn-toast-cross {
    margin-left: 26px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }
`

/**
 * A visual component for a toast.  This component is used by the toast stack to render the
 * individual toasts.
 * @param props The props for the toast visual.
 * @param props.variant The variant of the toast.
 * @param props.actionLabel The label of the action button, if desired.
 * @param props.noDismiss Whether the user cannot dismiss the toast.  Used for ongoing notifications.
 * @param props.onRequestClose Called when the toast wants to close.  Either from a timeout, or from the close button.
 * @param props.onActionClick Called when the action button is clicked.
 * @param props.children The content of the toast.
 * @param props.style The style of the toast.
 * @param props.id The HTML element id of the toast.
 * @param props.toastId The id of the toast. Used to identify the toast in the stack.
 * @returns The toast visual component.
 */
const ToastVisual = ({
  id,
  toastId,
  style,
  variant,
  actionLabel,
  onActionClick,
  onRequestClose,
  noDismiss,
  children,
}) => (
  <ToastVisualStyle id={id} className={`bn-toast`} style={style} variant={variant}>
    <div className="bn-toast-container">
      <Typography as="span" className="bn-toast-content" variant="p" align="left">
        {children}
      </Typography>
      {actionLabel && (
        <Button className="bn-toast-action" variant="linkDark" onClick={onActionClick}>
          {actionLabel}
        </Button>
      )}
      {!noDismiss && <Close className="bn-toast-cross" size="small" onClick={() => onRequestClose(toastId)} />}
    </div>
  </ToastVisualStyle>
)

export default ToastVisual
