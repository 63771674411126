import React from 'react'
import { getBenchmarkBestInClassAnalysis } from '../../services/apis'
import { useToastContext } from '../../context/ToastContext/useToastContext'

const DEFAULT_STATE = {
  defaultClinicId: undefined,
  defaultAppointmentDateYear: undefined,
  benchmarkRadarChartGroup: [],
  portfolioRadarChartGroup: [],
  labelsRadarChartGroup: undefined,
}

/**
 * Custom hook for fetching benchmark best-in-class analysis data.
 *
 * @param {Object} options - Options for the hook.
 * @param {boolean} options.defaultIsLoading - Default value for the isLoading state.
 * @returns {Object} - Object containing the hook's state and functions.
 * @property {boolean} isLoading - Indicates if the data is currently being fetched.
 * @property {Error} error - The error that occurred during the fetch.
 * @property {Function} fetchBenchmarkBestInClassAnalysis - Function for fetching the benchmark best-in-class analysis data.
 * @property {string} defaultClinicId - The default clinic ID.
 * @property {string} defaultAppointmentDateYear - The default appointment date year.
 * @property {Array} benchmarkRadarChartGroup - The benchmark radar chart group data.
 * @property {Array} portfolioRadarChartGroup - The portfolio radar chart group data.
 * @property {string} labelsRadarChartGroup - The labels for the radar chart group.
 */
export const useFetchBenchmarkBestInClassAnalysis = options => {
  const defaultIsLoading = options?.defaultIsLoading || false
  const { addToast } = useToastContext()

  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)

  const [defaultClinicId, setDefaultClinicId] = React.useState()
  const [defaultAppointmentDateYear, setDefaultAppointmentDateYear] = React.useState()

  const [benchmarkRadarChartGroup, setBenchmarkRadarChartGroup] = React.useState([])
  const [portfolioRadarChartGroup, setPortfolioRadarChartGroup] = React.useState([])
  const [labelsRadarChartGroup, setLabelsRadarChartGroup] = React.useState()
  /**
   * Fetches best in class analysis data.
   *
   * @param {string} accessToken - Access token for authentication.
   * @param {string} errorToastId - ID of the error toast.
   * @param {Object} bestInClassAnalysisParams - Parameters for fetching best in class analysis data.
   * @param {string} bestInClassAnalysisParams.units_payload.rate - The rate unit of measurement.
   * @param {string} bestInClassAnalysisParams.units_payload.time_granularity - The time granularity.
   * @param {string} bestInClassAnalysisParams.scenarioid - The scenario ID.
   * @param {string} bestInClassAnalysisParams.metric - The metric value.
   * @param {string} bestInClassAnalysisParams.clinic_id - The clinic ID.
   * @returns {Promise<void>}
   */
  const fetchBenchmarkBestInClassAnalysis = React.useCallback(
    async ({ accessToken, errorToastId, bestInClassAnalysisParams }) => {
      try {
        setIsLoading(true)
        if (!accessToken || !errorToastId || !bestInClassAnalysisParams) {
          throw new Error('accessToken, errorToastId, and bestInClassAnalysisParams are required.')
        }
        const res = await getBenchmarkBestInClassAnalysis({ accessToken, bestInClassAnalysisParams })
        // extract res.data.defaults into separate states
        setDefaultClinicId(res?.data?.defaults?.clinic_id)
        setDefaultAppointmentDateYear(res?.data?.defaults?.appointment_date_year)
        // extract res.data.radar_chart_comparison_groups into separate states
        setBenchmarkRadarChartGroup(res?.data?.radar_chart_comparison_groups?.benchmark)
        setPortfolioRadarChartGroup(res?.data?.radar_chart_comparison_groups?.portfolio)
        setLabelsRadarChartGroup(res?.data?.radar_chart_comparison_groups?.pretty_labels)
      } catch (e) {
        console.error(e)
        setError(e)
        // reset state to default
        setDefaultClinicId(DEFAULT_STATE.defaultClinicId)
        setDefaultAppointmentDateYear(DEFAULT_STATE.defaultAppointmentDateYear)
        setBenchmarkRadarChartGroup(DEFAULT_STATE.benchmarkRadarChartGroup)
        setPortfolioRadarChartGroup(DEFAULT_STATE.portfolioRadarChartGroup)
        setLabelsRadarChartGroup(DEFAULT_STATE.labelsRadarChartGroup)
        // throw toast
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading best in class analysis data - ${e?.message || 'unknown error'}`,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast],
  )

  return {
    isLoading,
    error,
    fetchBenchmarkBestInClassAnalysis,
    defaultClinicId,
    defaultAppointmentDateYear,
    benchmarkRadarChartGroup,
    portfolioRadarChartGroup,
    labelsRadarChartGroup,
  }
}
