export const DEFAULT_ROW_HEIGHT = 35

/**
 * All three of the following styles are required for CSS to show an ellipsis (...)
 * when there is text overflow in a div
 */
export const ELLIPSIS_STYLES = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}
