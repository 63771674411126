import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'

import withRouterWrapper from '../../components/withRouterWrapper/withRouterWrapper'
import ScenarioManagerCard from './pageComponents/scenarioManagerCard/ScenarioManagerCard'
import PortfolioManagerCard from './pageComponents/portfolioManagerCard'

function DataManager() {
  return (
    <Box sx={{ paddingX: 4, marginBottom: 4, width: '100%', height: '100%' }}>
      <Card sx={{ borderRadius: 0 }}>
        <CardContent sx={{ padding: 0 }}>
          <Box sx={{ padding: 2 }}>
            <Typography sx={{ fontWeight: 700, fontSize: '24pt' }}>Data Manager</Typography>
          </Box>
          <Box sx={{ minHeight: '460px' }}>
            <PortfolioManagerCard />
          </Box>
          <Box sx={{ minHeight: '460px' }}>
            <ScenarioManagerCard />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default withRouterWrapper(DataManager)({ shouldRedirectNewUser: false })
