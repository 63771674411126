import React from 'react'
import Grid from '@mui/material/Grid'
import BodyTemplate from '../BodyTemplate'
import BodyWrapper from './BodyWrapper'
import Navbar from '../../components/navbar/navbar'
import AuthProviders from './AuthProviders'
import { AppContextProvider } from '../../context/AppContext/AppContext'
import { STICKY_COMPONENT_CONFIG } from '../../themes/stickyConfig'

/**
 * Renders the page template component that is used across the application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render within the page template.
 * @returns {JSX.Element} The rendered page template.
 */
export const PageTemplate = ({ children }) => {
  const [computedNavbarHeight, setComputedNavbarHeight] = React.useState(0)
  const navGridRef = React.useRef()

  React.useEffect(() => {
    /**
     * useEffect is only called 1 time on initial render because navigation visible on every page
     * and there is no needed to recalculate navbar height
     */
    setComputedNavbarHeight(navGridRef.current?.clientHeight || 0) // needed for sticky component positioning
  }, [])

  return (
    <AuthProviders>
      <AppContextProvider>
        <Grid
          container
          ref={navGridRef}
          sx={{
            position: STICKY_COMPONENT_CONFIG.position,
            top: 0,
            zIndex: STICKY_COMPONENT_CONFIG.zIndex,
          }}
        >
          <Grid item xs={12}>
            <Navbar />
          </Grid>
        </Grid>
        {/* Note that BodyWrapper handles loading and error pages */}
        <BodyWrapper>
          <BodyTemplate computedNavbarHeight={computedNavbarHeight}>{children}</BodyTemplate>
        </BodyWrapper>
      </AppContextProvider>
    </AuthProviders>
  )
}
