import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { AuthContextProvider } from '../../../context/AuthContext/AuthContext'
import { auth0Config } from '../../../services/config'

/**
 * AuthProviders is a wrapper component that includes all providers
 * related to authentication and authorization.
 */
export const AuthProviders = ({ children }) => (
  <Auth0Provider
    domain={auth0Config.domain}
    clientId={auth0Config.clientId}
    authorizationParams={{
      redirect_uri: auth0Config.redirectUri,
    }}
  >
    <AuthContextProvider>{children}</AuthContextProvider>
  </Auth0Provider>
)
