import { Box, Divider, Grid, Hidden, Typography, useTheme } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import React from 'react'
import {
  UNIT_OF_MEASURE_OPTION_VALUE,
  UNIT_OF_TIME_OPTION_VALUE,
} from '../../context/FilterContext/FilterContext.constants'
import { useFilterContext } from '../../context/FilterContext/useFilterContext'
import CircularButtonGroup from '../CircularButton/CircularButtonGroup'
import { TOAST_IDS } from '../../context/ToastContext/constants'
import TitledDropdown from '../titledDropdown'
import LoadingSkeleton from '../LoadingSkeleton'

const FilterBarDivider = () => (
  <Hidden mdDown>
    <Divider orientation="vertical" sx={{ height: '40%', paddingLeft: 1 }} />
  </Hidden>
)

const UNIT_OF_MEASURE_BTN_GRP_OPTIONS = [
  {
    displayText: 'Per Provider',
    id: UNIT_OF_MEASURE_OPTION_VALUE.per_provider,
  },
  {
    displayText: 'Per Clinic',
    id: UNIT_OF_MEASURE_OPTION_VALUE.per_clinic,
  },
]

const UNIT_OF_TIME_BTN_GRP_OPTIONS = [
  {
    displayText: 'Quarterly',
    id: UNIT_OF_TIME_OPTION_VALUE.quarter,
  },
  {
    displayText: 'Monthly',
    id: UNIT_OF_TIME_OPTION_VALUE.month,
  },
  {
    displayText: 'Weekly',
    id: UNIT_OF_TIME_OPTION_VALUE.week,
  },
]

export const FilterBar = () => {
  const theme = useTheme()
  const {
    unitOfMeasurement,
    unitOfTime,
    updateUnitOfMeasurement,
    updateUnitOfTime,
    scenarios,
    selectedScenario,
    updateSelectedScenario,
    isLoadingFetchScenarios,
  } = useFilterContext()

  const sortedScenarios = [...scenarios]?.sort((a, b) => a?.scenario_title?.localeCompare(b?.scenario_title))
  const formattedScenarioList = sortedScenarios.map(val => ({ value: val.scenarioid, name: val.scenario_title }))
  const formattedSelectedScenario = selectedScenario?.scenarioid

  const handleDropdownChange = event => {
    // note that event.target.value is the scenarioid
    const newSelectedScenario = scenarios.find(scenario => scenario.scenarioid === event.target.value)
    updateSelectedScenario({ scenario: newSelectedScenario, errorToastId: TOAST_IDS.updateSelectedScenarioInFilterErr })
  }

  return (
    <Grid
      container
      sx={{
        display: {
          lg: 'flex',
        },
        justifyContent: 'center',
        background: theme.palette.background.paper,
        minHeight: '60px',
        paddingX: {
          xs: 1,
          md: 2,
        },
        paddingY: {
          xs: 1,
          md: 0,
        },
      }}
    >
      <Grid
        item
        xs={1}
        sm={2}
        md={1}
        lg={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            display: {
              xs: 'none',
              sm: 'block',
              md: 'none',
              lg: 'block',
            },
          }}
        >
          Filter By :
        </Typography>
        <TuneIcon />
        <FilterBarDivider />
      </Grid>

      <Grid item xs={11} sm={10} md={4} lg={4} sx={{ display: 'flex' }}>
        <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
          Unit of Measurement:
          <CircularButtonGroup
            options={UNIT_OF_MEASURE_BTN_GRP_OPTIONS}
            handleClick={id => updateUnitOfMeasurement(id)}
            selectedButtonId={unitOfMeasurement}
            sx={{ minHeight: '20px', fontSize: '11px', boxShadow: 0 }}
          />
        </Typography>
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <FilterBarDivider />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '10pt' }}>
          Unit of Time:
          <CircularButtonGroup
            options={UNIT_OF_TIME_BTN_GRP_OPTIONS}
            handleClick={id => updateUnitOfTime(id)}
            selectedButtonId={unitOfTime}
            sx={{ minHeight: '20px', fontSize: '11px', boxShadow: 0 }}
          />
        </Typography>
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <FilterBarDivider />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        lg={3}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: {
            xs: '100%',
            sm: '350px',
            md: '200px',
            lg: '350px',
          },
        }}
      >
        <TitledDropdown
          id="filter-scenario-dropdown"
          label="Scenario"
          isLoading={isLoadingFetchScenarios}
          menuOptions={formattedScenarioList}
          value={formattedSelectedScenario}
          onChange={handleDropdownChange}
          selectStyles={{ borderRadius: '5px', width: '100%', height: '40px' }}
          formControlStyles={{ width: '100%' }}
          renderLoading={() => <LoadingSkeleton variant="rounded" height="40px" width="100%" />}
        />
      </Grid>
    </Grid>
  )
}
