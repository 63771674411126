import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import CreatePortfolio from './CreatePortfolio'
import { keepPreviousData, QueryClient, QueryClientProvider, useInfiniteQuery } from '@tanstack/react-query'
import InfiniteScrollPortfolioTable from './PortfolioTable/InfiniteScrollPortfolioTable'
import StaticPortfolioTable from './PortfolioTable/StaticPortfolioTable'
import EditPortfolio from './EditPortfolio'

export const PortfolioManagerCard = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false)
  const [selectedPortfolio, setSelectedPortfolio] = React.useState()

  const handleCloseModal = () => {
    setSelectedPortfolio(undefined)
    setIsEditDialogOpen(false)
  }

  const handleEditRowClick = async row => {
    setSelectedPortfolio(row)
    setIsEditDialogOpen(true)
  }

  // const queryClient = new QueryClient()

  return (
    <Box sx={{ padding: 4, width: '100%', height: '100%' }}>
      <Grid container sx={{ border: '2px solid #F5F8F9', padding: 2 }}>
        <Grid item xs={6} sx={{ marginBottom: 2 }}>
          <Typography component="h2" sx={{ fontSize: '20pt', fontWeight: 700 }}>
            Portfolio Manager
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ marginBottom: 2 }}>
          <CreatePortfolio />
        </Grid>
        <Grid item xs={12}>
          {/**
           * QueryClientProvider must wrap every infinite scroll component so that useInfiniteQuery can work properly
           */}
          {/* <QueryClientProvider client={queryClient}>
            <InfiniteScrollPortfolioTable handleEditRowClick={handleEditRowClick} />
          </QueryClientProvider> */}
          <StaticPortfolioTable handleEditRowClick={handleEditRowClick} />
        </Grid>
      </Grid>
      <EditPortfolio
        isEditDialogOpen={isEditDialogOpen}
        setIsEditDialogOpen={setIsEditDialogOpen}
        handleCloseModal={handleCloseModal}
        selectedPortfolio={selectedPortfolio}
      />
    </Box>
  )
}

export default PortfolioManagerCard
