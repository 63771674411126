import React from 'react'
import { FilterContext } from '../FilterContext'

/**
 * useFilterContext returns filter context for the filter bar
 *
 * @returns {object} FilterContext
 * @returns {boolean} object.isLoadingFetchScenarios - whether the filter context is loading
 * @returns {function} object.refetchScenarios - function to refetch the scenarios
 * @returns {Array} object.scenarios - the scenarios
 * @returns {object} object.selectedScenario - the selected scenario
 * @returns {object} object.unitOfMeasurement - the selected unit of measurement
 * @returns {object} object.unitOfTime - the selected unit of time
 * @returns {function} object.updateSelectedScenario - function to update the selected scenario
 * @returns {function} object.updateUnitOfMeasurement - function to update the selected unit of measurement
 * @returns {function} object.updateUnitOfTime - function to update the selected unit of time
 */
export const useFilterContext = () => {
  const context = React.useContext(FilterContext)

  if (context === undefined) {
    throw new Error('useFilterContext must be used within FilterContextProvider')
  }

  return context
}
