import React, { useCallback } from 'react'
import DisplayToasts from './DisplayToasts'
import truncate from 'lodash/truncate'
import { TOAST_IDS } from './constants'

/**
 * The Toast Context
 * @type {Object}
 * @property {function} addToast - Adds a toast to the stack
 * @property {function} removeToast - Removes a toast from the stack
 * @property {Array} toasts - The array of toasts
 */
export const ToastContext = React.createContext()

/**
 * The Toast Context Provider
 * @param {Object} props - The props object
 * @param {Object} props.children - The children to render
 *
 * @returns {Object} The Toast Context Provider
 * @property {function} addToast - Adds a toast to the stack
 * @property {function} removeToast - Removes a toast from the stack
 * @property {Array} toasts - The array of toasts
 * @property {Object} children - The children to render
 */
export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = React.useState([])

  /**
   * Function that adds a toast to the stack
   * @param {Object} toast - The toast object
   * @param {string} toast.toastId - The unique identifier for the toast
   * @param {string} toast.variant - The variant of the toast (filled, outlined)
   * @param {string} toast.msg - The message to display in the toast
   * @param {string} toast.timeout - (Optional) How long the toast should be visible in seconds. 0 means it is never removed with a timeout. See "defaultTimeout" for defaults
   * @param {string} toast.truncateLength - (Optional) The length to truncate the message to - default is 200 characters
   *
   * @returns {void}
   */
  const addToast = useCallback(toast => {
    if (typeof toast.toastId === 'undefined') {
      throw new Error('toastId is required for toasts')
    }

    if (window.env.APP_ENVIRONMENT === 'local' && typeof TOAST_IDS[toast.toastId] === 'undefined') {
      throw new Error('toastId must be defined in TOAST_IDS to avoid duplicate toastIds')
    }

    if (toast?.variant === 'error') {
      // Log in browser console with toastId to identify source for debugging
      console.error(`TOAST_ERROR - ${toast.toastId}: ${toast.msg}`)
    }

    const truncatedMsg = truncate(toast.msg, { length: toast?.truncateLength || 200 }) // protect against really long error messages

    // to avoid duplicate toasts, any matching toast id will be replaced by the new toast
    setToasts(prevToasts => [
      ...prevToasts.filter(existingToast => existingToast.toastId !== toast.toastId),
      {
        ...toast,
        portalId: 'toast-stack',
        open: true, // open is always true becauswe all toasts in the array should be displayed
        msg: truncatedMsg,
      },
    ])
  }, [])

  /**
   * Function that removes a toast from the stack
   * @param {string} toastId - The unique identifier for the toast
   * @returns {void}
   */
  const removeToast = useCallback(toastId => {
    if (typeof toastId === 'undefined') {
      throw new Error('toastId is required for removing a toast.')
    }
    setToasts(prevToasts => prevToasts.filter(toast => toast.toastId !== toastId))
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast, toasts }}>
      <DisplayToasts />
      {children}
    </ToastContext.Provider>
  )
}
