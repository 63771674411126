import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'

import DynamicEditableTextField from '../../../../components/dynamicEditableTextField/dynamicEditableTextField'
import PortfolioBuilderTable from '../../../../components/portfolioBuilderTable/portfolioBuilderTable'
import { listAllClinics, savePortfolio } from '../../../../services/apis'
import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useToastContext } from '../../../../context/ToastContext/useToastContext'
import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import { useSidebarContext } from '../../../../context/SidebarContext/useSidebarContext'
import LoadingSkeleton from '../../../../components/LoadingSkeleton'
import TableSearchBar from '../../../../components/tableSearchBar/tableSearchBar'
import { useAppContext } from '../../../../context/AppContext/useAppContext'

const PortfolioCard = ({
  portfolioOptionMap,
  setSelectedPortfolio,
  selectedClinicList,
  setSelectedClinicList,
  loadedPortfolioName,
  setLoadedPortfolioName,
}) => {
  const { refetchPortfolios } = useSidebarContext()

  const { isLoadingFetchClinics, clinicsSorted } = useAppContext()
  const { accessToken } = useAuthContext()
  const { addToast } = useToastContext()

  const [filteredTableData, setFilteredTableData] = React.useState([])
  const [isSavingPortfolio, setIsSavingPortfolio] = React.useState(false)
  const [editablePortfolioName, setEditablePortfolioName] = React.useState('Set Portfolio Name')

  React.useEffect(() => {
    if (isLoadingFetchClinics) return
    setFilteredTableData(clinicsSorted)
  }, [isLoadingFetchClinics, clinicsSorted])

  React.useEffect(() => {
    if (loadedPortfolioName) {
      // set the editable name to the loaded name if it is defined
      setEditablePortfolioName(loadedPortfolioName)
    }
  }, [loadedPortfolioName])

  const handlePortfolioSave = () => {
    if (selectedClinicList.length === 0) return
    // Check if the portfolio name already exists
    if (portfolioOptionMap?.some(portfolio => portfolio.name === editablePortfolioName)) {
      addToast({
        toastId: TOAST_IDS.createPortfolioError,
        variant: 'error',
        msg: `The portfolio named "${editablePortfolioName}" already exists. Please choose a different one.`,
      })
      return
    }
    setIsSavingPortfolio(true)

    // Construct Parameters
    const params = {
      portfolio_title: editablePortfolioName,
      list_of_clinics: selectedClinicList,
    }

    // Execute Save Call
    savePortfolio(params, accessToken)
      .then(res => {
        addToast({
          toastId: TOAST_IDS.createPortfolioSuccess,
          variant: 'success',
          msg: `Portfolio "${res?.data?.portfolio_title}" was created successfully.`,
        })

        // Set the newly selected portfolio
        setSelectedPortfolio(res.data.portfolioid)
        setLoadedPortfolioName(res.data.portfolio_title)

        refetchPortfolios({ accessToken, errorToastId: TOAST_IDS.getPortfoliosErrorForPortfolioCard })
      })
      .catch(e => {
        addToast({
          toastId: TOAST_IDS.createPortfolioError,
          variant: 'error',
          msg: `Error creating portfolio - ${e?.message || 'unknown error'}`,
        })
      })
      .finally(() => {
        setIsSavingPortfolio(false)
      })
  }

  const masterClinicData = React.useMemo(() => {
    return {
      header: ['Clinic ID', 'Clinic Name', 'Location', 'Select'],
      records: filteredTableData || [],
    }
  }, [filteredTableData])

  const doesLoadedPortfolioNameMatchEditable = loadedPortfolioName && editablePortfolioName === loadedPortfolioName

  const shouldDisableSaveBtn = React.useMemo(() => {
    const noClinics = selectedClinicList?.length === 0

    return isSavingPortfolio || noClinics || doesLoadedPortfolioNameMatchEditable
  }, [doesLoadedPortfolioNameMatchEditable, isSavingPortfolio, selectedClinicList?.length])

  const isPortfolioTableReadOnly = React.useMemo(() => {
    return doesLoadedPortfolioNameMatchEditable
  }, [doesLoadedPortfolioNameMatchEditable])

  return (
    <Box
      sx={{
        minHeight: '432px',
        height: '100%',
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: 1,
      }}
    >
      <Grid container alignItems={'center'} sx={{ minHeight: '80px' }}>
        <Grid item xs={12} sm={6} md={5} lg={3} sx={{ padding: 1, display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '19px', fontWeight: 700 }}>Portfolio:</Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={7}
          lg={4}
          sx={{ padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'left' }}
        >
          <DynamicEditableTextField
            textValue={editablePortfolioName}
            setTextValue={setEditablePortfolioName}
            setFieldEdited={() => undefined}
            id="create-portfolio-name"
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          padding={1}
          sx={{
            paddingTop: 4,
            paddingBottom: 6,
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}
        >
          {isLoadingFetchClinics ? (
            <Box sx={{ paddingX: 2 }} height="56px" width="100%">
              <LoadingSkeleton variant="rounded" height="56px" width="100%" sx={{ maxWidth: '400px' }} />
            </Box>
          ) : (
            <TableSearchBar
              label="Search Clinics"
              data={clinicsSorted}
              field="clinic_name"
              returnData={setFilteredTableData}
              sx={{ paddingY: 0, marginX: { xs: 2, sm: 0 }, maxWidth: '400px' }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} padding={1} sx={{ overflowX: 'auto' }}>
          <PortfolioBuilderTable
            data={masterClinicData}
            selectedClinicList={selectedClinicList}
            handleSelectedClinicList={setSelectedClinicList}
            isLoading={isLoadingFetchClinics}
            isReadOnly={isPortfolioTableReadOnly}
          />
        </Grid>
        <Grid item xs={12} padding={1}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
              variant="outlined"
              disabled={shouldDisableSaveBtn}
              classes="inactive"
              startIcon={<Save />}
              onClick={handlePortfolioSave}
            >
              Save portfolio
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PortfolioCard
