import React from 'react'
import { Card, CardContent } from '@mui/material'
import withRouterWrapper from '../../components/withRouterWrapper/withRouterWrapper'

const PageNotFound = () => {
  return (
    <Card>
      <CardContent sx={{ padding: 0, minHeight: '84vh' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
          <h1>Page Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
        </div>
      </CardContent>
    </Card>
  )
}

export default withRouterWrapper(PageNotFound)({ shouldRedirectNewUser: false })
