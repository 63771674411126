import React from 'react'
import { Chip } from '@mui/material'

const ChipStyle = ({ isDefault, size, ...rest }) => {
  return (
    <Chip
      {...rest}
      label={isDefault ? 'Default' : 'Set Default'}
      color={isDefault ? 'default' : 'primary'}
      variant={isDefault ? 'filled' : 'outlined'}
    />
  )
}

export default ChipStyle
