import React from 'react'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AddBox, CheckBox } from '@mui/icons-material'
import LoadingSkeleton from '../LoadingSkeleton'

const StyledBodyTableCell = props => {
  const { children, ...rest } = props
  return (
    <TableCell sx={{ padding: '5px 16px' }} {...rest}>
      {props.children}
    </TableCell>
  )
}

export default function PortfolioBuilderTable({
  data,
  selectedClinicList,
  handleSelectedClinicList,
  isLoading,
  isReadOnly,
}) {
  const handleAddClinic = (e, i) => {
    const current_clinic_array = [...selectedClinicList, e.clinicid]
    handleSelectedClinicList(current_clinic_array)
  }

  // Remove Clinics //
  const handleRemoveClinic = e => {
    const current_clinic_array = [...selectedClinicList].filter(item => item !== e.clinicid)
    handleSelectedClinicList(current_clinic_array)
  }

  const tableRowData = data.records.map(row =>
    selectedClinicList.some(item => row.clinicid === item)
      ? {
          clinicid: row.clinicid,
          clinic_name: row.clinic_name,
          clinic_address: row.clinic_address,
          selected: true,
        }
      : {
          clinicid: row.clinicid,
          clinic_name: row.clinic_name,
          clinic_address: row.clinic_address,
          selected: row.selected,
        },
  )

  const tableRows = tableRowData.map((row, index) => (
    <TableRow key={index} className="efficiency">
      <StyledBodyTableCell>{row.clinicid}</StyledBodyTableCell>
      <StyledBodyTableCell>{row.clinic_name}</StyledBodyTableCell>
      <StyledBodyTableCell align="left">{row.clinic_address}</StyledBodyTableCell>
      <StyledBodyTableCell align="center">
        {row.selected === false ? (
          <IconButton onClick={() => handleAddClinic(row)} disabled={isReadOnly}>
            <AddBox />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              handleRemoveClinic(row)
            }}
            disabled={isReadOnly}
          >
            <CheckBox />
          </IconButton>
        )}
      </StyledBodyTableCell>
    </TableRow>
  ))

  return (
    <TableContainer sx={{ maxWidth: '100%', minWidth: '85%', height: '250px', overflow: 'auto' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {data.header.map((title, index) => {
              if (title === 'Select') {
                return (
                  <TableCell key={index} align="center">
                    {title}
                  </TableCell>
                )
              } else {
                return (
                  <TableCell key={index} align="left">
                    {title}
                  </TableCell>
                )
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell align="center" colSpan={data.header.length}>
                <LoadingSkeleton variant="rectangular" height="200px" width="100%" />
              </TableCell>
            </TableRow>
          ) : (
            <>{tableRows}</>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
