import React from 'react'
import { Box, Grid, Typography, FormLabel } from '@mui/material'

import CheckboxMenu from '../../../../components/checkboxMenu/checkboxMenu'
import CheckboxMenuV2 from '../../../../components/checkboxMenu/checkboxMenuV2'
import {
  getCheckboxOptions,
  verifyIsChecked,
  composeCheckboxChange,
} from '../../../../components/checkboxMenu/checkboxHelpers'

import ValueSlider from '../../../../components/valueSlider/valueSlider'
import { useAppContext } from '../../../../context/AppContext/useAppContext'
import TitledDropdown from '../../../../components/titledDropdown'
import LoadingSkeleton from '../../../../components/LoadingSkeleton'

const ScenarioParameterDropdown = ({ label, isLoading, menuOptions, value, onChange, boxStyles }) => {
  return (
    <Box sx={boxStyles}>
      <TitledDropdown
        id="scenario-parameter-dropdown"
        label={label}
        isLoading={isLoading}
        menuOptions={menuOptions}
        value={value}
        onChange={onChange}
        selectStyles={{
          borderRadius: '5px',
          width: '100%',
          minHeight: '56px',
        }}
        formControlStyles={{ width: '100%', maxWidth: '400px' }}
        renderLoading={() => <LoadingSkeleton variant="rounded" width="400px" height={56} />}
      />
    </Box>
  )
}

const therapyOptionMap = [{ value: 'all', name: 'All' }]

const ScenarioParametersCard = ({
  clinicLowerVal,
  clinicUpperVal,
  handleTherapyOptionMapSelect,
  handleYearCheckboxSelect,
  selectedTherapyOption,
  setClinicLowerVal,
  setClinicUpperVal,
  selectedVisitTypeOption,
  handleVisitType,
  handleTherapistCheckboxSelect,
  selectedYears,
  selectedTherapistTypes,
  sliderKey,
}) => {
  const { isLoadingFetchMenuOptions, menuOptions } = useAppContext()

  const visitTypeOptionMap = React.useMemo(() => {
    const visitTypeOptions =
      menuOptions
        ?.filter(opt => opt.menu === 'visit_type')
        ?.map(filteredOpt => ({ value: filteredOpt.option_id, name: filteredOpt.option_value })) || []

    return [...visitTypeOptions]?.sort((a, b) => a?.name?.localeCompare(b?.name)) || []
  }, [menuOptions])

  const yearOptionMap = React.useMemo(() => {
    if (!menuOptions) return null
    return menuOptions
      ?.filter(opt => opt.menu === 'year')
      ?.reduce((acc, menuItem) => {
        return {
          ...acc,
          [menuItem.option_id]: {
            name: menuItem.option_value,
            state: true,
          },
        }
      }, {})
  }, [menuOptions])

  const therapistTypeOptionMap = React.useMemo(() => {
    if (!menuOptions) return null
    return menuOptions
      ?.filter(opt => opt.menu === 'therapist_type')
      ?.reduce((acc, menuItem) => {
        return {
          ...acc,
          [menuItem.option_id]: {
            name: menuItem.option_value,
            state: true,
          },
        }
      }, {})
  }, [menuOptions])

  const yearCheckboxOptions = React.useMemo(
    () => getCheckboxOptions({ menuOptions, menuKey: 'year', name: 'year' }),
    [menuOptions],
  )

  const therapistTypeCheckboxOptions = React.useMemo(
    () => getCheckboxOptions({ menuOptions, menuKey: 'therapist_type', name: 'therapistType' }),
    [menuOptions],
  )

  return (
    <Grid
      container
      sx={{
        padding: 2,
        background: '#FFFFFF',
        height: '100%',
      }}
    >
      <Grid item xs={12} sx={{ padding: 1 }}>
        <Typography sx={{ fontSize: '19px', fontWeight: 700 }}>Benchmark Parameters:</Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={6} sx={{ paddingX: 2 }}>
        <Box sx={{ height: '100%' }}>
          <ScenarioParameterDropdown
            label="Discipline Type"
            isLoading={isLoadingFetchMenuOptions}
            menuOptions={therapyOptionMap}
            value={selectedTherapyOption}
            onChange={handleTherapyOptionMapSelect}
            boxStyles={{ paddingBottom: 2, width: '100%' }}
          />

          <ScenarioParameterDropdown
            label="Visit Type"
            isLoading={isLoadingFetchMenuOptions}
            menuOptions={visitTypeOptionMap}
            value={selectedVisitTypeOption}
            onChange={handleVisitType}
          />
        </Box>
      </Grid>
      <Grid item xs={6} lg={2}>
        {isLoadingFetchMenuOptions || !yearOptionMap ? (
          <Box sx={{ paddingX: 2 }}>
            <LoadingSkeleton variant="rectangular" height="212px" width="108px" />
          </Box>
        ) : (
          <Box sx={{ paddingX: 2, display: 'flex', justifyContent: 'center' }}>
            <CheckboxMenuV2
              checkBoxOptions={yearCheckboxOptions}
              onChange={handleYearCheckboxSelect}
              verifyIsChecked={verifyIsChecked(selectedYears)}
            >
              <>
                <FormLabel>Year</FormLabel>
              </>
            </CheckboxMenuV2>
          </Box>
        )}
      </Grid>
      <Grid item xs={6} lg={4}>
        {clinicUpperVal && clinicLowerVal ? (
          <Box sx={{ maxWidth: '200px' }}>
            <ValueSlider
              key={sliderKey} // Not ideal but fast and easy way to reset the slider when scenario save is successful
              sliderTitle="Number of Providers"
              step={1}
              paddingLeft="20px"
              paddingTop="0"
              upperVal={clinicUpperVal}
              lowerVal={clinicLowerVal}
              setUpperVal={setClinicUpperVal}
              setLowerVal={setClinicLowerVal}
              sliderContainerStyles={{ marginTop: 2, marginBottom: 1 }}
            />
          </Box>
        ) : null}

        {isLoadingFetchMenuOptions || !therapistTypeOptionMap ? (
          <Box sx={{ paddingX: 2 }}>
            <LoadingSkeleton variant="rectangular" height="212px" width="108px" />
          </Box>
        ) : (
          <Box sx={{ paddingX: 2 }}>
            <CheckboxMenuV2
              checkBoxOptions={therapistTypeCheckboxOptions}
              onChange={handleTherapistCheckboxSelect}
              verifyIsChecked={verifyIsChecked(selectedTherapistTypes)}
            >
              <>
                <FormLabel>Therapist Type</FormLabel>
              </>
            </CheckboxMenuV2>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default ScenarioParametersCard
