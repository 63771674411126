import { Skeleton } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

/**
 * @param {string} variant - The variant of the skeleton ("circular", "rectangular", "text", "rounded")
 * @param {string} width - The width of the skeleton
 * @param {string} height - The height of the skeleton
 * @param {object} rest - The rest of the props for the MUI Skeleton component
 */
export const LoadingSkeleton = ({ variant, width = '100%', height = '100%', ...rest }) => (
  <Skeleton
    animation="wave"
    variant={variant}
    width={width}
    height={height}
    data-testid={`wave-skeleton-${variant}`}
    sx={{
      maxWidth: '100%',
      ...rest?.sx,
    }}
    {...rest}
  />
)

LoadingSkeleton.propTypes = {
  variant: PropTypes.oneOf(['circular', 'rectangular', 'text', 'rounded']).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
