import React from 'react'
import { Grid } from '@mui/material'
import isEqual from 'lodash.isequal'

import { TOAST_IDS } from '../../context/ToastContext/constants'

import { useAuthContext } from '../../context/AuthContext/useAuthContext'
import { useFetchBenchmarkBestInClassAnalysis } from '../../hooks/useFetchBenchmarkBestInClassAnalysis'
import { useFilterContext } from '../../context/FilterContext/useFilterContext'
import { usePrevious } from '../../hooks/usePrevious'
import { useSidebarContext } from '../../context/SidebarContext/useSidebarContext'

import { buildSelectedUOM } from '../../context/FilterContext/filterContextHelpers'

import BestInClassAnalysis from './pageComponents/BestInClassAnalysis'
import ClinicExplorerGraphWrapper from './pageComponents/ClinicExplorerGraphWrapper'
import ClinicExplorerTable from './pageComponents/ClinicExplorerTable'
import PerformanceScorecard from './pageComponents/PerformanceScorecard'
import withRouterWrapper from '../../components/withRouterWrapper/withRouterWrapper'
import { useFetchPerformanceScoreCard } from '../../hooks/useFetchPerformanceScoreCard'

function ClinicExplorer() {
  const { accessToken } = useAuthContext()
  const { scenarioParamsWithMetadata, isLoadingFetchScenarioParams } = useSidebarContext()
  const { selectedScenario, unitOfMeasurement, unitOfTime, isLoadingFetchScenarios } = useFilterContext()
  const {
    isLoading: isLoadingGetBenchmarkBestInClassAnalysis,
    fetchBenchmarkBestInClassAnalysis,
    defaultAppointmentDateYear,
    benchmarkRadarChartGroup,
    portfolioRadarChartGroup,
    labelsRadarChartGroup,
  } = useFetchBenchmarkBestInClassAnalysis({
    defaultIsLoading: true,
  })

  const {
    isLoading: isLoadingGetPerformanceScoreCard,
    fetchPerformanceScoreCard,
    performanceScoreCard,
  } = useFetchPerformanceScoreCard()

  const [selectedClinicDetails, setSelectedClinicDetails] = React.useState(null)
  const [selectedClinicId, setSelectedClinicId] = React.useState(null)
  const [selectedMetric, setSelectedMetric] = React.useState({
    value: 'completed_appointments',
    displayText: 'Completed Appointments',
  })

  const bestInClassAnalysisParams = React.useMemo(() => {
    if (!unitOfMeasurement || !unitOfTime || !selectedScenario?.scenarioid || !selectedMetric?.value) return
    return {
      units_payload: {
        rate: unitOfMeasurement,
        time_granularity: unitOfTime,
      },
      scenarioid: selectedScenario?.scenarioid,
      metric: selectedMetric?.value,
    }
  }, [unitOfMeasurement, unitOfTime, selectedScenario?.scenarioid, selectedMetric?.value])

  const performanceScoreCardParams = React.useMemo(() => {
    if (!unitOfTime || !selectedScenario?.scenarioid || !selectedClinicId) {
      return
    }

    return {
      units_payload: {
        rate: unitOfMeasurement,
        time_granularity: unitOfTime,
      },
      scenario_id: selectedScenario?.scenarioid,
      clinic_id: selectedClinicId,
    }
  }, [unitOfTime, selectedScenario?.scenarioid, selectedClinicId, unitOfMeasurement])

  const handleMetricSelect = (e, i) => {
    setSelectedMetric({
      value: e.target.value,
      displayText: i.props.name,
    })
  }

  // Fetch benchmark clinic summary table whenever any of the arguments are updated
  React.useEffect(() => {
    if (accessToken && bestInClassAnalysisParams) {
      fetchBenchmarkBestInClassAnalysis({
        accessToken,
        errorToastId: TOAST_IDS.getBenchmarkBestInClassAnalysisError,
        bestInClassAnalysisParams,
      })
    }
  }, [fetchBenchmarkBestInClassAnalysis, accessToken, bestInClassAnalysisParams])

  const previousSelectedScenarioParams = usePrevious(scenarioParamsWithMetadata)
  const hasDetectedChangeInScenarioParams =
    !!previousSelectedScenarioParams && !isEqual(scenarioParamsWithMetadata, previousSelectedScenarioParams)
  const isChangeFromSelectedScenarioUpdate =
    previousSelectedScenarioParams?.scenario_metadata?.[0]?.scenarioid !==
    scenarioParamsWithMetadata?.scenario_metadata?.[0]?.scenarioid
  const shouldRefetchForScenarioParamUpdate = hasDetectedChangeInScenarioParams && !isChangeFromSelectedScenarioUpdate
  const shouldRefetchForPerformanceScoreCardUpdate =
    hasDetectedChangeInScenarioParams && !isChangeFromSelectedScenarioUpdate
  /**
   * Special case to refetch when scneario params are updated using the sidebar
   * Note that we need this because the useEffect above does not cover this case.
   * During this case, the arguments do not change and so the useEffect above will not run.
   */
  React.useEffect(() => {
    if (accessToken && bestInClassAnalysisParams && shouldRefetchForScenarioParamUpdate) {
      fetchBenchmarkBestInClassAnalysis({
        accessToken,
        errorToastId: TOAST_IDS.getBenchmarkBestInClassAnalysisError,
        bestInClassAnalysisParams,
      })
    }
  }, [fetchBenchmarkBestInClassAnalysis, accessToken, bestInClassAnalysisParams, shouldRefetchForScenarioParamUpdate])

  const previousClinicId = usePrevious(selectedClinicId)

  React.useEffect(() => {
    if (!isEqual(selectedClinicId, previousClinicId)) {
      if (!selectedClinicId) {
        setSelectedClinicId(null)
      } else {
        setSelectedClinicId(selectedClinicId)
      }
    }
  }, [selectedClinicId, previousClinicId])

  React.useEffect(() => {
    if (accessToken && performanceScoreCardParams) {
      fetchPerformanceScoreCard({
        accessToken,
        errorToastId: TOAST_IDS.fetchPerformanceScoreCardError,
        performanceScoreCardParams: performanceScoreCardParams,
      })
    }
  }, [performanceScoreCardParams, accessToken, fetchPerformanceScoreCard, shouldRefetchForPerformanceScoreCardUpdate])

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: { xs: 2, md: 0 } }}>
        <ClinicExplorerTable
          setSelectedClinicId={setSelectedClinicId}
          setSelectedClinicDetails={setSelectedClinicDetails}
          handleRowClick={row => {
            if (selectedClinicId === row.getValue('clinic_id')) return
            setSelectedClinicId(row.getValue('clinic_id'))
            setSelectedClinicDetails(row.original)
          }}
          isLoading={isLoadingFetchScenarioParams || isLoadingGetBenchmarkBestInClassAnalysis}
        />
      </Grid>
      <ClinicExplorerGraphWrapper
        defaultAppointmentDateYear={defaultAppointmentDateYear}
        selectedClinicId={selectedClinicId}
        selectedMetricValue={selectedMetric?.value}
        yearlyDataPerformanceScoreCard={performanceScoreCard}
      >
        {({
          clinicTrendData,
          handleYearSelect,
          isLoadingGetClinicBenchmarkTrend,
          selectedYear,
          selectedYearSummaryData,
          yearList,
        }) => (
          <>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <PerformanceScorecard
                clinicTrendData={clinicTrendData}
                handleMetricSelect={handleMetricSelect}
                handleYearSelect={handleYearSelect}
                isLoadingFetchScenarioParams={isLoadingFetchScenarioParams}
                isLoadingGetBenchmarkBestInClassAnalysis={
                  isLoadingGetBenchmarkBestInClassAnalysis || isLoadingGetPerformanceScoreCard
                }
                isLoadingGetClinicBenchmarkTrend={isLoadingGetClinicBenchmarkTrend}
                selectedClinicName={selectedClinicDetails?.clinic_name}
                selectedMetric={selectedMetric}
                selectedYear={selectedYear}
                selectedYearSummaryData={selectedYearSummaryData}
                yearList={yearList}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <BestInClassAnalysis
                benchmarkRadarChartGroup={benchmarkRadarChartGroup}
                handleYearSelect={handleYearSelect}
                isLoadingBestInClassCharts={isLoadingGetClinicBenchmarkTrend}
                isLoadingYearList={isLoadingGetBenchmarkBestInClassAnalysis || isLoadingFetchScenarioParams}
                labelsRadarChartGroup={labelsRadarChartGroup}
                portfolioRadarChartGroup={portfolioRadarChartGroup}
                selectedYear={selectedYear}
                shouldDisableYearDropdown={
                  isLoadingGetBenchmarkBestInClassAnalysis ||
                  isLoadingGetClinicBenchmarkTrend ||
                  isLoadingFetchScenarioParams
                }
                targetClinicRadar={selectedYearSummaryData}
                unitOfMeasure={buildSelectedUOM({ unitOfMeasurement, unitOfTime })}
                yearList={yearList}
              />
            </Grid>
          </>
        )}
      </ClinicExplorerGraphWrapper>
    </Grid>
  )
}

export default withRouterWrapper(ClinicExplorer)({ shouldRedirectNewUser: true })
