import React from 'react'
import { Box } from '@mui/material'
import PerformanceCard from '../../../../components/performanceCard/performanceCard'
import { useToastContext } from '../../../../context/ToastContext/useToastContext'
import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import { getEfficiencyMetricOverview } from '../../../../services/apis'
import { usePrevious } from '../../../../hooks/usePrevious'
import isEqual from 'lodash.isequal'
import { useSidebarContext } from '../../../../context/SidebarContext/useSidebarContext'

const PAGE = 'overview'

const EFFICIENCY_ITEM_GROUP = {
  Benchmark: 'Benchmark',
  Portfolio: 'Portfolio',
}

/**
 * For some reason this is being appended when determining
 * portfolio performance and benchmark performance
 */
const AVERAGE_SCHEDULED_APPOINTMENTS_METRIC_OPTION = {
  value: 'avg_scheduled_appointments',
  name: 'Average Scheduled Appointments',
}

const BenchmarkPerformanceCard = ({
  unitOfMeasurement,
  unitOfTime,
  selectedMetric,
  isLoadingSpinnerEfficiencyTable,
  setIsLoadingSpinnerEfficiencyTable,
  children,
}) => {
  const { addToast } = useToastContext()
  const { accessToken } = useAuthContext()
  const { selectedScenario, isLoadingFetchScenarios } = useFilterContext()
  const { scenarioParamsWithMetadata, isLoadingFetchScenarioParams } = useSidebarContext()

  const scenarioid = selectedScenario?.scenarioid

  const [efficiencyTableData, setEfficiencyTableData] = React.useState(null)

  const fetchEfficiencyMetricOverview = React.useCallback(async () => {
    try {
      setIsLoadingSpinnerEfficiencyTable(true)
      if (unitOfMeasurement && unitOfTime && scenarioid) {
        // Call Efficiency Metric Overviews
        const efficiencyMetricPayload = {
          page: PAGE,
          scenarioid: scenarioid,
          units_payload: {
            rate: unitOfMeasurement,
            time_granularity: unitOfTime,
          },
        }

        const res = await getEfficiencyMetricOverview(accessToken, efficiencyMetricPayload)
        setEfficiencyTableData(res?.data?.efficiency_metrics)
      }
    } catch (error) {
      addToast({
        toastId: TOAST_IDS.getEffMetricErrForBenchmarkExplorer,
        variant: 'error',
        msg: `Error loading efficiency metric overview - ${error?.message || 'unknown error'}`,
      })
      setEfficiencyTableData([])
    } finally {
      setIsLoadingSpinnerEfficiencyTable(false)
    }
  }, [unitOfMeasurement, unitOfTime, scenarioid, accessToken, addToast, setIsLoadingSpinnerEfficiencyTable])

  React.useEffect(() => {
    fetchEfficiencyMetricOverview()
  }, [fetchEfficiencyMetricOverview])

  const previousSelectedScenarioParams = usePrevious(scenarioParamsWithMetadata)
  React.useEffect(() => {
    // only run use effect if there were previously scenarioParamsWithMetadata defined && now the previous render values does not equal current value
    if (!!previousSelectedScenarioParams && !isEqual(scenarioParamsWithMetadata, previousSelectedScenarioParams)) {
      fetchEfficiencyMetricOverview()
    }
  }, [previousSelectedScenarioParams, fetchEfficiencyMetricOverview, scenarioParamsWithMetadata])

  const portfolioPerformance = React.useMemo(() => {
    const metricSelect =
      selectedMetric.value === 'scheduled_appointments'
        ? AVERAGE_SCHEDULED_APPOINTMENTS_METRIC_OPTION.value
        : selectedMetric.value

    if (Array.isArray(efficiencyTableData)) {
      const portfolioPerformance = efficiencyTableData.find(item => item.group === EFFICIENCY_ITEM_GROUP.Portfolio)
      return portfolioPerformance?.[metricSelect] || 0
    }
    return 0
  }, [efficiencyTableData, selectedMetric.value])

  const benchmarkPerformance = React.useMemo(() => {
    const metricSelect =
      selectedMetric.value === 'scheduled_appointments'
        ? AVERAGE_SCHEDULED_APPOINTMENTS_METRIC_OPTION.value
        : selectedMetric.value

    if (Array.isArray(efficiencyTableData)) {
      const benchmarkPerformance = efficiencyTableData.find(item => item.group === EFFICIENCY_ITEM_GROUP.Benchmark)
      return benchmarkPerformance?.[metricSelect] || 0
    }
    return 0
  }, [efficiencyTableData, selectedMetric.value])

  const isLoading = isLoadingSpinnerEfficiencyTable || isLoadingFetchScenarioParams || isLoadingFetchScenarios
  return (
    <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end', height: '100%' } }}>
      <Box sx={{ maxWidth: { xs: '230px', lg: '300px', height: '100%' } }}>
        <PerformanceCard
          isLoading={isLoading}
          benchmarkPerformance={benchmarkPerformance || 0}
          portfolioPerformance={portfolioPerformance || 0}
        >
          {children}
        </PerformanceCard>
      </Box>
    </Box>
  )
}

export default BenchmarkPerformanceCard
