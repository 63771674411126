import React from 'react'

import { Box } from '@mui/material'
import { useAppContext } from '../../../context/AppContext/useAppContext'
import TitledDropdown from '../../../components/titledDropdown'
import LoadingSkeleton from '../../../components/LoadingSkeleton'

const OverviewMetricList = ({
  selectedMetric,
  setSelectedMetric,
  isLoadingTrendSpinner,
  isLoadingSpinnerDistribution,
}) => {
  const { menuOptions, isLoadingFetchMenuOptions } = useAppContext()
  const metricList = React.useMemo(() => {
    return (
      menuOptions
        ?.filter(opt => opt.menu === 'metrics')
        ?.map(filteredOpt => ({ value: filteredOpt.option_id, name: filteredOpt.option_value })) || []
    )
  }, [menuOptions])

  const isDisabled = isLoadingTrendSpinner || isLoadingSpinnerDistribution
  return (
    <Box sx={{ maxWidth: '300px', minHeight: '65px', width: '100%', height: '100%' }}>
      <TitledDropdown
        id="overview-metric-dropdown"
        label="Efficiency Metric"
        isLoading={isLoadingFetchMenuOptions}
        isDisabled={isDisabled}
        menuOptions={metricList}
        value={selectedMetric.value}
        onChange={(e, i) => {
          setSelectedMetric({
            value: e.target.value,
            name: i.props.name,
          })
        }}
        selectStyles={{ borderRadius: '5px', width: '100%', height: '65px' }}
        formControlStyles={{ width: '100%' }}
        renderLoading={() => <LoadingSkeleton variant="rounded" height="65px" />}
      />
    </Box>
  )
}

export default OverviewMetricList
