import React from 'react'

import { useToastContext } from '../../../ToastContext/useToastContext'
import { listAllPortfolios } from '../../../../services/apis'

/**
 * Custom hook for fetching portfolios.
 * @param {Object} options - The options for fetching portfolios.
 * @param {boolean} options.defaultIsLoading - The default value for isLoading state.
 * @returns {Object} An object containing isLoading, error, portfoliosSorted, and fetchPortfolios.
 * @returns {boolean} object.isLoading - whether the portfolios are loading
 * @returns {Object} object.error - any error that occurred while fetching the portfolios
 * @returns {Array} object.portfoliosSorted - the sorted portfolios
 * @returns {function} object.fetchPortfolios - function to fetch portfolios
 */
export const useFetchPorfolios = ({ defaultIsLoading = false } = { defaultIsLoading: false }) => {
  const { addToast } = useToastContext()

  /**
   * @type {Array.<{portfolioid: string, created_by_userid: string, portfolio_title: string, portfolio_description: string, create_date: string, list_of_clinics: Array.<string>}>|[]}
   * An array of portfolio objects, sorted in a specific order. Each portfolio object contains the following properties:
   * @property {string} portfolioid - The ID of the portfolio.
   * @property {string} created_by_userid - The user ID of the creator of the portfolio.
   * @property {string} portfolio_title - The title of the portfolio.
   * @property {string} portfolio_description - The description of the portfolio.
   * @property {string} create_date - The creation date of the portfolio.
   * @property {Array.<string>} list_of_clinics - An array of clinic IDs associated with the portfolio.
   * If there are no portfolios, this variable is an empty array.
   */
  const [portfoliosSorted, setPortfoliosSorted] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)

  /**
   * Fetches portfolios using the provided access token.
   *
   * @param {Object} options - The options for fetching portfolios.
   * @param {string} options.accessToken - The string access token used for authentication.
   * @param {string} options.errorToastId - The string ID of the toast to display if an error occurs.
   * @returns {Promise<Array>} A promise that resolves to an array of sorted portfolios.
   */
  const fetchPortfolios = React.useCallback(
    async ({ accessToken, errorToastId }) => {
      try {
        if (!accessToken || !errorToastId) {
          throw new Error('accessToken and errorToastId are required')
        }
        setIsLoading(true)
        const portfolioListResponse = (await listAllPortfolios(accessToken)) || []
        const sortedPortfolioList = [...portfolioListResponse]?.sort((a, b) =>
          a?.portfolio_title?.localeCompare(b?.portfolio_title),
        )
        setPortfoliosSorted(sortedPortfolioList)
        return sortedPortfolioList
      } catch (error) {
        console.error(error)
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading portfolio list - ${error?.message || 'unknown error'}`,
        })
        setError(error)
        setPortfoliosSorted([])
      } finally {
        setIsLoading(false)
      }
    },
    [addToast],
  )

  return {
    isLoading,
    error,
    portfoliosSorted,
    fetchPortfolios,
  }
}
