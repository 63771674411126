import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import CreateScenario from './CreateScenario'
import ScenarioTable from './ScenarioTable'
import { EditScenario } from './EditScenario/EditScenario'

export const ScenarioManagerCard = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false)
  const [selectedScenarioid, setSelectedScenarioid] = React.useState()

  const handleCloseModal = () => {
    setSelectedScenarioid(undefined)
    setIsEditDialogOpen(false)
  }

  const handleEditRowClick = async row => {
    setSelectedScenarioid(row.scenarioid)
    setIsEditDialogOpen(true)
  }

  return (
    <Box sx={{ padding: 4, width: '100%', height: '100%' }}>
      <Grid container sx={{ border: '2px solid #F5F8F9', padding: 2 }}>
        <Grid item xs={6} sx={{ marginBottom: 2 }}>
          <Typography component="h2" sx={{ fontSize: '20pt', fontWeight: 700 }}>
            Scenario Manager
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ marginBottom: 2 }}>
          <CreateScenario />
        </Grid>

        <Grid item xs={12}>
          <EditScenario
            isEditDialogOpen={isEditDialogOpen}
            handleCloseModal={handleCloseModal}
            selectedScenarioid={selectedScenarioid}
          />
          <ScenarioTable handleEditRowClick={handleEditRowClick} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ScenarioManagerCard
