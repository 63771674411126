const mockData1 = [
  {
    portfolio_title:
      'Portfolio 1 asldkfj asdkfj asdkfj lkajsd flkajsdfklsdjfk asdfkalsdjflsjdf asldkfjal skdjf kjs dfjosjdfklsjdflakj ks fjlaskjdf lkajs foiwejfi sdifj woiejfskdf ',
  },
  {
    portfolio_title: 'Portfolio 2',
  },
  {
    portfolio_title: 'Portfolio 3',
  },
  {
    portfolio_title: 'Portfolio 4',
  },
  {
    portfolio_title: 'Portfolio 5',
  },
  {
    portfolio_title: 'Portfolio 6',
  },
  {
    portfolio_title: 'Portfolio 7',
  },
  {
    portfolio_title: 'Portfolio 8',
  },
  {
    portfolio_title: 'Portfolio 9',
  },
  {
    portfolio_title: 'Portfolio 10',
  },
  {
    portfolio_title: 'Portfolio 11',
  },
  {
    portfolio_title: 'Portfolio 12',
  },
  {
    portfolio_title: 'Portfolio 13',
  },
  {
    portfolio_title: 'Portfolio 14',
  },
  {
    portfolio_title: 'Portfolio 15',
  },
  {
    portfolio_title: 'Portfolio 16',
  },
  {
    portfolio_title: 'Portfolio 17',
  },
  {
    portfolio_title: 'Portfolio 18',
  },
  {
    portfolio_title: 'Portfolio 19',
  },
  {
    portfolio_title: 'Portfolio 20',
  },
]

const mockData2 = [
  {
    portfolio_title: 'Portfolio 21',
  },
  {
    portfolio_title: 'Portfolio 22',
  },
  {
    portfolio_title: 'Portfolio 23',
  },
  {
    portfolio_title: 'Portfolio 24',
  },
  {
    portfolio_title: 'Portfolio 25',
  },
  {
    portfolio_title: 'Portfolio 26',
  },
  {
    portfolio_title: 'Portfolio 27',
  },
  {
    portfolio_title: 'Portfolio 28',
  },
  {
    portfolio_title: 'Portfolio 29',
  },
  {
    portfolio_title: 'Portfolio 30',
  },
  {
    portfolio_title: 'Portfolio 31',
  },
  {
    portfolio_title: 'Portfolio 32',
  },
  {
    portfolio_title: 'Portfolio 33',
  },
  {
    portfolio_title: 'Portfolio 34',
  },
  {
    portfolio_title: 'Portfolio 35',
  },
  {
    portfolio_title: 'Portfolio 36',
  },
  {
    portfolio_title: 'Portfolio 37',
  },
  {
    portfolio_title: 'Portfolio 38',
  },
  {
    portfolio_title: 'Portfolio 39',
  },
  {
    portfolio_title: 'Portfolio 40',
  },
]

const mockData3 = [
  {
    portfolio_title: 'Portfolio 41',
  },
  {
    portfolio_title: 'Portfolio 42',
  },
  {
    portfolio_title: 'Portfolio 43',
  },
  {
    portfolio_title: 'Portfolio 44',
  },
  {
    portfolio_title: 'Portfolio 45',
  },
  {
    portfolio_title: 'Portfolio 46',
  },
  {
    portfolio_title: 'Portfolio 47',
  },
  {
    portfolio_title: 'Portfolio 48',
  },
  {
    portfolio_title: 'Portfolio 49',
  },
  {
    portfolio_title: 'Portfolio 50',
  },
  {
    portfolio_title: 'Portfolio 51',
  },
  {
    portfolio_title: 'Portfolio 52',
  },
  {
    portfolio_title: 'Portfolio 53',
  },
  {
    portfolio_title: 'Portfolio 54',
  },
  {
    portfolio_title: 'Portfolio 55',
  },
  {
    portfolio_title: 'Portfolio 56',
  },
  {
    portfolio_title: 'Portfolio 57',
  },
  {
    portfolio_title: 'Portfolio 58',
  },
  {
    portfolio_title: 'Portfolio 59',
  },
  {
    portfolio_title: 'Portfolio 60',
  },
]

// the dataframes library requires that the property "id" is always defined for each record
export const rowsWithRowId1 = (mockData1 || []).map((row, index) => {
  if (typeof row?.id === 'undefined') {
    return {
      ...row,
      id: index + 1,
    }
  }

  return row
})

export const rowsWithRowId2 = (mockData2 || []).map((row, index) => {
  if (typeof row?.id === 'undefined') {
    return {
      ...row,
      id: 20 + index + 1,
    }
  }

  return row
})

export const rowsWithRowId3 = (mockData3 || []).map((row, index) => {
  if (typeof row?.id === 'undefined') {
    return {
      ...row,
      id: 40 + index + 1,
    }
  }
})
