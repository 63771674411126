import React from 'react'
import { Box, Typography } from '@mui/material'

import CircularButtonGroup from '../CircularButton/CircularButtonGroup'
import { TOAST_IDS } from '../../context/ToastContext/constants'
import { VALUE_DISPLAY_BUTTON_OPTIONS, VALUE_DISPLAY_ID } from './valueDisplay.constants'

import { useToastContext } from '../../context/ToastContext/useToastContext'

export default function ValueDisplaySelector({
  children,
  handleValueDisplay,
  selectedValueDisplay,
  visibleButtons,
  ...buttonProps
}) {
  const { addToast } = useToastContext()

  const handleValueDisplaySwitch = id => {
    if (!Object.values(VALUE_DISPLAY_ID).includes(id)) {
      return addToast({
        toastId: TOAST_IDS.valueDisplaySelectorError,
        variant: 'error',
        msg: `Error updating the selected unit of measurement filter.`,
      })
    }

    handleValueDisplay(id)
  }

  // Filter the button options based on visibleButtons
  const filteredButtonOptions = VALUE_DISPLAY_BUTTON_OPTIONS.filter(button => visibleButtons.includes(button.id))

  return (
    <Box>
      {children}
      <Box>
        <CircularButtonGroup
          options={filteredButtonOptions}
          handleClick={id => handleValueDisplaySwitch(id)}
          selectedButtonId={selectedValueDisplay}
          sx={{ minHeight: '42px', fontSize: '13px', boxShadow: 0 }}
          {...buttonProps}
        />
      </Box>
    </Box>
  )
}
