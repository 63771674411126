const DEFAULT_FETCH_OPTIONS = {
  keepalive: true,
}

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
}

/**
 * customFetch is a wrapper around fetch that adds default headers and options
 * @param {string} url - The url
 * @param {Object} options - The options object
 * @returns {Promise<Function>}
 */
export const customFetch = async (url, options = {}) => {
  return fetch(url, {
    ...DEFAULT_FETCH_OPTIONS,
    ...options,
    headers: {
      ...DEFAULT_HEADERS,
      ...options.headers,
    },
  })
}
