import React from 'react'
import { useToastContext } from '../../../ToastContext/useToastContext'
import { listAllClinics } from '../../../../services/apis'

/**
 * useFetchClinics is a hook to fetch clinics and sort them alphabetically
 *
 * @param {Object} options
 * @param {boolean} options.defaultIsLoading - default value for isLoading (in the event we know we will be fetching on load)
 * @returns {Object} - { isLoading, error, clinicsSorted, fetchClinics }
 * @returns {boolean} object.isLoading - whether the clinics are loading
 * @returns {Object} object.error - the error object
 * @returns {Array} object.clinicsSorted - the sorted clinics arrays of objects
 * @returns {Function} object.fetchClinics - function to fetch clinics
 */
export const useFetchClinics = options => {
  const defaultIsLoading = options?.defaultIsLoading
  const { addToast } = useToastContext()

  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)
  const [clinicsSorted, setClinicsSorted] = React.useState([])

  /**
   * Fetches clinics using the provided access token and displays an error toast if an error occurs.
   *
   * @param {Object} options - The options for fetching clinics.
   * @param {string} options.accessToken - The access token used for authentication.
   * @param {string} options.errorToastId - The ID of the error toast to display.
   * @returns {Promise<void>} A promise that resolves when the clinics have been fetched.
   * @throws {Error} - If accessToken or errorToastId is missing.
   */
  const fetchClinics = React.useCallback(
    async ({ accessToken, errorToastId }) => {
      try {
        if (!accessToken || !errorToastId) {
          throw new Error('accessToken and errorToastId are required')
        }
        setIsLoading(true)
        const clinics = (await listAllClinics(accessToken)) || []
        const sortedClinics = [...clinics]?.sort((a, b) => a?.clinic_name?.localeCompare(b.clinic_name))
        setClinicsSorted(sortedClinics)
      } catch (e) {
        console.error(e)
        setError(e)
        setClinicsSorted([])
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading clinic list - ${e?.message || 'unknown error'}`,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast],
  )

  return {
    isLoading,
    error,
    clinicsSorted,
    fetchClinics,
  }
}
