import React from 'react'
import { getMenuOptions } from '../../../../services/apis'
import { useToastContext } from '../../../ToastContext/useToastContext'

/**
 * Custom hook for fetching menu options.
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.defaultIsLoading - The default value for isLoading state.
 * @returns {Object} - An object containing the fetchMenuOptions function, menuOptions array, isLoading boolean, and error object.
 * @returns {function} object.fetchMenuOptions - The function to fetch menu options.
 * @returns {Array} object.menuOptions - The array of menu options.
 * @returns {boolean} object.isLoading - Whether the menu options are loading.
 * @returns {Object} object.error - The error object if an error occurred.
 */
export const useFetchMenuOptions = options => {
  const defaultIsLoading = options?.defaultIsLoading
  const { addToast } = useToastContext()

  const [menuOptions, setMenuOptions] = React.useState([])
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)

  /**
   * Fetches menu options using the provided access token.
   *
   * @param {Object} options - The options object.
   * @param {string} options.accessToken - The access token required for the API request.
   * @param {string} options.errorToastId - The ID of the error toast to display.
   * @returns {Promise<void>} - A promise that resolves when the menu options are fetched.
   * @throws {Error} - If accessToken or errorToastId is missing.
   */
  const fetchMenuOptions = React.useCallback(
    async ({ accessToken, errorToastId }) => {
      try {
        if (!accessToken || !errorToastId) {
          throw new Error('accessToken and errorToastId are required')
        }
        setIsLoading(true)
        const menuOptionsResponse = await getMenuOptions(accessToken)
        setMenuOptions(menuOptionsResponse)
        setError(null)
      } catch (error) {
        console.error(error)
        setError(error)
        setMenuOptions([])
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading menu options - ${error?.message || 'unknown error'}`,
        })
      } finally {
        setIsLoading(false)
      }
    },
    [addToast],
  )

  return {
    fetchMenuOptions,
    menuOptions,
    isLoading,
    error,
  }
}
