import React from 'react'
import debounce from 'lodash/debounce'

/**
 * Custom hook that returns the debounced width of a DOM element.
 * @param {React.RefObject} ref - The ref object of the DOM element whose width needs to be tracked.
 * @param {number} [delay=300] - The delay in milliseconds before updating the width after a resize event.
 * @returns {number} - The debounced width of the DOM element.
 */
export const useDebouncedWidth = (ref, delay = 300) => {
  const [width, setWidth] = React.useState(0)

  React.useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth)
      }
    }

    // Initial width calculation
    updateWidth()

    // Debounce the window resize event
    const debouncedResize = debounce(updateWidth, delay)

    window.addEventListener('resize', debouncedResize)

    return () => {
      // Clean up event listener on component unmount
      window.removeEventListener('resize', debouncedResize)
    }
  }, [ref, delay])

  return width
}
