import React from 'react'
import { Card, CardContent, Box, Skeleton } from '@mui/material'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function PerformanceDistributionCard({
  children,
  selectedBenchmark,
  data,
  isLoading,
  marginBottom = 0,
  cardHeight,
  graphHeight,
} = {}) {
  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: 'Count of Clinics',
          font: {
            family: 'Lato', // Add your font here to change the font of your y axis
          },
        },
        ticks: {
          stepSize: 1, // Add your step size here to change the step size of your y axis
          font: {
            family: 'Lato', // Add your font here to change the font of your y axis
          },
        },
      },
      x: {
        title: {
          display: true,
          text: selectedBenchmark + ' (Binned)',
          font: {
            family: 'Lato', // Add your font here to change the font of your y axis
          },
        },
        ticks: {
          font: {
            family: 'Lato', // Add your font here to change the font of your y axis
          },
        },
      },
    },
    barPercentage: 0.4,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        font: {
          family: 'Lato', // Add your font here to change the font of your y axis
        },
      },

      title: {
        display: false,
      },
    },
  }

  return (
    <Card sx={{ marginBottom, padding: 2, minHeight: cardHeight }}>
      <CardContent>
        {children}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100%',
            minHeight: graphHeight,
          }}
        >
          <>
            {isLoading ? (
              <Box sx={{ width: '100%' }}>
                <Skeleton animation="wave" variant="rectangular" height={graphHeight} />
              </Box>
            ) : (
              <>
                {data?.clinic_distribution ? (
                  <>
                    {data?.clinic_distribution && (
                      <Bar options={options} data={data?.clinic_distribution} style={{ maxHeight: graphHeight }} />
                    )}
                  </>
                ) : null}
              </>
            )}
          </>
        </Box>
      </CardContent>
    </Card>
  )
}
