import React from 'react'
import { Toast } from '../../components/Toast'
import { ToastStack } from '../../components/Toast/ToastStack'
import { useToastContext } from './useToastContext'

/**
 * Display toasts renders the toasts array from the toast context in the toast stack.
 * @returns {JSX.Element}
 */
const DisplayToasts = () => {
  const { toasts, removeToast } = useToastContext()

  const mappedToasts = toasts.map(toast => {
    return (
      <Toast
        key={toast.toastId}
        toastId={toast.toastId}
        portalId={toast.portalId}
        open={toast.open}
        variant={toast.variant}
        onRequestClose={removeToast}
        timeout={toast.timeout}
      >
        {toast.msg}
      </Toast>
    )
  })

  return <ToastStack>{mappedToasts}</ToastStack>
}

export default DisplayToasts
