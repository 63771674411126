import React from 'react'
import { useToastContext } from '../ToastContext/useToastContext'
import { TOAST_IDS } from '../ToastContext/constants'
import { useAuthContext } from '../AuthContext/useAuthContext'
import { useUnitOfMeasurementFilter } from './hooks/useUnitOfMeasurementFilter'
import { useUnitOfTimeFilter } from './hooks/useUnitOfTimeFilter'
import { useScenarioFilter } from './hooks/useScenarioFilter'

export const FilterContext = React.createContext()

/**
 * Provides a context for filtering data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The filtered data context provider.
 */
export const FilterContextProvider = ({ children }) => {
  const { accessToken } = useAuthContext()

  const { unitOfMeasurement, updateUnitOfMeasurement } = useUnitOfMeasurementFilter()
  const { unitOfTime, updateUnitOfTime } = useUnitOfTimeFilter()
  const {
    isLoading: isLoadingFetchScenarios,
    scenarios,
    selectedScenario,
    fetchScenarios,
    updateSelectedScenario,
  } = useScenarioFilter({
    defaultIsLoading: true,
  })

  const hasSetInitialSelectedScenario = React.useRef(false)
  React.useEffect(() => {
    if (hasSetInitialSelectedScenario.current || !accessToken) return
    hasSetInitialSelectedScenario.current = true

    fetchScenarios({
      accessToken,
      errorToastId: TOAST_IDS.scenarioListErrorForFilterContext,
    })
  }, [accessToken, fetchScenarios])

  return (
    <FilterContext.Provider
      value={{
        isLoadingFetchScenarios,
        scenarios,
        selectedScenario,
        unitOfMeasurement,
        unitOfTime,
        refetchScenarios: fetchScenarios,
        updateSelectedScenario,
        updateUnitOfMeasurement,
        updateUnitOfTime,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
