import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import styled from '@emotion/styled'

/**
 * Styles taken from POC when classes were set to "active" or "inactive"
 * and this was refactored because setting this value to active or inactive
 */

const sharedBaseButtonStyles = `
  border-radius: 15px;
  border: 1px solid #ebebeb;
  color: #282e34;
  margin: 3px;
  padding: 6px 16px;
`

const sharedBaseHoverStyles = `
  color: #fff;
  webkit-text-decoration: none;
  text-decoration: none;
  background-color: #1565c0;
  border: 1px solid #1565c0;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`

const ActiveButton = styled(Button)`
  background: #ebebeb;
  ${sharedBaseButtonStyles}

  &:hover {
    ${sharedBaseHoverStyles}
  }
`

const InactiveButton = styled(Button)`
  background: #ffffff;
  ${sharedBaseButtonStyles}

  &:hover {
    ${sharedBaseHoverStyles}
  }
`

/**
 * CircularButton component represents a circular button element.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isActive - Indicates whether the button is active or not.
 * @param {ReactNode} props.children - The content to be displayed inside the button.
 * @param {Object} buttonProps - Optional Aaditional props to be spread on the button element (e.g. onChange).
 * @returns {ReactElement} The rendered CircularButton component.
 */
const CircularButton = ({ isActive, children, ...buttonProps }) => (
  <>
    {isActive ? (
      <ActiveButton variant="contained" data-testid={`circular-button-isactive-true`} {...buttonProps}>
        {children}
      </ActiveButton>
    ) : (
      <InactiveButton variant="contained" data-testid={`circular-button-isactive-false`} {...buttonProps}>
        {children}
      </InactiveButton>
    )}
  </>
)

CircularButton.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  buttonProps: PropTypes.object,
}

export default CircularButton
