import { AppBar, Toolbar, Box, Divider, Hidden } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import newLogo from '../../assets/newLogo.png'
import { ProfileComponent } from '../Profile/Profile'
import { APP_PAGES_MAP } from './navbar.constants'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthContext } from '../../context/AuthContext/useAuthContext'
import { useAppContext } from '../../context/AppContext/useAppContext'
import LoadingSkeletonComponent from '../LoadingSkeleton'
import CircularButton from '../CircularButton/CircularButton'

const LoadingSkeleton = ({ width, dataTestId }) => (
  <Box sx={{ padding: '16px 6px' }}>
    <LoadingSkeletonComponent
      variant="rounded"
      height="34px"
      width={width}
      data-testid={dataTestId}
      sx={{ borderRadius: '15px' }}
    />
  </Box>
)

const SHOULD_SHOW_DM_UPDATE_PAGE = window.env.SHOULD_SHOW_DM_UPDATE_PAGE === 'true'

/**
 * Renders the Navbar component.
 *
 * @returns {JSX.Element} The rendered Navbar component.
 */
export default function Navbar() {
  const { isLoading: isAuth0Loading, error: auth0Error, isAuthenticated } = useAuth0()
  const {
    isLoadingFetchTokenSilently,
    fetchTokenSilentlyError,
    accessToken,
    isLoadingValidatePermissions,
    validatePermissionsError,
    isAuthorizedWithPermission,
  } = useAuthContext()

  const { isLoadingFetchUserPreferences, defaultScenarioId } = useAppContext()

  const location = useLocation()
  const pathname = location.pathname

  const currentPathname = React.useMemo(
    () =>
      Object.keys(APP_PAGES_MAP).reduce((acc, key) => {
        const itemWithPath = APP_PAGES_MAP[key].path === pathname
        if (itemWithPath) {
          return pathname
        }
        return acc
      }, undefined),
    [pathname],
  )

  const isOverviewPageSelected = [APP_PAGES_MAP.OVERVIEW.path, APP_PAGES_MAP.HOME.path].includes(currentPathname)
  const isBenchmarkExplorerPageSelected = [APP_PAGES_MAP.BENCHMARK_EXPLORER.path].includes(currentPathname)
  const isClinicExplorerPageSelected = [APP_PAGES_MAP.CLINIC_EXPLORER.path].includes(currentPathname)
  const isDataManagerPageSelected = [APP_PAGES_MAP.DATA_MANAGER.path].includes(currentPathname)

  const isLoading =
    isAuth0Loading || isLoadingFetchTokenSilently || isLoadingValidatePermissions || isLoadingFetchUserPreferences

  const hasAuthError = auth0Error || fetchTokenSilentlyError || validatePermissionsError
  const isFirstTimeUser = !isLoadingFetchUserPreferences && !defaultScenarioId
  const areAllNavButtonsDisabled = hasAuthError || !isAuthenticated || !isAuthorizedWithPermission || !accessToken

  return (
    <AppBar position="static" sx={{ padding: 0, boxShadow: 'none' }}>
      <Toolbar>
        <Hidden mdDown>
          <Box sx={{ textAlign: 'left', width: '220px', minHeight: '94px' }}>
            {SHOULD_SHOW_DM_UPDATE_PAGE ? (
              <Link to="/dataManagerUpdate" style={{ cursor: 'default' }}>
                <img src={newLogo} width="100%" />
              </Link>
            ) : (
              <img src={newLogo} width="100%" />
            )}
          </Box>
        </Hidden>

        {isLoading ? (
          <LoadingSkeleton width="90px" dataTestId="overview-nav-button-loading" />
        ) : (
          <CircularButton
            variant="contained"
            isActive={isOverviewPageSelected}
            component={Link}
            to={APP_PAGES_MAP.OVERVIEW.path}
            disabled={isLoading || areAllNavButtonsDisabled || isFirstTimeUser}
          >
            Overview
          </CircularButton>
        )}

        {isLoading ? (
          <LoadingSkeleton width="152px" dataTestId="be-nav-button-loading" />
        ) : (
          <CircularButton
            variant="contained"
            isActive={isBenchmarkExplorerPageSelected}
            component={Link}
            to={APP_PAGES_MAP.BENCHMARK_EXPLORER.path}
            disabled={isLoading || areAllNavButtonsDisabled || isFirstTimeUser}
          >
            Benchmark Explorer
          </CircularButton>
        )}

        {isLoading ? (
          <LoadingSkeleton width="120px" dataTestId="ce-nav-button-loading" />
        ) : (
          <CircularButton
            variant="contained"
            isActive={isClinicExplorerPageSelected}
            component={Link}
            to={APP_PAGES_MAP.CLINIC_EXPLORER.path}
            disabled={isLoading || areAllNavButtonsDisabled || isFirstTimeUser}
          >
            Clinic Explorer
          </CircularButton>
        )}

        {isLoading ? (
          <LoadingSkeleton width="116px" dataTestId="dm-nav-button-loading" />
        ) : (
          <CircularButton
            variant="contained"
            isActive={isDataManagerPageSelected}
            component={Link}
            to={APP_PAGES_MAP.DATA_MANAGER.path}
            disabled={isLoading || areAllNavButtonsDisabled}
          >
            Data Manager
          </CircularButton>
        )}

        <Box sx={{ width: '230px', textAlign: 'right' }}>
          <ProfileComponent isLoading={isLoading} />
        </Box>
      </Toolbar>

      <Divider />
    </AppBar>
  )
}
