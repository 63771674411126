import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import LoadingSkeleton from '../../../../../../components/LoadingSkeleton'

const FONT_SIZE = '13px'

/**
 * Renders the statistics for a clinic.
 *
 * @TODO rewrite component as a table.  Component styling is brittle and any changes in font
 * size or width will impact styling across breakpoints
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - The array of clinic statistics data.
 * @param {Object} props.data[].name - The name of the statistic.
 * @param {Object} props.data[].value - The value of the statistic.
 * @param {boolean} props.isLoading - Indicates whether the data is currently being loaded.
 * @param {Array} props.fallbackData - The array of clinic statistics data to use when data is not available (name is used in array during load).
 * @returns {JSX.Element|null} The rendered component.
 */
export function ClinicStats({ data: rawData, isLoading, fallbackData }) {
  const data = Array.isArray(rawData) ? rawData : fallbackData

  return (
    <Box marginTop={2}>
      {data?.map((val, index) => {
        return (
          <Box key={index} sx={{ display: 'flex' }}>
            <Box sx={{ paddingBottom: 1, width: { xs: '30%', md: '60%', lg: '40%' } }}>
              <Typography sx={{ fontSize: FONT_SIZE, fontColor: '#EBEBEB' }}>{val?.name}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                height: FONT_SIZE,
                paddingX: '4px',
                width: { xs: '50%', md: '20%', lg: '40%' },
              }}
            >
              <Box component="div" sx={{ borderBottom: '1px dotted', fontSize: FONT_SIZE, width: '100%' }} />
            </Box>
            <Box sx={{ direction: 'flex', justifyContent: 'right', width: { xs: '20%', md: '20%', lg: '20%' } }}>
              {isLoading ? (
                <LoadingSkeleton
                  variant="text"
                  height="20px"
                  width="50%"
                  data-testid={`loading-clinic-stats-${index}`} // because we could be loading multiple lines of text, there can be more than 1 skeleton
                />
              ) : (
                <Typography sx={{ fontSize: FONT_SIZE, fontWeight: 700 }}>{val?.value?.toLocaleString()}</Typography>
              )}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

ClinicStats.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
  ),
  fallbackData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }).isRequired,
  ).isRequired,
}
