/* eslint-disable no-unused-vars */
import { Check, Delete, Create } from '@mui/icons-material'
import { TextField, IconButton, Typography } from '@mui/material'
import React from 'react'

export default function DynamicEditableTextField(props) {
  //Text field values
  const [textValue, setTextValue] = React.useState(null)
  const [editorActive, setEditorActive] = React.useState(false)
  const [valueEdited, setValueEdited] = React.useState(false)
  const [oldTextValue, setOldTextValue] = React.useState(null)

  // Editors

  const openEditor = e => {
    setOldTextValue(textValue)
    setEditorActive(true)
  }

  const handleEditTextValue = e => {
    setTextValue(e.target.value)
    setValueEdited(true)
  }

  const handleExitEditNoSave = () => {
    setValueEdited(false)
    setEditorActive(false)
    setTextValue(oldTextValue)
  }

  const handleSaveEditedValue = () => {
    setValueEdited(false)
    setEditorActive(false)

    // Pass back only on save
    props.setTextValue(textValue)
    props.setFieldEdited(true)
  }

  // Switcher
  const rednerEditNameControls = () => {
    switch (editorActive) {
      case true:
        return (
          <>
            <TextField
              id={props.id}
              sx={{ fontSize: '16px', fontWeight: 400 }}
              defaultValue={props.textValue}
              onChange={handleEditTextValue}
              variant="standard"
            ></TextField>
            <IconButton onClick={handleSaveEditedValue}>
              <Check></Check>
            </IconButton>
            <IconButton onClick={handleExitEditNoSave}>
              <Delete></Delete>
            </IconButton>
          </>
        )
      default:
        return (
          <>
            <TextField
              id={props.id}
              sx={{ fontSize: '16px', fontWeight: 400 }}
              defaultValue={props.textValue}
              onChange={handleEditTextValue}
              variant="standard"
            ></TextField>
            <IconButton onClick={handleExitEditNoSave}>
              <Delete></Delete>
            </IconButton>
          </>
        )
    }
  }

  return (
    <>
      {editorActive === true && rednerEditNameControls()}

      {editorActive === false && (
        <Typography sx={{ fontSize: '16px', fontWeight: 400 }} display="flex" alignItems="center" direction="column">
          {props.textValue}{' '}
          <IconButton onClick={openEditor}>
            <Create></Create>
          </IconButton>
        </Typography>
      )}
    </>
  )
}
