import React from 'react'
import PropTypes from 'prop-types'
import { Search } from '@mui/icons-material'
import { TextField } from '@mui/material'
import { escapeRegexProtectedChars } from '../../utils/escapeRegexProtectedChars'

/**
 * Renders a table search bar component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - The data array to be searched.
 * @param {string} props.field - The field in the data array to be searched.
 * @param {Function} props.returnData - The callback function to return the filtered data.
 * @param {string} props.label - The label for the search bar.
 * @returns {JSX.Element} The table search bar component.
 */
export default function TableSearchBar({ data = [], field, returnData, label }) {
  const searchArray = e => {
    const searchVal = e.target.value
    const escapedSearch = escapeRegexProtectedChars(searchVal.toLowerCase())
    const filteredData = data.filter(row => row?.[field]?.toLowerCase()?.match(escapedSearch))
    returnData(filteredData)
  }

  return (
    <TextField
      variant="outlined"
      label={label}
      onChange={searchArray}
      fullWidth={true}
      InputProps={{
        endAdornment: <Search data-testid="search-icon" />,
      }}
    />
  )
}

TableSearchBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  field: PropTypes.string.isRequired,
  returnData: PropTypes.func.isRequired,
  label: PropTypes.string,
}
