import React from 'react'
import { geoCentroid } from 'd3-geo'
import { ComposableMap, Geographies, Geography, Marker, Annotation } from 'react-simple-maps'
import { scaleQuantile } from 'd3-scale'

const offsets = {
  VT: [50, -8],
  NH: [34, 2],
  MA: [30, -1],
  RI: [28, 2],
  CT: [35, 10],
  NJ: [34, 1],
  DE: [33, 0],
  MD: [47, 10],
  DC: [49, 21],
}

/**
 * The following is used to prevent an error being thrown (and the UI crashing)
 * The error thrown is "TypeError: (0 , _d3Array.bisect) is not a function" that originates
 * from scaleQuantile.  Because the error appears to be related to the library, I have been unable to find
 * a solution or a workaround.
 *
 * node_modules/d3-scale/src/quantile.js:18:63
 *
 */
const getProtectedFill = ({ colorScale, cur }) => {
  try {
    return cur ? colorScale(cur.metric_rate) : '#BBB'
  } catch (e) {
    console.error(e)
    return '#3f51b5'
  }
}

const StateMap = ({ data, selectedValueDisplay, geoMapStatesData }) => {
  const domainMap = data.map(d => d.metric_rate)
  const colorScale = scaleQuantile()
    .domain(domainMap)
    .range([
      '#cfebff',
      '#b7dbf8',
      '#a0caf1',
      '#8bbaeb',
      '#78a9e4',
      '#6798dc',
      '#5887d4',
      '#4c75cb',
      '#4463c0',
      '#3f51b5',
    ])

  return (
    <ComposableMap projection="geoAlbersUsa">
      {data && colorScale ? (
        <Geographies geography={geoMapStatesData}>
          {({ geographies }) => (
            <>
              {geographies.map(geo => {
                const cur = data.find(s => s.clinic_state === geo.properties.name)

                return (
                  <Geography
                    key={geo.rsmKey}
                    stroke="#FFF"
                    geography={geo}
                    fill={getProtectedFill({ colorScale, cur })}
                  />
                )
              })}
              {geographies.map(geo => {
                const centroid = geoCentroid(geo)
                const cur = data.find(s => s.clinic_state === geo.properties.name)
                return (
                  <g key={geo.rsmKey + '-name'}>
                    {cur &&
                      centroid[0] > -160 &&
                      centroid[0] < -67 &&
                      (Object.keys(offsets).indexOf(cur.clinic_state_abbr) === -1 ? (
                        <Marker coordinates={centroid}>
                          {selectedValueDisplay === 'yoy' ? (
                            <text y="2" fontSize={12} textAnchor="middle">
                              {cur.clinic_state_abbr} : {Math.round(cur.metric_rate * 100) + '%'}
                            </text>
                          ) : (
                            <text y="2" fontSize={12} textAnchor="middle">
                              {cur.clinic_state_abbr} : {cur.metric_rate}
                            </text>
                          )}
                        </Marker>
                      ) : (
                        <Annotation
                          subject={centroid}
                          dx={offsets[cur.clinic_state_abbr][0]}
                          dy={offsets[cur.clinic_state_abbr][1]}
                        >
                          {selectedValueDisplay === 'yoy' ? (
                            <text x={2} fontSize={12} alignmentBaseline="middle">
                              {cur.clinic_state_abbr} : {Math.round(cur.metric_rate * 100) + '%'}
                            </text>
                          ) : (
                            <text x={2} fontSize={12} alignmentBaseline="middle">
                              {cur.clinic_state_abbr} : {cur.metric_rate}
                            </text>
                          )}
                        </Annotation>
                      ))}
                  </g>
                )
              })}
            </>
          )}
        </Geographies>
      ) : null}
    </ComposableMap>
  )
}

export default StateMap
