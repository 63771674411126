import React from 'react'
import PropTypes from 'prop-types'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useVirtualizer } from '@tanstack/react-virtual'
import { Card } from '@mui/material'
import LoadingSkeleton from '../../LoadingSkeleton'
import { DEFAULT_ROW_HEIGHT, ELLIPSIS_STYLES } from '../Table.constants'
import SortableTableHeaderCell from '../tableComponents/SortableTableHeaderCell'

export function StaticScrollTable({
  memoizedColumns,
  memoizedData,
  isLoading,
  tableHeight,
  noRecordsOverlay,
  rowHeight = DEFAULT_ROW_HEIGHT,
  initialSortState = [],
  dataTestId,
  enableSortingRemoval = true, // the ability to remove a sort from a column (see https://tanstack.com/table/latest/docs/guide/sorting#sorting-removal)
}) {
  const [sorting, setSorting] = React.useState(initialSortState)
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef(null)

  const table = useReactTable({
    data: memoizedData,
    state: {
      sorting,
    },
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: false,
    enableSortingRemoval,
  })

  // scroll to top of table when sorting changes
  const handleSortingChange = updater => {
    setSorting(updater)
    if (table?.getRowModel()?.rows?.length) {
      rowVirtualizer.scrollToIndex?.(0)
    }
  }

  //since this table option is derived from table row model state, we're using the table.setOptions utility
  table.setOptions(prev => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }))

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => DEFAULT_ROW_HEIGHT, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && global.navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5, // The number of items to render above and below the visible area.
  })

  return (
    <TableContainer
      ref={tableContainerRef}
      component={Card}
      sx={{
        height: tableHeight, //should be a fixed height
        overflow: 'auto', //our scrollable table container
        position: 'relative', //needed for sticky header
        width: '100%',
      }}
      data-testid={dataTestId || 'static-scroll-table'}
    >
      {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
      <Table
        component="div"
        aria-colcount={memoizedColumns.length}
        aria-rowcount={memoizedData.length + 1} //add 1 for header row
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <TableHead
          component="div"
          role="presentation"
          sx={{
            background: '#FFF',
            position: 'sticky',
            top: 0,
            width: '100%',
            zIndex: 2,
          }}
        >
          {table.getHeaderGroups().map((headerGroup, index) => (
            <TableRow
              key={headerGroup.id}
              aria-rowindex={index + 1}
              component="div"
              role="rowgroup"
              sx={{ display: 'flex', alignItems: 'center', width: '100%', height: rowHeight }}
            >
              {headerGroup.headers.map((header, index) => (
                <SortableTableHeaderCell key={header.id} header={header} colIndex={index + 1} rowHeight={rowHeight} />
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody
          component="div"
          sx={{
            display: 'flex',
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: 'relative', //needed for absolute positioning of rows
          }}
        >
          {isLoading && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: tableHeight - rowHeight, //subtract header height
                justifyContent: 'center',
                position: 'absolute',
                transform: `translateY(0px)`, //this should always be a `style` as it changes on scroll
                width: '100%',
              }}
              component="div"
            >
              <LoadingSkeleton
                variant="rectangular"
                width="100%"
                height="100%"
                data-testid="default-table-loading-overlay"
              />
            </Box>
          )}

          {!isLoading && !memoizedData?.length && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                transform: `translateY(0px)`, //this should always be a `style` as it changes on scroll
                width: '100%',
                height: tableHeight - rowHeight,
              }}
              component="div"
            >
              {noRecordsOverlay ? noRecordsOverlay : <div>No records found.</div>}
            </Box>
          )}

          {rowVirtualizer.getVirtualItems().map(virtualRow => {
            const row = rows[virtualRow.index]

            return (
              <TableRow
                key={row.id}
                ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                data-index={virtualRow.index} //needed for dynamic row height measurement
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  width: '100%',
                  maxWidth: '100%',
                  height: rowHeight,
                  borderBottom: '1px solid #E0E0E0',
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                component="div"
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableCell
                      key={cell.id}
                      component="div"
                      role="tablecell"
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flex: cell.column.columnDef.flex || 1,
                        maxHeight: rowHeight,
                        maxWidth: '100%',
                        minHeight: rowHeight,
                        paddingX: 1,
                        width: cell.column.getSize(),
                        ...ELLIPSIS_STYLES,
                      }}
                    >
                      <Box sx={ELLIPSIS_STYLES}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

StaticScrollTable.propTypes = {
  memoizedColumns: PropTypes.arrayOf(
    PropTypes.shape({
      // custom props we added
      flex: PropTypes.number.isRequired, // To style width of column -> This is the shorthand for flex-grow, flex-shrink and flex-basis combined (see https://css-tricks.com/snippets/css/a-guide-to-flexbox/)
      // from react-table
      accessorFn: PropTypes.func, // How the column data is accessed from the memoized data
      accessorKey: PropTypes.string, // An alternative to accessorFn
      cell: PropTypes.func.isRequired, // How the cell value is defined
      enableSorting: PropTypes.bool.isRequired, // whether or not to enable sorting for a column
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired, // The text to display in the header cell
      id: PropTypes.string.isRequired,
      sortingFn: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // see // https://tanstack.com/table/latest/docs/guide/sorting#custom-sorting-functions
      sortDescFirst: PropTypes.bool, // The first column is sorted desc first and this is to correct that
    }),
  ).isRequired,
  memoizedData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // An id property is always required
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  tableHeight: PropTypes.number.isRequired,
  noRecordsOverlay: PropTypes.node,
  rowHeight: PropTypes.number,
  initialSortState: PropTypes.array,
  dataTestId: PropTypes.string,
}
