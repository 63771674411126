import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import isEqual from 'lodash.isequal'
import { useAuthContext } from '../../context/AuthContext/useAuthContext'
import { useToastContext } from '../../context/ToastContext/useToastContext'
import { useSidebarContext } from '../../context/SidebarContext/useSidebarContext'
import { useFilterContext } from '../../context/FilterContext/useFilterContext'
import { usePrevious } from '../../hooks/usePrevious'
import { buildSelectedUOM } from '../../context/FilterContext/filterContextHelpers'
import { getBenchmarkTrend } from '../../services/apis'
import LineGraphCard from '../lineGraphCard/lineGraphCard'
import ValueDisplaySelector from '../valueDisplaySelector/valueDisplaySelector'
import { TOAST_IDS } from '../../context/ToastContext/constants'
import { VALUE_DISPLAY_ID } from '../valueDisplaySelector/valueDisplay.constants'

const HEIGHT = '500px'
const NO_DATA_LABEL = { label: 'No Data' }

const BenchmarkTrendGraph = ({
  selectedMetric,
  selectedValueDisplay,
  handleSetSelectedValueDisplay,
  isLoadingTrendSpinner,
  setIsLoadingTrendSpinner,
}) => {
  const { accessToken } = useAuthContext()
  const { addToast } = useToastContext()
  const { selectedScenario, isLoadingFetchScenarios, unitOfMeasurement, unitOfTime } = useFilterContext()
  const { scenarioParamsWithMetadata, isLoadingFetchScenarioParams } = useSidebarContext()

  const [trendData, setTrendData] = React.useState(null)

  const selectedUOM = React.useMemo(
    () => buildSelectedUOM({ unitOfMeasurement, unitOfTime }),
    [unitOfMeasurement, unitOfTime],
  )

  const fetchBenchmarkTrend = React.useCallback(async () => {
    try {
      setIsLoadingTrendSpinner(true)
      if (
        unitOfMeasurement &&
        unitOfTime &&
        selectedValueDisplay &&
        accessToken &&
        selectedScenario?.scenarioid &&
        selectedMetric.value
      ) {
        const getBenchmarkTrendParams = {
          units_payload: {
            rate: unitOfMeasurement,
            time_granularity: unitOfTime,
          },
          scenarioid: selectedScenario?.scenarioid,
          metric: selectedMetric.value,
          value_display: selectedValueDisplay,
        }

        const res = await getBenchmarkTrend(getBenchmarkTrendParams, accessToken)
        setTrendData(res?.data?.benchmark_trend)
      }
    } catch (error) {
      setTrendData({
        labels: [],
        datasets: [NO_DATA_LABEL],
      })
      addToast({
        toastId: TOAST_IDS.getBenchmarkTrendErrForOverview,
        variant: 'error',
        msg: `Error loading benchmark trend - ${error?.message || 'unknown error'}`,
      })
    } finally {
      setIsLoadingTrendSpinner(false)
    }
  }, [
    unitOfMeasurement,
    unitOfTime,
    selectedScenario?.scenarioid,
    selectedMetric.value,
    selectedValueDisplay,
    accessToken,
    addToast,
    setIsLoadingTrendSpinner,
  ])

  React.useEffect(() => {
    fetchBenchmarkTrend()
  }, [
    fetchBenchmarkTrend, // run useEffect when any values fetchBenchmarkTrend dependency array change
  ])

  const previousSelectedScenarioParams = usePrevious(scenarioParamsWithMetadata)
  React.useEffect(() => {
    // only run use effect if there were previously scenarioParamsWithMetadata defined && now the previous render values does not equal current value
    if (!!previousSelectedScenarioParams && !isEqual(scenarioParamsWithMetadata, previousSelectedScenarioParams)) {
      fetchBenchmarkTrend()
    }
  }, [previousSelectedScenarioParams, fetchBenchmarkTrend, scenarioParamsWithMetadata])

  const isLoading = isLoadingFetchScenarios || isLoadingTrendSpinner || isLoadingFetchScenarioParams

  return (
    <LineGraphCard
      data={trendData}
      height={HEIGHT}
      isLoading={isLoading}
      selectedBenchmark={selectedMetric.name}
      selectedValueDisplay={selectedValueDisplay}
    >
      <Grid container sx={{ alignItems: 'center', paddingX: 3 }}>
        <Grid item xs={6} sx={{ paddingBottom: 0, display: 'flex', justifyContent: 'flex-start' }}>
          <Box>
            <Typography>Trend over Time</Typography>
            <Typography sx={{ color: '#9FACB8', fontWeight: 400, fontSize: 14 }}>{selectedUOM}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ValueDisplaySelector
            handleValueDisplay={handleSetSelectedValueDisplay}
            selectedValueDisplay={selectedValueDisplay}
            disabled={isLoading}
            visibleButtons={[VALUE_DISPLAY_ID.actuals, VALUE_DISPLAY_ID.index]}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography color="#9FACB8" sx={{ fontWeight: 400, fontSize: 14 }}>
                Value Display
              </Typography>
            </Box>
          </ValueDisplaySelector>
        </Grid>
      </Grid>
    </LineGraphCard>
  )
}

export default BenchmarkTrendGraph
