import React from 'react'
import PropTypes from 'prop-types'
import Delete from '@mui/icons-material/Delete'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import IconButton from '@mui/material/IconButton'

import { deleteScenario } from '../../../../../../services/apis'
import { useAuthContext } from '../../../../../../context/AuthContext/useAuthContext'
import { useFilterContext } from '../../../../../../context/FilterContext/useFilterContext'
import { TOAST_IDS } from '../../../../../../context/ToastContext/constants'
import { useToastContext } from '../../../../../../context/ToastContext/useToastContext'

/**
 * Component for deleting a Scenario.
 * @param {Object} props - The component props.
 * @param {Object} props.row - The row data for the Scenario.
 * @param {Object} props.row.create_date - The creation date of the Scenario.
 * @param {Object} props.row.created_by_userid - The user ID of the creator.
 * @param {Object} props.row.id - The ID of the Scenario.
 * @param {Object} props.row.list_of_clinics - The list of clinics associated with the Scenario.
 * @param {Object} props.row.Scenario_description - The description of the Scenario.
 * @param {Object} props.row.Scenario_title - The title of the Scenario.
 * @param {Object} props.row.Scenarioid - The ID of the Scenario.
 * @returns {JSX.Element} The delete Scenario component.
 */
export const DeleteScenario = ({ row, iconButtonStyles = {} }) => {
  const { accessToken } = useAuthContext()
  const { refetchScenarios } = useFilterContext()
  const { addToast } = useToastContext()
  const [isLoading, setIsLoading] = React.useState(false)

  /**
   * Handles the click event when deleting a row.
   * @param {Object} row - The row data for the Scenario.
   * @returns {Promise<void>} A promise that resolves when the Scenario is deleted.
   */
  const handleDeleteRowClick = async row => {
    try {
      setIsLoading(true)
      const deletedScenario = await deleteScenario({ accessToken: accessToken, scenarioid: row.scenarioid })
      addToast({
        toastId: TOAST_IDS.deleteScenarioSuccess,
        variant: 'success',
        msg: `The scenario "${deletedScenario.scenario_title}" has been successfully deleted.`,
      })

      // refetch Scenarios on success to update table rows
      await refetchScenarios({ accessToken, errorToastId: TOAST_IDS.refetchScenariosOnDeleteScenarioError })
    } catch (error) {
      console.error(error)
      addToast({
        toastId: TOAST_IDS.deleteScenarioError,
        variant: 'error',
        msg: error.message,
        truncateLength: 300, // increase truncate length so that more scenario names are visible with 422 error
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <DeleteTwoToneIcon data-testid={`delete-scenario-loading-${row.scenarioid}`} sx={{ color: '#707070' }} />
  }

  return (
    <>
      {row.default_scenario_flag === true ? null : (
        <IconButton
          data-testid={`delete-scenario-${row.scenarioid}`}
          onClick={() => handleDeleteRowClick(row)}
          sx={{ color: '#707070', cursor: 'pointer', padding: 0, ...iconButtonStyles }}
        >
          <Delete />
        </IconButton>
      )}
    </>
  )
}

DeleteScenario.propTypes = {
  row: PropTypes.shape({
    create_date: PropTypes.string.isRequired,
    created_by_userid: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    scenario_description: PropTypes.string,
    scenario_title: PropTypes.string.isRequired,
    scenarioid: PropTypes.string.isRequired,
  }).isRequired,
}
