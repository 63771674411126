export const VALUE_DISPLAY_ID = {
  actuals: 'actuals',
  index: 'index',
  yoy: 'yoy',
}

export const VALUE_DISPLAY_BUTTON_OPTIONS = [
  {
    displayText: 'Actuals',
    id: VALUE_DISPLAY_ID.actuals,
  },
  {
    displayText: 'Index',
    id: VALUE_DISPLAY_ID.index,
  },
  {
    displayText: 'YoY',
    id: VALUE_DISPLAY_ID.yoy,
  },
]
