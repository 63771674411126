import React from 'react'
import { Card, CardContent, Typography, Grid, Box, Skeleton } from '@mui/material'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import DimensionAnalysisTable from '../dimensionAnalysisTable/dimensionAnalysisTable'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default function DimensionAnalysisCard({ data, dimensionPrettyName, selectedMetricPrettyName, isLoading }) {
  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: '% of Observations',
        },
        ticks: {
          callback: label => ` ${label * 100}%`,
        },
        grid: {
          display: false,
        },
      },
      x: {
        title: {
          display: true,
          text: dimensionPrettyName,
        },
        grid: {
          display: false,
        },
      },
    },
    barPercentage: 0.4,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },

      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.formattedValue * 100
            label = label.toString()
            label += '%'
            return label
          },
        },
      },
    },
  }

  const _data = {
    labels: data?.clinic_data_graph?.labels || [],
    datasets: data?.clinic_data_graph?.datasets || [],
  }

  const dimensionDetailTable = React.useMemo(() => {
    return {
      header: [
        'Dimension',
        '% of Portfolio Observations',
        '% of Benchmark Observation',
        'Benchmark Average',
        'Portfolio Average',
      ],
      records: data?.clinic_data_table || [],
    }
  }, [data?.clinic_data_table])

  return (
    <Card sx={{ padding: 0 }}>
      <CardContent>
        <Grid container sx={{ paddingX: 2, paddingTop: { xs: 5, lg: 10 }, minHeight: '300px' }}>
          <Grid item xs={12} lg={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: '19px' }}>
                  {selectedMetricPrettyName} by {dimensionPrettyName}
                </Typography>
              </Box>
              <Box sx={{ maxWidth: '100%', overflowX: 'auto', height: '100%' }}>
                {isLoading ? (
                  <Box sx={{ paddingX: 2, height: '100%' }}>
                    <Skeleton animation="wave" variant="rectangular" height={'100%'} />
                  </Box>
                ) : (
                  <DimensionAnalysisTable data={dimensionDetailTable} dimension={dimensionPrettyName} />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ paddingTop: { xs: 5, lg: 0 } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: '19px' }}>{dimensionPrettyName} vs % of Observations</Typography>
              </Box>
              {isLoading ? (
                <Box sx={{ paddingX: 2 }}>
                  <Skeleton animation="wave" variant="rectangular" height={390} />
                </Box>
              ) : (
                <Bar options={options} data={_data} height="80%" width="100%" />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
