import { Box, Card, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'

/**
 * Renders a generic component for displaying an unknown error message.
 * @returns {JSX.Element} The UnknownError component.
 */
export const UnknownError = () => {
  const theme = useTheme()
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ margin: 2 }}>
        <Card sx={{ backgroundColor: theme.palette.background.paper, padding: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '90vh', width: '100%' }}>
            <Typography component="p" align="center" color="text.primary" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              An unknown error occured. Please refresh the page and try again.
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  )
}
