import uniq from 'lodash/uniq'
/**
 * parseOutFormValues takes the formik form values and parses them into a format
 * that is compatible with the expected payload for update scenario parameters
 */

/**
 * Parses out values for editing a scenario.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.formikValues - The formik values object.
 * @param {string} options.formikValues.scenario_title - The scenario name.
 * @param {string} options.formikValues.visit_type - The visit type.
 * @param {Array} options.formikValues.therapist_type - The therapist type.
 * @param {string} options.formikValues.therapy_type - The therapy type.
 * @param {Array} options.formikValues.clinic_size - The clinic size range. This has a length of 2
 * @param {string} options.formikValues.portfolioid - The portfolio ID.
 * @param {Array} options.formikValues.years - An array of years
 * @param {string} options.scenarioId - The scenario ID.
 * @returns {Object} - The parsed values object.
 * @property {string} scenarioid - The scenario ID.
 * @property {string} scenario_title - The scenario name.
 * @property {Array} visit_type - The visit type.
 * @property {Array} therapist_type - The therapist type.
 * @property {Array} therapy_type - The therapy type.
 * @property {Object} clinic_size - The clinic size range.
 * @property {string} clinic_size.min - The minimum clinic size.
 * @property {string} clinic_size.max - The maximum clinic size.
 * @property {string} portfolioid - The portfolio ID.
 * @property {Array} years - The years.
 */
export const parseOutValuesForEditScenario = ({ formikValues, scenarioId }) => {
  return {
    scenarioid: scenarioId,
    scenario_title: formikValues.scenario_title,
    visit_type: [formikValues.visit_type],
    therapist_type: uniq(formikValues.therapist_type),
    therapy_type: [formikValues.therapy_type],
    clinic_size: { min: formikValues.clinic_size[0], max: formikValues.clinic_size[1] },
    portfolioid: formikValues.portfolioid,
    years: uniq(formikValues.years),
  }
}
