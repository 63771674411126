import React from 'react'
import { Grid, Typography } from '@mui/material'

import { getBenchmarkDistribution } from '../../../../services/apis'
import PerformanceDistributionCard from '../../../../components/performanceDistributionCard/performanceDistributionCard'

import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useToastContext } from '../../../../context/ToastContext/useToastContext'
import { buildSelectedUOM } from '../../../../context/FilterContext/filterContextHelpers'
import { useSidebarContext } from '../../../../context/SidebarContext/useSidebarContext'
import { usePrevious } from '../../../../hooks/usePrevious'
import isEqual from 'lodash.isequal'

const PerformanceDistribution = ({
  selectedMetric,
  selectedValueDisplay,
  graphHeight,
  cardHeight,
  isLoadingSpinnerDistribution,
  setIsLoadingSpinnerDistribution,
}) => {
  const { accessToken } = useAuthContext()
  const { addToast } = useToastContext()
  const { scenarioParamsWithMetadata, isLoadingFetchScenarioParams } = useSidebarContext()
  const { selectedScenario, isLoadingFetchScenarios, unitOfMeasurement, unitOfTime } = useFilterContext()

  const [distributionData, setDistributionData] = React.useState(null)

  const unitOfMeasure = React.useMemo(
    () => buildSelectedUOM({ unitOfMeasurement, unitOfTime }),
    [unitOfMeasurement, unitOfTime],
  )

  const fetchBenchmarkDistribution = React.useCallback(async () => {
    try {
      if (accessToken && unitOfMeasurement && unitOfTime && selectedScenario?.scenarioid && selectedMetric.value) {
        setIsLoadingSpinnerDistribution(true)
        const getBenchmarkDistributionParams = {
          units_payload: {
            rate: unitOfMeasurement,
            time_granularity: unitOfTime,
          },
          scenarioid: selectedScenario?.scenarioid,
          metric: selectedMetric.value,
          value_display: selectedValueDisplay,
        }

        const res = await getBenchmarkDistribution(accessToken, getBenchmarkDistributionParams)
        setDistributionData(res?.data)
      }
    } catch (error) {
      console.error(error)
      setDistributionData(null)
      addToast({
        toastId: TOAST_IDS.getBenchmarkDistErrForOverview,
        variant: 'error',
        msg: `Error loading benchmark distribution - ${error?.message || 'unknown error'}`,
      })
    } finally {
      setIsLoadingSpinnerDistribution(false)
    }
  }, [
    unitOfMeasurement,
    unitOfTime,
    selectedScenario?.scenarioid,
    selectedMetric.value,
    selectedValueDisplay,
    accessToken,
    addToast,
    setIsLoadingSpinnerDistribution,
  ])

  React.useEffect(() => {
    fetchBenchmarkDistribution()
  }, [fetchBenchmarkDistribution])

  const previousSelectedScenarioParams = usePrevious(scenarioParamsWithMetadata)
  React.useEffect(() => {
    // only run use effect if there were previously scenarioParamsWithMetadata defined && now the previous render values does not equal current value
    if (!!previousSelectedScenarioParams && !isEqual(scenarioParamsWithMetadata, previousSelectedScenarioParams)) {
      fetchBenchmarkDistribution()
    }
  }, [previousSelectedScenarioParams, fetchBenchmarkDistribution, scenarioParamsWithMetadata])

  const isLoading = isLoadingFetchScenarios || isLoadingSpinnerDistribution || isLoadingFetchScenarioParams

  return (
    <PerformanceDistributionCard
      cardHeight={cardHeight}
      graphHeight={graphHeight}
      selectedBenchmark={selectedMetric.name}
      data={distributionData}
      isLoading={isLoading}
    >
      <Typography>
        Clinic Performance Distribution for <b>{selectedMetric.name}</b>
      </Typography>
      <Typography color="#9FACB8" sx={{ fontWeight: 400, fontSize: 14 }}>
        {unitOfMeasure}
      </Typography>
      <Grid container direction="row" display="flex" sx={{ paddingTop: 2, alignItems: 'center' }}>
        <Grid item xs={3}></Grid>

        <Grid item xs={1}>
          <Typography align="center" sx={{ fontSize: 14 }}>
            Lower 25%
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item container display="flex" justifyContent="center" xs={1}>
          <Typography align="center" sx={{ fontSize: 20 }}>
            Average
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={1}>
          <Typography align="center" sx={{ fontSize: 14 }}>
            Upper 25%
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}></Grid>

        <Grid item xs={1}>
          <Typography align="center" sx={{ fontSize: 14 }}>
            {distributionData?.summary_metrics?.lower_quantile}
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={1}>
          <Typography align="center" sx={{ fontSize: 20 }}>
            {distributionData?.summary_metrics?.portfolio_mean}
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>

        <Grid item xs={1}>
          <Typography align="center" sx={{ fontSize: 14 }}>
            {distributionData?.summary_metrics?.upper_quantile}
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </PerformanceDistributionCard>
  )
}

export default PerformanceDistribution
