import React from 'react'
import styled from '@emotion/styled'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { globalTheme } from '../../themes/globalTheme'

const StyledContainer = styled.main`
  background: ${({ theme }) => theme.palette.background.default};
`

/**
 * Wraps all page content with default styles.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be rendered inside the wrapper.
 * @returns {ReactNode} The wrapped page content with loading styles.
 */
export const AppStyleWrapper = ({ children }) => (
  <ThemeProvider theme={globalTheme}>
    <StyledContainer data-testid="main-element">
      <CssBaseline />
      {children}
    </StyledContainer>
  </ThemeProvider>
)
