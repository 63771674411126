export const verifyIsChecked = values => id => {
  if (!Array.isArray(values) || values.length === 0) return false
  return values.includes(id)
}

const buildCheckBoxOptions = ({ subsetOfMenuOptions, name }) => {
  if (!Array.isArray(subsetOfMenuOptions) || subsetOfMenuOptions.length === 0) return []
  return subsetOfMenuOptions.map(item => {
    const _value = item?.menu === 'year' ? parseInt(item.option_id, 10) : item.option_id
    return {
      id: item.option_id,
      label: item.option_value,
      checkboxAttributes: { type: 'checkbox', name: name, value: _value, size: 'small' },
    }
  })
}

export const getCheckboxOptions = ({ menuOptions, menuKey, name }) => {
  if (!Array.isArray(menuOptions) || menuOptions.length === 0) return []
  const subsetOfMenuOptions = menuOptions?.filter(option => option.menu === menuKey)
  return buildCheckBoxOptions({ subsetOfMenuOptions, name })
}

export const composeCheckboxChange = setState => e => {
  if (e.target.checked) {
    setState(prevCheckedValues => [...prevCheckedValues, e.target.value])
  } else {
    setState(prevCheckedValues => prevCheckedValues.filter(val => val !== e.target.value))
  }
}
