import { Box, Slider, Typography } from '@mui/material'
import React from 'react'

export default function ValueSlider(props) {
  const [lowerVal, setLowerVal] = React.useState(props.lowerVal)
  const [upperVal, setUpperVal] = React.useState(props.upperVal)

  const handleSliderChange = e => {
    const change_range = e.target.value
    props.setLowerVal(change_range[0])
    props.setUpperVal(change_range[1])
    setLowerVal(change_range[0])
    setUpperVal(change_range[1])
  }

  return (
    <Box sx={{ paddingLeft: props.paddingLeft, paddingTop: props.paddingTop, width: '80%' }}>
      <Typography sx={{ fontSize: '16px', fontColor: '#656A6C' }}>{props.sliderTitle}</Typography>
      <Typography sx={{ fontSize: '8pt', fontColor: '#656A6C' }}>
        Providers : {lowerVal} - {upperVal}
      </Typography>
      <Box sx={props.sliderContainerStyles}>
        <Slider
          marks
          name={props.name}
          getAriaLabel={() => 'Small'}
          size="small"
          step={props.step}
          max={75}
          min={1}
          value={[lowerVal, upperVal]}
          valueLabelDisplay="auto"
          onChange={handleSliderChange}
        />
      </Box>
    </Box>
  )
}
