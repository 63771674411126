import React from 'react'
import ArrowUpward from '@mui/icons-material/ArrowUpward'

const ARROW_SIZE = {
  small: '18px',
  medium: '24px',
}

const ARROW_DIRECTION = {
  up: 'up',
  down: 'down',
}

export const ArrowIcon = ({ direction, size, ...styles }) => {
  if (typeof direction !== 'string') {
    throw new Error('ArrowIcon: direction prop must be a string with a value of "up" or "down"')
  }
  return (
    <ArrowUpward
      sx={{
        width: ARROW_SIZE[size] || ARROW_SIZE.small,
        height: ARROW_SIZE[size] || ARROW_SIZE.small,
        color: 'rgba(0, 0, 0, 0.54)',
        transform: ARROW_DIRECTION[direction] === ARROW_DIRECTION.up ? 'rotate(0deg)' : 'rotate(180deg)',
        ...styles,
      }}
    />
  )
}
