import React from 'react'
import Proptypes from 'prop-types'
import { Typography } from '@mui/material'

/**
 * Error message component.
 *
 * @param {Object} props - The component props (supports all Typography props).
 * @param {React.ReactNode} props.children - The content of the error message.
 * @param {Object} props.sx - Additional styles for the error message.
 * @returns {React.ReactNode} The rendered error message component.
 */
export const ErrorMessage = ({ children, sx = {}, ...rest }) => (
  <Typography sx={{ fontSize: '12px', color: 'rgb(244, 67, 54)', ...sx }} {...rest}>
    {children}
  </Typography>
)

ErrorMessage.propTypes = {
  children: Proptypes.node.isRequired,
  sx: Proptypes.object,
}
