/**
 * ApiError is a custom error that can be in the api file
 * if we want to customize how we handle the error in the UI.
 * @param {string} message - The error message to be displayed in the UI.
 * @param {object} options - The options object.
 * @param {number} options.statusCode - The status code from the response
 * @param {string} options.errorTarget - An arbitrary string that is used to identify what caused the error. This could be a form field name or whatever was provided in the payload to cause the error.
 * @param {object} options.errorMetadata - An arbitrary object that can contain any additional metadata that is needed.
 *
 * @returns {Error} The ApiError object.
 */
export class ApiError extends Error {
  constructor(message, { statusCode, errorTarget, errorMetadata } = {}) {
    super(message)
    this.name = 'ApiError'
    this.statusCode = statusCode
    this.errorTarget = errorTarget
    this.errorMetadata = errorMetadata
  }
}
