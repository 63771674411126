import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Formik } from 'formik'
import { Save } from '@mui/icons-material'
import BaseModalContent from '../../../../../components/modals/BaseModalContent'
import StyledFormDialog from '../../../../../components/modals/FormModal/StyledFormDialog'
import { TOAST_IDS } from '../../../../../context/ToastContext/constants'
import { useToastContext } from '../../../../../context/ToastContext/useToastContext'
import { useAuthContext } from '../../../../../context/AuthContext/useAuthContext'
import { ApiError } from '../../../../../utils/ApiError'
import ErrorMessage from '../../../../../components/ErrorMessage'
import { useSidebarContext } from '../../../../../context/SidebarContext/useSidebarContext'
import EditPortfolioForm, {
  initialTouchedForEditPortfolioForm,
  initialStatusForEditPortfolioForm,
  editPortfolioValidationSchema,
} from '../../../../../forms/EditPortfolioForm'
import { updatePortfolio } from '../../../../../services/apis'
import { useFilterContext } from '../../../../../context/FilterContext/useFilterContext/useFilterContext'

/**
 * We use a no-op function to handle the onClose event for the modal
 * to require the member to either click the "X" button or to click the "Cancel" button.
 * With this function, users will not be able to click "ESC" key to exit or click outside the modal to exit.
 *
 */
const blockExitFromModal = () => undefined

/**
 * Renders the Edit Portfolio Modal & Form.
 * @param {Object} props The component props.
 * @param {boolean} props.isEditDialogOpen Whether the modal is open.
 * @param {Function} props.handleCloseModal The function to close the modal.
 * @returns {React.ReactNode} The Edit Scenario component.
 */
export function EditPortfolio({ isEditDialogOpen, setIsEditDialogOpen, handleCloseModal, selectedPortfolio }) {
  const { isLoadingFetchPortfolios, refetchPortfolios } = useSidebarContext()
  const { refetchScenarios, selectedScenario } = useFilterContext()

  const initialPortfolioFormValues = selectedPortfolio
    ? Object.keys(selectedPortfolio).reduce((acc, key) => {
        if (['portfolio_title', 'list_of_clinics'].includes(key)) {
          return { ...acc, [key]: selectedPortfolio[key] }
        }
        return acc
      }, {})
    : undefined

  const { addToast } = useToastContext()
  const { accessToken } = useAuthContext()

  const [formLevelError, setFormLevelError] = React.useState(undefined)

  const handleSubmit = async (formikValues, formikActions) => {
    setFormLevelError(undefined)
    const { setFieldError } = formikActions
    try {
      await updatePortfolio({
        accessToken,
        data: {
          portfolio_title: formikValues.portfolio_title,
        },
        id: selectedPortfolio.portfolioid,
      })

      setIsEditDialogOpen(false)
      addToast({
        toastId: TOAST_IDS.editPortfolioSuccess,
        variant: 'success',
        msg: `Portfolio "${formikValues.portfolio_title}" was updated successfully.`,
      })

      await Promise.all([
        refetchPortfolios({ accessToken, errorToastId: TOAST_IDS.getPortfoliosErrorForPortfolioCard }),
        refetchScenarios({
          accessToken,
          errorToastId: TOAST_IDS.getScenariosErrorForPortfolioCard,
          nextSelectedScenarioId: selectedScenario.scenarioid,
        }), // required to update the scenario table which lists the portfolio name
      ])
    } catch (error) {
      if (error instanceof ApiError) {
        // check for field level errors
        if (['portfolio_title'].includes(error?.errorTarget)) {
          return setFieldError(error.errorTarget, error?.message) // sets error in appropriate form field
        }
      }

      setFormLevelError(error.message) // Note that this covers ApiError & Error because both have a message property
    }
  }

  return (
    <>
      {/* 
          Note that StyledFormDialog does not render unless isEditDialogOpen is true.
          Then, StyledFormDialog will render as a React portal. 
      */}
      <StyledFormDialog onClose={blockExitFromModal} open={isEditDialogOpen} data-testid="edit-scenario-modal">
        {isEditDialogOpen && (
          <Formik
            initialValues={initialPortfolioFormValues}
            initialTouched={initialTouchedForEditPortfolioForm}
            initialStatus={initialStatusForEditPortfolioForm}
            onSubmit={handleSubmit}
            validationSchema={editPortfolioValidationSchema}
            enableReinitialize={true} // allows the form to update initialValues when the scenarioParamsWithMetadata changes
          >
            {formikProps => {
              return (
                <BaseModalContent
                  handleClickCloseButton={handleCloseModal}
                  hasContentDividers={true}
                  modalTitle="Edit Portfolio"
                  closeIconBtnAttributes={{
                    'data-testid': 'edit-portfolio-close-button',
                    disabled: formikProps.isSubmitting,
                  }}
                  renderModalFooter={() => (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        paddingX: 1,
                        marginY: 1,
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      {formLevelError && (
                        <ErrorMessage sx={{ fontSize: { xs: '12px', sm: '14px' }, paddingRight: 1 }}>
                          {formLevelError}
                        </ErrorMessage>
                      )}
                      <Box sx={{ minWidth: '240px' }}>
                        <Button
                          variant="outlined"
                          onClick={handleCloseModal}
                          sx={{ marginRight: 1 }}
                          color="error"
                          disabled={formikProps.isSubmitting}
                          data-testid="edit-portfolio-cancel-button"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="outlined"
                          startIcon={<Save />}
                          onClick={formikProps.handleSubmit}
                          disabled={formikProps.isSubmitting || !initialPortfolioFormValues}
                          data-testid="edit-portfolio-save-button"
                        >
                          Update Portfolio
                        </Button>
                      </Box>
                    </Box>
                  )}
                >
                  {initialPortfolioFormValues ? (
                    <EditPortfolioForm {...formikProps} isLoading={isLoadingFetchPortfolios} />
                  ) : (
                    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
                      <ErrorMessage sx={{ fontSize: '18px' }}>
                        There was an error loading the selected Portfolio. Please close the modal and try again.
                      </ErrorMessage>
                    </Box>
                  )}
                </BaseModalContent>
              )
            }}
          </Formik>
        )}
      </StyledFormDialog>
    </>
  )
}

EditPortfolio.propTypes = {
  isEditDialogOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  selectedPortfolio: PropTypes.object,
}
