import React from 'react'
import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { Box, Skeleton } from '@mui/material'
import { getBenchmarkByState, getMapStates } from '../../../../services/apis'
import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useToastContext } from '../../../../context/ToastContext/useToastContext'
import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import MapCard from '../../../../components/mapCard/mapCard'
import { usePrevious } from '../../../../hooks/usePrevious'
import { useSidebarContext } from '../../../../context/SidebarContext/useSidebarContext'
import isEqual from 'lodash.isequal'

const PerformanceByGeography = ({
  children,
  selectedMetric,
  selectedValueDisplay,
  selectedDimension,
  isLoadingBenchmarkByState,
  setIsLoadingBenchmarkByState,
}) => {
  const { accessToken } = useAuthContext()
  const { addToast } = useToastContext()
  const { selectedScenario, unitOfMeasurement, unitOfTime, isLoadingFetchScenarios } = useFilterContext()
  const { scenarioParamsWithMetadata, isLoadingFetchScenarioParams } = useSidebarContext()

  const [mapData, setMapData] = React.useState(null)
  const [mapMetadata, setMapMetadata] = React.useState(null)
  const [geoMapStatesData, setGeoMapStatesData] = React.useState(null)

  const scenarioid = selectedScenario?.scenarioid

  const fetchStateBenchmark = React.useCallback(async () => {
    try {
      setIsLoadingBenchmarkByState(true)
      if (
        accessToken &&
        unitOfMeasurement &&
        unitOfTime &&
        scenarioid &&
        selectedMetric.value &&
        selectedValueDisplay &&
        selectedDimension
      ) {
        const getBenchmarkTrendParams = {
          units_payload: {
            rate: unitOfMeasurement,
            time_granularity: unitOfTime,
          },
          scenarioid: scenarioid,
          metric: selectedMetric.value,
          value_display: selectedValueDisplay,
          dimension: selectedDimension,
        }

        const res = await getBenchmarkByState(getBenchmarkTrendParams, accessToken)
        setMapData(res.data.state_data)
        setMapMetadata(res.data.metadata)
      }
    } catch (error) {
      addToast({
        toastId: TOAST_IDS.getBenchmarkByStateForBenchmarkExplorer,
        variant: 'error',
        msg: `Error loading benchmark by state - ${error?.message || 'unknown error'}`,
      })
    } finally {
      setIsLoadingBenchmarkByState(false)
    }
  }, [
    accessToken,
    addToast,
    scenarioid,
    selectedDimension,
    selectedMetric,
    unitOfMeasurement,
    unitOfTime,
    selectedValueDisplay,
    setIsLoadingBenchmarkByState,
  ])

  React.useEffect(() => {
    fetchStateBenchmark()
  }, [fetchStateBenchmark])

  const previousSelectedScenarioParams = usePrevious(scenarioParamsWithMetadata)
  React.useEffect(() => {
    // only run use effect if there were previously scenarioParamsWithMetadata defined && now the previous render values does not equal current value
    if (!!previousSelectedScenarioParams && !isEqual(scenarioParamsWithMetadata, previousSelectedScenarioParams)) {
      fetchStateBenchmark()
    }
  }, [previousSelectedScenarioParams, fetchStateBenchmark, scenarioParamsWithMetadata])

  const isLoading = isLoadingBenchmarkByState || isLoadingFetchScenarios || isLoadingFetchScenarioParams

  const dataWithMetricRateAsNumber = React.useMemo(() => {
    return mapData?.map(d => {
      if (typeof d?.metric_rate === 'string') {
        return {
          ...d,
          metric_rate: parseFloat(d.metric_rate, 10),
        }
      }

      return d
    })
  }, [mapData])

  const hasSetInitialMapState = React.useRef(false)
  React.useEffect(() => {
    if (hasSetInitialMapState.current || !accessToken) return
    hasSetInitialMapState.current = true

    const fetchGeoMapStates = async () => {
      const mapData = await getMapStates({ accessToken })
      setGeoMapStatesData(mapData)
    }

    fetchGeoMapStates()
  }, [accessToken])

  return (
    <>
      {children}
      {isLoading ? (
        <Box sx={{ paddingX: 2 }}>
          <Skeleton animation="wave" variant="rectangular" height={620} />
        </Box>
      ) : (
        <>
          {Array.isArray(dataWithMetricRateAsNumber) ? (
            <MapCard
              data={dataWithMetricRateAsNumber}
              geoMapStatesData={geoMapStatesData}
              selectedBenchmark={selectedValueDisplay}
              marginBottom={2}
              metadata={mapMetadata}
              selectedValueDisplay={selectedValueDisplay}
            />
          ) : null}
        </>
      )}
    </>
  )
}

export default PerformanceByGeography
