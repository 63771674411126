import React from 'react'
import { Button } from '@mui/material'
import { APP_PAGES_MAP } from '../navbar/navbar.constants'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.handleButtonClick = this.handleButtonClick.bind(this)
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('error', error)
    console.error('errorInfo', errorInfo)
  }

  handleButtonClick() {
    this.setState({ hasError: false })
    window.location.pathname = APP_PAGES_MAP.DATA_MANAGER.path
  }

  render() {
    const handleButtonClick = this.handleButtonClick
    if (this.state.hasError) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
          <div>
            <h1>An unexpected error occurred.</h1>
          </div>
          <div>
            <Button variant="contained" onClick={handleButtonClick}>
              Click Here to try again.
            </Button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
