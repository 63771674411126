import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography, useTheme } from '@mui/material'

import TitledDropdown from '../../../../../components/titledDropdown'
import LoadingSkeleton from '../../../../../components/LoadingSkeleton'
import { useAppContext } from '../../../../../context/AppContext/useAppContext'

/**
 * Renders the header component for the performance scorecard.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleMetricSelect - The function to handle metric selection.
 * @param {boolean} props.isLoadingSelectedClinicName - Indicates if the selected clinic name is loading.
 * @param {string} props.selectedClinicName - The name of the selected clinic.
 * @param {string} props.selectedMetricOption - The selected metric option.
 * @param {boolean} [props.shouldDisableMetricDropdown=false] - Indicates if the metric dropdown should be disabled.
 * @returns {JSX.Element} The rendered component.
 */
export const ScorecardHeader = ({
  handleMetricSelect,
  isLoadingSelectedClinicName,
  selectedClinicName,
  selectedMetricOption,
  shouldDisableMetricDropdown = false,
}) => {
  const theme = useTheme()
  const { menuOptions, isLoadingFetchMenuOptions } = useAppContext()

  const metricList = React.useMemo(() => {
    return menuOptions
      ?.filter(opt => opt.menu === 'metrics')
      ?.map(filteredOpt => {
        return { value: filteredOpt.option_id, name: filteredOpt.option_value }
      })
  }, [menuOptions])

  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: 1,
        minHeight: '96px',
        marginBottom: { xs: 2, md: 1 },
        alignItems: 'center',
      }}
    >
      <Grid item xs={12} sm={6} md={5} lg={4} sx={{ paddingLeft: { xs: 1 }, paddingY: { xs: 1, md: 0 } }}>
        <Typography sx={{ fontSize: { xs: '24px', lg: '30px', xl: '32px' }, fontWeight: 700 }}>
          Performance Scorecard
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: { xs: 1 }, paddingY: { xs: 1, md: 0 } }}>
        {isLoadingSelectedClinicName ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%', paddingX: 3 }}>
            <LoadingSkeleton variant="text" height="50px" width="100%" data-testid="loading-selected-clinic" />
          </Box>
        ) : (
          <Typography
            sx={{
              fontSize: { xs: '20px', lg: '22px' },
              textAlign: { xs: 'left', sm: 'center' },
            }}
          >
            {selectedClinicName || 'No Clinic Selected'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={4} sx={{ paddingLeft: { xs: 1 }, paddingY: { xs: 1, md: 0 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '500px', height: '100%', width: '100%' }}>
          <TitledDropdown
            id="performance-scorecard-metric-dropdown"
            label="Efficiency Metric"
            isLoading={isLoadingFetchMenuOptions}
            menuOptions={metricList}
            value={selectedMetricOption}
            onChange={handleMetricSelect}
            selectStyles={{ borderRadius: '5px', width: '100%', height: '56px' }}
            formControlStyles={{ width: '100%' }}
            isDisabled={shouldDisableMetricDropdown}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

ScorecardHeader.propTypes = {
  handleMetricSelect: PropTypes.func.isRequired,
  isLoadingSelectedClinicName: PropTypes.bool.isRequired,
  selectedClinicName: PropTypes.string,
  selectedMetricOption: PropTypes.string.isRequired,
  shouldDisableMetricDropdown: PropTypes.bool,
}
