import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'

export default function DimensionAnalysisTable(props) {
  return (
    <Table sx={{ maxWidth: '95%', minWidth: '85%' }}>
      <TableHead>
        <TableRow>
          {props.data.header.map((title, index) => {
            if (title === 'Dimension') {
              return (
                <TableCell key={index} align="center">
                  <b>{props.dimension}</b>
                </TableCell>
              )
            } else {
              return (
                <TableCell key={index} align="center">
                  {title}
                </TableCell>
              )
            }
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data.records.map((row, index) => (
          <TableRow key={index} class="efficiency">
            <TableCell align="center">{row.dimension}</TableCell>
            <TableCell align="center">{(row.pct_portfolio * 1000) / 10}%</TableCell>
            <TableCell align="center">{(row.pct_benchmark * 1000) / 10}%</TableCell>
            <TableCell align="center">{row.metric_rate_benchmark}</TableCell>
            <TableCell align="center">{row.metric_rate_portfolio}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
