import PropTypes from 'prop-types'

const requiredNullableNumber = PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]).isRequired
const requiredNullableString = PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]).isRequired
const requiredNullableBoolean = PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]).isRequired

const clinicSummaryDataProperties = {
  avg_scheduled_appointments: requiredNullableNumber,
  avg_therapist_count: requiredNullableNumber,
  billable_visits: requiredNullableNumber,
  clinic_address: requiredNullableString,
  clinic_city: requiredNullableString,
  clinic_count: requiredNullableNumber,
  clinic_full_address: requiredNullableString,
  clinic_id: requiredNullableNumber,
  clinic_name: requiredNullableString,
  clinic_npi: requiredNullableNumber,
  clinic_state: requiredNullableString,
  clinic_timezone: requiredNullableString,
  clinic_zip: requiredNullableNumber,
  clinicid: requiredNullableNumber,
  completed_appointments: requiredNullableNumber,
  emr_accountid: requiredNullableNumber,
  facility_created_date: requiredNullableNumber,
  ie_visit_ratio: requiredNullableNumber,
  ie_visits: requiredNullableNumber,
  insert_date: requiredNullableNumber,
  is_active: requiredNullableBoolean,
  is_portfolio: requiredNullableNumber,
  no_show_appointments: requiredNullableNumber,
  salesforceid: requiredNullableString,
  selected: requiredNullableBoolean,
  total_appointments: requiredNullableNumber,
  total_appointments_cancelled: requiredNullableNumber,
  total_appointments_not_cancelled: requiredNullableNumber,
  total_billed_visit_count: requiredNullableNumber,
  total_ie_billed_visit_count: requiredNullableNumber,
  total_non_ie_billed_visit_count: requiredNullableNumber,
  total_periods: requiredNullableNumber,
  total_units_billed: requiredNullableNumber,
  treating_therapist_count: requiredNullableNumber,
  units_per_visit: requiredNullableNumber,
}

const clinicExplorerSummaryDataItem = PropTypes.shape(clinicSummaryDataProperties)
const clinicExplorerSummaryDataRecords = PropTypes.arrayOf(clinicExplorerSummaryDataItem)

const clinicListTableDataProperties = {
  clinic_id: clinicSummaryDataProperties.clinic_id,
  clinic_name: clinicSummaryDataProperties.clinic_name,
  clinic_full_address: clinicSummaryDataProperties.clinic_full_address,
  avg_scheduled_appointments: clinicSummaryDataProperties.avg_scheduled_appointments,
  completed_appointments: clinicSummaryDataProperties.completed_appointments,
  billable_visits: clinicSummaryDataProperties.billable_visits,
  ie_visits: clinicSummaryDataProperties.ie_visits,
  units_per_visit: clinicSummaryDataProperties.units_per_visit,
  ie_visit_ratio: clinicSummaryDataProperties.ie_visit_ratio,
}

export const clinicListTableRecordsPropType = PropTypes.arrayOf(
  PropTypes.shape(clinicListTableDataProperties).isRequired,
)

/**
 * PropType for data used with clinic explorer summary table
 */
export const clinicExplorerSummaryDataPropType = PropTypes.shape({
  header: PropTypes.array,
  records: clinicExplorerSummaryDataRecords,
})

export const targetClinicRadarPropTypes = {
  appointment_date_year: PropTypes.number,
  avg_scheduled_appointments: PropTypes.number,
  billable_visits: PropTypes.number,
  clinic_id: PropTypes.string,
  completed_appointments: PropTypes.number,
  ie_visits: PropTypes.number,
  total_active_therapists: PropTypes.number,
  total_appointments: PropTypes.number,
  total_billed_visit_count: PropTypes.number,
  total_ie_billed_visit_count: PropTypes.number,
  total_units_billed: PropTypes.number,
  total_units_billed_per_visit: PropTypes.number,
}
