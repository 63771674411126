/**
 * Unique toast ids for success variant
 */
const SUCCESS_TOAST_IDS = {
  createPortfolioSuccess: 'createPortfolioSuccess',
  createScenarioSuccess: 'createScenarioSuccess',
  deleteScenarioSuccess: 'deleteScenarioSuccess',
  updateScenarioParamsSuccess: 'updateScenarioParamsSuccess',
  deletePortfolioSuccess: 'deletePortfolioSuccess',
  setDefaultScenarioSuccess: 'setDefaultScenarioSuccess',
  editScenarioSuccess: 'editScenarioSuccess',
  editPortfolioSuccess: 'editPortfolioSuccess',
}

/**
 * Unique toast ids for error variant
 */
const ERROR_TOAST_IDS = {
  createPortfolioError: 'createPortfolioError',
  createScenarioError: 'createScenarioError',
  deleteScenarioError: 'deleteScenarioError',
  getMenuOptionsError: 'getMenuOptionsError',
  getEffMetricErrForOverview: 'getEffMetricErrForOverview',
  getEffMetricErrForBenchmarkExplorer: 'getEffMetricErrForBenchmarkExplorer',
  getBenchmarkTrendErrForOverview: 'getBenchmarkTrendErrForOverview',
  getBenchmarkDistErrForOverview: 'getBenchmarkDistErrForOverview',
  getBenchmarkByStateForBenchmarkExplorer: 'getBenchmarkByStateForBenchmarkExplorer',
  getBenchmarkByDimensionForBenchmarkExplorer: 'getBenchmarkByDimensionForBenchmarkExplorer',
  getBenchmarkClinicSummaryTableErrorForClinicExplorer: 'getBenchmarkClinicSummaryTableErrorForClinicExplorer',
  getClinicBenchmarkTrendErrorForClinicExplorer: 'getClinicBenchmarkTrendErrorForClinicExplorer',
  getBenchmarkBestInClassAnalysisError: 'getBenchmarkBestInClassAnalysisError',
  listAllClinicsErrForDataManager: 'listAllClinicsErrForDataManager',
  updateScenarioError: 'updateScenarioError',
  getUserPreferenceForAppContextError: 'getUserPreferenceForAppContextError',
  updateDefaultScenarioError: 'updateDefaultScenarioError',
  setDefaultScenarioError: 'setDefaultScenarioError',
  newSelectedUnitOfMeasurementError: 'newSelectedUnitOfMeasurementError',
  newSelectedUnitOfTimeError: 'newSelectedUnitOfTimeError',
  scenarioListErrorForFilterContext: 'scenarioListErrorForFilterContext',
  deleteDefaultScenarioError: 'deleteDefaultScenarioError',
  updateSelectedScenarioError: 'updateSelectedScenarioError',
  getPortfolioListErrorForSidebar: 'getPortfolioListErrorForSidebar',
  valueDisplaySelectorError: 'valueDisplaySelectorError',
  createScenarioMissingPortfolioError: 'createScenarioMissingPortfolioError',
  createScenarioNoYearsSelectedError: 'createScenarioNoYearsSelectedError',
  getScenarioParamsErrorForSidebarContext: 'getScenarioParamsErrorForSidebarContext',
  refetchScenarioParamsErrorForSidebarComponent: 'refetchScenarioParamsErrorForSidebarComponent',
  getPortfoliosErrorForPortfolioCard: 'getPortfoliosErrorForPortfolioCard',
  refetchScenariosErrorForSidebarComponent: 'refetchScenariosErrorForSidebarComponent',
  refetchScenariosErrorForCreateScenarioCard: 'refetchScenariosErrorForCreateScenarioCard',
  refetchScenariosErrForScenarioManagerCard: 'refetchScenariosErrForScenarioManagerCard',
  updateErrorForCreateScenarioCard: 'updateErrorForCreateScenarioCard',
  updateSelectedScenarioInFilterErr: 'updateSelectedScenarioInFilterErr',
  fetchClinicBenchMarkTrendErr: 'fetchClinicBenchMarkTrendErr',
  refetchClinicBenchMarkTrendErr: 'refetchClinicBenchMarkTrendErr',
  refetchPortfoliosOnDeletePortfolioError: 'refetchPortfoliosOnDeletePortfolioError',
  deletePortfolioError: 'deletePortfolioError',
  refetchScenariosOnDeleteScenarioError: 'refetchScenariosOnDeleteScenarioError',
  getScenarioParamsErrorForEditScenario: 'getScenarioParamsErrorForEditScenario',
  refetchScenariosErrorForEditScenario: 'refetchScenariosErrorForEditScenario',
  refetchScenarioParamsErrForEditScenario: 'refetchScenarioParamsErrForEditScenario',
  getScenariosErrorForPortfolioCard: 'getScenariosErrorForPortfolioCard',
  fetchPerformanceScoreCardError: 'fetchPerformanceScoreCardError',
}

export const TOAST_IDS = {
  ...SUCCESS_TOAST_IDS,
  ...ERROR_TOAST_IDS,
}

/**
 * The code below is used to check if the keys and values in TOAST_IDS are unique.
 * If they are not unique, an error will be thrown in the local environment only
 */
const keys = Object.keys(TOAST_IDS)
const values = Object.values(TOAST_IDS)
const uniqueKeys = new Set(keys)
const uniqueValues = new Set(values)

const duplicatesDetected = !(keys.length === uniqueKeys.size && values.length === uniqueValues.size)

if (window.env.APP_ENVIRONMENT === 'local' && duplicatesDetected) {
  throw new Error('non-unique keys or values in TOAST_IDS object.')
}
