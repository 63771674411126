import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { deleteScenario, defaultScenario } from '../../../../services/apis'
import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useAppContext } from '../../../../context/AppContext/useAppContext'
import { useToastContext } from '../../../../context/ToastContext/useToastContext'
import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import ScenarioListTable from './ScenarioListTable'

const ScenarioManagerCard = () => {
  const { accessToken } = useAuthContext()
  const { defaultScenarioId, updateDefaultScenarioId } = useAppContext()
  const { addToast } = useToastContext()
  const { isLoadingFetchScenarios, refetchScenarios } = useFilterContext()
  const [isLoadingPreference, setIsLoadingPreference] = React.useState(false)

  const handleDeleteScenario = e => {
    const deletedScenarioId = e.row.scenarioid

    if (defaultScenarioId !== e.row.scenarioid) {
      deleteScenario({ scenarioid: deletedScenarioId, accessToken: accessToken })
        .then(async res => {
          addToast({
            toastId: TOAST_IDS.deleteScenarioSuccess,
            variant: 'success',
            msg: `Scenario "${res?.scenario_title}" was deleted.`,
          })

          // We need to refetch the scenarios to make sure the filter bar will be updated
          await refetchScenarios({
            accessToken,
            errorToastId: TOAST_IDS.refetchScenariosErrForScenarioManagerCard,
          })
        })
        .catch(e => {
          addToast({
            toastId: TOAST_IDS.deleteScenarioError,
            variant: 'error',
            msg: `Delete scenario failed - ${e?.message || 'unknown error'}`,
          })
        })
    } else {
      addToast({
        toastId: TOAST_IDS.deleteDefaultScenarioError,
        variant: 'error',
        msg: `You cannot delete the default scenario`,
      })
    }
  }

  const handleDefaultScenario = e => {
    const scenarioId = e.row.scenarioid

    if (defaultScenarioId === scenarioId) {
      addToast({
        toastId: TOAST_IDS.setDefaultScenarioError,
        variant: 'error',
        msg: `This scenario is already the default scenario.`,
      })
      return
    }

    setIsLoadingPreference(true)

    defaultScenario({ scenarioid: scenarioId, accessToken: accessToken })
      .then(async res => {
        addToast({
          toastId: TOAST_IDS.setDefaultScenarioSuccess,
          variant: 'success',
          msg: `The default scenario has been set to "${e.row.scenario_title}".`,
        })

        // We need to refetch the scenarios to make sure the filter bar will be updated
        await refetchScenarios({
          accessToken,
          errorToastId: TOAST_IDS.refetchScenariosErrForScenarioManagerCard,
        })
        await updateDefaultScenarioId(e.row.scenarioid)
      })
      .catch(e => {
        addToast({
          toastId: TOAST_IDS.deleteScenarioError,
          variant: 'error',
          msg: `Change the default scenario failed - ${e?.message || 'unknown error'}`,
        })
      })
      .finally(() => {
        setIsLoadingPreference(false)
      })
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Grid container sx={{ border: '2px solid #F5F8F9', padding: 2 }}>
        <Grid item xs={12} sx={{ marginBottom: 2 }}>
          <Typography sx={{ fontSize: '20pt', fontWeight: 700 }}>Scenario Manager</Typography>
        </Grid>
        <Grid item xs={12}>
          <ScenarioListTable
            handleDelete={handleDeleteScenario}
            handleDefault={handleDefaultScenario}
            isLoading={isLoadingFetchScenarios}
            isLoadingUserPreference={isLoadingPreference}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ScenarioManagerCard
