import React from 'react'
import { Card, CardContent, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { getEfficiencyMetricOverview } from '../../../../services/apis'

import { TOAST_IDS } from '../../../../context/ToastContext/constants'
import { useAuthContext } from '../../../../context/AuthContext/useAuthContext'
import { useToastContext } from '../../../../context/ToastContext/useToastContext'
import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { useSidebarContext } from '../../../../context/SidebarContext/useSidebarContext'
import { usePrevious } from '../../../../hooks/usePrevious'
import isEqual from 'lodash.isequal'

const EFFICIENCY_TABLE_HEADERS = [
  'Group',
  'Clinic Count',
  'Avg. Scheduled Appointments',
  'Avg. Cancel/No Show Rate',
  'Avg. Completed Appointments',
  'Avg. Billable Visits',
  'Avg. IE Visits',
  'Avg. Units per Visit',
  'Avg. IE to Visit Ratio',
]

const EfficiencyTable = ({ page }) => {
  const { selectedScenario, isLoadingFetchScenarios, unitOfMeasurement, unitOfTime } = useFilterContext()
  const { accessToken } = useAuthContext()
  const { addToast } = useToastContext()
  const { scenarioParamsWithMetadata, isLoadingFetchScenarioParams } = useSidebarContext()

  const [loadingSpinnerEfficiencyTable, setLoadingSpinnerEfficiencyTable] = React.useState(true)
  const [efficiencyTableData, setEfficiencyTableData] = React.useState([])

  const fetchEfficiencyMetricOverview = React.useCallback(async () => {
    try {
      setLoadingSpinnerEfficiencyTable(true)

      if (accessToken && unitOfMeasurement && unitOfTime && selectedScenario?.scenarioid) {
        const res = await getEfficiencyMetricOverview(accessToken, {
          page: page,
          scenarioid: selectedScenario?.scenarioid,
          units_payload: {
            rate: unitOfMeasurement,
            time_granularity: unitOfTime,
          },
        })

        setEfficiencyTableData(Array.isArray(res?.data?.efficiency_metrics) ? res.data.efficiency_metrics : [])
      }
    } catch (error) {
      setEfficiencyTableData([])
      addToast({
        toastId: TOAST_IDS.getEffMetricErrForOverview,
        variant: 'error',
        msg: `Error loading efficiency metric overview - ${error?.message || 'unknown error'}`,
      })
    } finally {
      setLoadingSpinnerEfficiencyTable(false)
    }
  }, [page, selectedScenario?.scenarioid, accessToken, addToast, unitOfMeasurement, unitOfTime])

  React.useEffect(() => {
    fetchEfficiencyMetricOverview()
  }, [fetchEfficiencyMetricOverview])

  const previousSelectedScenarioParams = usePrevious(scenarioParamsWithMetadata)
  React.useEffect(() => {
    // only run use effect if there were previously scenarioParamsWithMetadata defined && now the previous render values does not equal current value
    if (!!previousSelectedScenarioParams && !isEqual(scenarioParamsWithMetadata, previousSelectedScenarioParams)) {
      fetchEfficiencyMetricOverview()
    }
  }, [previousSelectedScenarioParams, fetchEfficiencyMetricOverview, scenarioParamsWithMetadata])

  const mappedRows = efficiencyTableData.map((row, index) => (
    <TableRow key={index} class="efficiency">
      <TableCell>{row.group}</TableCell>
      <TableCell align="center">{row.clinic_count}</TableCell>
      <TableCell align="center">{row.avg_scheduled_appointments}</TableCell>
      <TableCell align="center">{row.cancel_no_show * 100}%</TableCell>
      <TableCell align="center">{row.completed_appointments}</TableCell>
      <TableCell align="center">{row.billable_visits}</TableCell>
      <TableCell align="center">{row.ie_visits}</TableCell>
      <TableCell align="center">{row.units_per_visits}</TableCell>
      <TableCell align="center">{row.ie_visit_ratio * 100}%</TableCell>
    </TableRow>
  ))

  const isLoading = isLoadingFetchScenarios || loadingSpinnerEfficiencyTable || isLoadingFetchScenarioParams

  return (
    <Card sx={{ minHeight: '200px', overflow: 'auto' }}>
      <CardContent sx={{ paddingX: 2 }}>
        <Table sx={{ maxWidth: '100%' }}>
          <TableHead>
            <TableRow>
              {EFFICIENCY_TABLE_HEADERS.map(title => {
                return (
                  <TableCell key={title} align="center">
                    {title}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow class="efficiency">
                <TableCell align="center" colSpan={EFFICIENCY_TABLE_HEADERS.length}>
                  <Skeleton animation="wave" variant="rectangular" height={20} />
                </TableCell>
              </TableRow>
            ) : (
              <>{mappedRows}</>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default EfficiencyTable
