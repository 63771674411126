import React from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Divider, Grid, useMediaQuery, useTheme } from '@mui/material'
import { FilterContextProvider } from '../../context/FilterContext/FilterContext'
import { SidebarContextProvider } from '../../context/SidebarContext/SidebarContext'
import FilterBar from '../../components/filterBar'
import Sidebar from '../../components/sidebar'
import { APP_PAGES_MAP } from '../../components/navbar/navbar.constants'
import { STICKY_COMPONENT_CONFIG } from '../../themes/stickyConfig'

const pagesWithSidebar = [
  APP_PAGES_MAP.HOME.path,
  APP_PAGES_MAP.OVERVIEW.path,
  APP_PAGES_MAP.BENCHMARK_EXPLORER.path,
  APP_PAGES_MAP.CLINIC_EXPLORER.path,
]
const pagesWithFilterbar = [
  APP_PAGES_MAP.HOME.path,
  APP_PAGES_MAP.OVERVIEW.path,
  APP_PAGES_MAP.BENCHMARK_EXPLORER.path,
  APP_PAGES_MAP.CLINIC_EXPLORER.path,
]

/**
 * Renders the body template component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render.
 * @returns {ReactNode} The rendered body template component.
 */
export const BodyTemplate = ({ computedNavbarHeight = 0, children }) => {
  const location = useLocation()
  const theme = useTheme()
  const isStickyEnabled = useMediaQuery(theme.breakpoints.up('md'))

  const hasSidebar = pagesWithSidebar.includes(location.pathname)
  const hasFilterbar = pagesWithFilterbar.includes(location.pathname)

  const [computedFilterBarHeight, setComputedFilterBarHeight] = React.useState(0)

  const filterBarRef = React.useRef()

  React.useEffect(() => {
    if (hasFilterbar && isStickyEnabled) {
      // This conditional addresses the styling issue when navigating from data manager in mobile view to overview and then resize out of mobile view
      setComputedFilterBarHeight(hasFilterbar ? filterBarRef.current?.clientHeight : 0)
    }
  }, [hasFilterbar, isStickyEnabled])

  // Scroll to top when the user navigagtes to a different page
  React.useEffect(() => {
    window?.scrollTo({ top: 0 })
  }, [location.pathname])

  const navbarAndFilterBarHeight = (computedNavbarHeight || 0) + computedFilterBarHeight

  return (
    <FilterContextProvider>
      <SidebarContextProvider>
        {hasFilterbar && (
          <Grid
            data-testid="filter-bar-sticky-styles"
            container
            ref={filterBarRef}
            sx={{
              position: STICKY_COMPONENT_CONFIG.position,
              top: computedNavbarHeight,
              zIndex: STICKY_COMPONENT_CONFIG.zIndex,
            }}
          >
            <Grid item xs={12}>
              <FilterBar />
              <Divider />
            </Grid>
          </Grid>
        )}
        {hasSidebar ? (
          <Grid
            container
            sx={{
              width: '100%',
              height: '100%',
              marginY: 3,
              paddingX: 2,
            }}
          >
            <Grid item xs={12} md={4} lg={3} xl={2} sx={{ paddingRight: { xs: 0, md: 1 } }}>
              <Box
                data-testid="sidebar-bar-sticky-styles"
                sx={{
                  position: 'sticky',
                  top: navbarAndFilterBarHeight,
                  width: '100%',
                  height: '100%',
                  background: '#fff',
                  maxHeight: {
                    xs: '100%',
                    md: `calc(100vh - ${navbarAndFilterBarHeight}px)`,
                  },
                  overflowY: 'auto',
                }}
              >
                <Sidebar />
              </Box>
            </Grid>
            <Grid item xs={12} md={8} lg={9} xl={10}>
              {children}
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ marginTop: { xs: 2, md: 0 } }}>
              {children}
            </Grid>
          </Grid>
        )}
      </SidebarContextProvider>
    </FilterContextProvider>
  )
}
