import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthContext } from '../../../context/AuthContext/useAuthContext'
import { useAppContext } from '../../../context/AppContext/useAppContext'

import LoadingPage from '../../../pages/loadingPage'
import Unauthorized from '../../../pages/errorPages/Unauthorized'
import Unauthenticated from '../../../pages/errorPages/Unauthenticated'
import UnknownError from '../../../pages/errorPages/UnknownError'
import ConnectionError from '../../../pages/errorPages/ConnectionError'

/**
 * Wrapper component for the body of the page.  It is responsible for rendering components for page
 * load and top level error states.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render.
 * @returns {ReactNode} The rendered body wrapper component.
 */
export const BodyWrapper = ({ children }) => {
  const { isLoading: isAuth0Loading, error: auth0Error, isAuthenticated } = useAuth0()
  const {
    isLoadingFetchTokenSilently,
    fetchTokenSilentlyError,
    accessToken,
    isLoadingValidatePermissions,
    validatePermissionsError,
    isAuthorizedWithPermission,
  } = useAuthContext()

  const { isLoadingFetchUserPreferences } = useAppContext()

  const isAuthLoading = isAuth0Loading || isLoadingFetchTokenSilently || isLoadingValidatePermissions

  const isLoading = isAuthLoading || isLoadingFetchUserPreferences
  const hasError = fetchTokenSilentlyError || auth0Error || validatePermissionsError

  if (isLoading) {
    // isLoadingFetchUserPreferences is required to know what page to load on sign in and so we will wait for this to finish loading
    return <LoadingPage />
  }

  if (!isLoading && (fetchTokenSilentlyError || auth0Error || !isAuthenticated)) {
    return <Unauthenticated />
  }

  if (!isLoading && (validatePermissionsError || !isAuthorizedWithPermission)) {
    // when server is down or no internet connection, the error message should be "Failed to fetch" based on local testing. Here we don't want to show the unauthorized page:
    if (validatePermissionsError?.message?.includes('Failed to fetch')) {
      return <ConnectionError />
    }

    return <Unauthorized />
  }

  if (!isLoading && (hasError || !accessToken)) {
    return <UnknownError />
  }

  return children
}
