import * as React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'

/**
 * BaseModalContent MUST BE WRAPPED by a Dialog component.
 * The component contains the essential modal components for building and customizing modal content.
 * Using this base content modal, additional modals can be built and customized as needed (see CreatePortfolio).
 *
 * @component
 * @param {ReactNode} children - The content of the modal.
 * @param {object} closeIconBtnStyles - Custom styles for the close icon.
 * @param {object} closeIconBtnAttributes - Custom attributes for the close icon button.
 * @param {object} dialogTitleStyles - Custom styles for the dialog title.
 * @param {function} handleClickCloseButton - Callback function to handle clicking the modal close button.
 * @param {boolean} hasContentDividers - Determines whether the content of the modal has dividers or not.
 * @param {string | React.ReactNode} modalTitle - The title of the modal.
 * @param {function} renderModalFooter - Callback function to render custom dialog actions.
 * @returns {JSX.Element} The rendered BaseModalContent component.
 */
export const BaseModalContent = ({
  children,
  closeIconBtnStyles = {},
  closeIconBtnAttributes = {},
  dialogTitleStyles = {},
  handleClickCloseButton,
  hasContentDividers,
  modalTitle,
  renderModalFooter,
}) => {
  return (
    <>
      {modalTitle && (
        <DialogTitle data-testid="modal-title" sx={{ m: 0, p: 2, ...dialogTitleStyles }}>
          {modalTitle}
        </DialogTitle>
      )}
      <IconButton
        data-testid="base-modal-close-button"
        aria-label="close"
        onClick={handleClickCloseButton}
        sx={{
          position: 'absolute',
          right: 8,
          top: modalTitle ? 14 : 8,
          color: theme => theme.palette.grey[500],
          ...closeIconBtnStyles, // ability to override right, top, color
        }}
        {...closeIconBtnAttributes}
      >
        <CloseIcon data-testid="base-modal-close-icon" />
      </IconButton>
      <DialogContent dividers={hasContentDividers}>{children}</DialogContent>
      {renderModalFooter ? <DialogActions data-testid="modal-actions">{renderModalFooter()}</DialogActions> : null}
    </>
  )
}

BaseModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  closeIconBtnAttributes: PropTypes.object,
  closeIconBtnStyles: PropTypes.object,
  dialogTitleStyles: PropTypes.object,
  handleClickCloseButton: PropTypes.func.isRequired,
  hasContentDividers: PropTypes.bool.isRequired,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  renderModalFooter: PropTypes.func,
}
