import React from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import ScorecardHeader from './ScorecardHeader'
import SingleClinicTrend from './SingleClinicTrend'
import { MONTH_LABELS, NO_DATA_LABEL } from '../../clinicExplorer.constants'

/**
 * Renders the Performance Scorecard component.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.clinicTrendData - The trend data for the clinic.
 * @param {Function} props.handleMetricSelect - The function to handle metric selection.
 * @param {Function} props.handleYearSelect - The function to handle year selection.
 * @param {boolean} props.isLoadingFetchScenarioParams - Flag indicating if scenario parameters are being fetched.
 * @param {boolean} props.isLoadingGetBenchmarkBestInClassAnalysis - Flag indicating if benchmark clinic summary table is being fetched.
 * @param {boolean} props.isLoadingGetClinicBenchmarkTrend - Flag indicating if clinic benchmark trend is being fetched.
 * @param {string} props.selectedClinicName - The selected clinic name.
 * @param {Object} props.selectedMetric - The selected metric.
 * @param {string} props.selectedYear - The selected year.
 * @param {Array} props.selectedYearSummaryData - The summary data for the selected year.
 * @param {Array} props.yearList - The list of available years.
 * @returns {JSX.Element} The rendered Performance Scorecard component.
 */
export const PerformanceScorecard = ({
  clinicTrendData,
  handleMetricSelect,
  handleYearSelect,
  isLoadingFetchScenarioParams,
  isLoadingGetBenchmarkBestInClassAnalysis,
  isLoadingGetClinicBenchmarkTrend,
  selectedClinicName,
  selectedMetric,
  selectedYear,
  selectedYearSummaryData,
  yearList,
}) => {
  const trendDataByYear = React.useMemo(() => {
    const filteredData = clinicTrendData
      ?.filter(item => item.year === selectedYear)
      ?.map(filteredOpt => filteredOpt) || [NO_DATA_LABEL]

    return {
      labels: MONTH_LABELS,
      datasets: filteredData,
    }
  }, [clinicTrendData, selectedYear])

  const clinicStats = React.useMemo(() => {
    const data = selectedYearSummaryData?.[0] ?? {}
    return [
      { name: 'Total Active Therapists', value: data.total_active_therapists ?? 0 },
      { name: 'Total Appointments', value: data.total_appointments ?? 0 },
      { name: 'Total Visits', value: data.total_billed_visit_count ?? 0 },
      { name: 'Total Billable Visits', value: data.total_billed_visit_count ?? 0 },
      { name: 'Total IE', value: data.total_ie_billed_visit_count ?? 0 },
      { name: 'Total Units', value: data.total_units_billed ?? 0 },
    ]
  }, [selectedYearSummaryData])

  return (
    <Grid container sx={{ minHeight: '532px' }}>
      <Grid item xs={12}>
        <ScorecardHeader
          handleMetricSelect={handleMetricSelect}
          isLoadingSelectedClinicName={isLoadingGetBenchmarkBestInClassAnalysis || isLoadingFetchScenarioParams}
          selectedClinicName={selectedClinicName}
          selectedMetricOption={selectedMetric?.value}
          shouldDisableMetricDropdown={
            isLoadingGetBenchmarkBestInClassAnalysis || isLoadingGetClinicBenchmarkTrend || isLoadingFetchScenarioParams
          }
        />
      </Grid>
      <Grid item xs={12}>
        <SingleClinicTrend
          clinicGraphData={trendDataByYear}
          clinicStats={clinicStats}
          handleYearSelect={handleYearSelect}
          isLoadingClinicGraphData={isLoadingGetClinicBenchmarkTrend || isLoadingFetchScenarioParams}
          isLoadingClinicStats={isLoadingGetBenchmarkBestInClassAnalysis || isLoadingFetchScenarioParams}
          selectedYear={selectedYear}
          yearList={yearList}
          selectedBenchmark={selectedMetric?.displayText}
          shouldDisableYearDropdown={
            isLoadingGetBenchmarkBestInClassAnalysis || isLoadingGetClinicBenchmarkTrend || isLoadingFetchScenarioParams
          }
        />
      </Grid>
    </Grid>
  )
}

PerformanceScorecard.propTypes = {
  clinicTrendData: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
      label: PropTypes.string,
      year: PropTypes.number,
    }).isRequired,
  ),
  handleMetricSelect: PropTypes.func.isRequired,
  handleYearSelect: PropTypes.func.isRequired,
  isLoadingFetchScenarioParams: PropTypes.bool.isRequired,
  isLoadingGetBenchmarkBestInClassAnalysis: PropTypes.bool.isRequired,
  isLoadingGetClinicBenchmarkTrend: PropTypes.bool.isRequired,
  selectedClinicName: PropTypes.string,
  selectedMetric: PropTypes.shape({
    displayText: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  selectedYear: PropTypes.number,
  selectedYearSummaryData: PropTypes.arrayOf(
    PropTypes.shape({
      total_active_therapists: PropTypes.number,
      total_appointments: PropTypes.number,
      total_billed_visit_count: PropTypes.number,
      total_ie_billed_visit_count: PropTypes.number,
      total_units_billed: PropTypes.number,
    }),
  ),
  yearList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
  ),
}
