import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import TextField from '@mui/material/TextField'
import { usePrevious } from '../../hooks/usePrevious'

/**
 * A text field component with debounced input change handling.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.debounceIntervalInMs - The debounce interval in milliseconds.
 * @param {string} [props.defaultInputValue=''] - The default input value.
 * @param {Function} props.handleInputChange - The callback function to handle input change with callback options.
 * @param {boolean} props.shouldReset - The flag to reset the input value (should be a state value from the parent).  Needed for situations where reset required and default value has not changed.
 * @param {string} props.variant - The variant of the text field.
 * @param {Object} textFieldProps - Additional props for the underlying TextField component.
 * @returns {JSX.Element} The rendered DebouncedTextField component.
 */
export function DebouncedTextField({
  debounceIntervalInMs,
  defaultInputValue = '',
  handleInputChange,
  shouldReset = false,
  ...textFieldProps
}) {
  const [inputValue, setInputValue] = React.useState(defaultInputValue) // need to keep local state for the key values to be immediately updated

  // Define a ref to store the latest inputValue
  const inputValueRef = React.useRef(inputValue)

  // Update the ref whenever the inputValue changes
  React.useEffect(() => {
    inputValueRef.current = inputValue
  }, [inputValue])

  const resetInputValue = React.useCallback(
    overrideValue => {
      setInputValue(overrideValue || defaultInputValue)
    },
    [defaultInputValue],
  )

  React.useEffect(() => {
    resetInputValue(defaultInputValue)
  }, [defaultInputValue, resetInputValue])

  const previousShouldReset = usePrevious(shouldReset)
  React.useEffect(() => {
    if (!previousShouldReset && shouldReset) {
      resetInputValue()
    }
  }, [previousShouldReset, shouldReset, resetInputValue])

  // Debounce the handleInputChange to delay execution and cancel previous invocations
  const debouncedSearch = React.useMemo(
    () =>
      debounce(event => {
        handleInputChange(event, { resetInputValue }) // callback includes the ability to reset the search term
      }, debounceIntervalInMs),
    [handleInputChange, debounceIntervalInMs, resetInputValue],
  )

  const handleTextFieldChange = event => {
    setInputValue(event.target.value) // update internal state
    debouncedSearch(event) // Trigger handleInputChange callback after debounce delay
  }

  return <TextField {...textFieldProps} type="text" onChange={handleTextFieldChange} value={inputValue} />
}

DebouncedTextField.propTypes = {
  debounceIntervalInMs: PropTypes.number.isRequired,
  defaultInputValue: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  shouldReset: PropTypes.bool,
  // TextField Props https://mui.com/material-ui/api/text-field/ (only common ones listed here)
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.node,
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
}
