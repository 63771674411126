import React from 'react'

/**
 * Custom hook for fetching an access token silently.
 *
 * @param {Object} options - The options for the hook.
 * @param {Function} options.getAccessTokenSilently - The Auth0 function for getting the access token silently.
 * @param {Function} options.loginWithRedirect - The Auth0 function for redirecting to the login page.
 * @param {boolean} [options.defaultIsLoading=false] - The default value for the isLoading state.
 * @returns {Object} - The hook's state and functions.
 * @property {boolean} isLoading - Indicates whether the token is currently being fetched.
 * @property {Error|null} error - The error that occurred during token fetching.
 * @property {string|undefined} accessToken - The fetched access token.
 * @property {Function} fetchTokenSilently - The function for fetching the access token silently.
 */
export const useFetchTokenSilently = ({ getAccessTokenSilently, loginWithRedirect, defaultIsLoading = false }) => {
  const [accessToken, setAccessToken] = React.useState(undefined)
  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)
  /**
   * Fetches the access token silently.
   *
   * @returns {Promise<void>} A promise that resolves when the access token is fetched.
   * @throws {Error} If `getAccessTokenSilently` or `loginWithRedirect` is undefined.
   */
  const fetchTokenSilently = React.useCallback(async () => {
    try {
      if (!getAccessTokenSilently || !loginWithRedirect) {
        throw new Error('getAccessTokenSilently and loginWithRedirect are required')
      }
      setIsLoading(true)
      const newAccessToken = await getAccessTokenSilently()
      setAccessToken(newAccessToken)
    } catch (e) {
      console.error('Error getting accessToken', e)
      setError(e)
      if (loginWithRedirect) {
        await loginWithRedirect().catch(err => {
          console.error('Error redirecting', err)
          setError(err)
          setIsLoading(false)
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [getAccessTokenSilently, loginWithRedirect])

  return {
    isLoading,
    error,
    accessToken,
    fetchTokenSilently,
  }
}
