import React from 'react'
import { AuthContext } from './AuthContext'

/**
 * useAuthContext contains authenication and authorization
 * state related to the user's session.
 *
 * @returns {object} AuthContext
 * @returns {boolean} object.isLoadingFetchTokenSilently - whether the auth0 method getAccessTokenSilently is loading
 * @return {object} object.fetchTokenSilentlyError - Object containing any error that may have occured
 * @returns {string} object.accessToken - the user's access token
 * @returns {boolean} object.isLoadingValidatePermissions - whether the user's permissions are loading
 * @returns {object} object.validatePermissionsError - Object containing any error that may have occured
 * @returns {boolean} object.isAuthorizedWithPermission - whether the user is authorized with permission
 */

export const useAuthContext = () => {
  const context = React.useContext(AuthContext) // see auth context provider for details
  if (context === undefined) {
    throw new Error('useAuthContext must be used within Auth Context Provider')
  }

  return context
}
