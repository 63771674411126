import { escapeRegexProtectedChars } from '../escapeRegexProtectedChars'

/**
 * @param {Object} args
 * @param {Array} args.formattedClinics - Array of clinics
 * @param {Object} args.formattedClinics[] - Clinic object
 * @param {String} args.formattedClinics[].clinic_name - Clinic name
 * @param {String} args.formattedClinics[].clinicid - Clinic id
 * @param {String} args.clinicSearchTerm - Search term to filter clinics by (clinic id or clinic name)
 * @returns {Array} Clinics filtered by search term
 */
export const filterClinicsBySearchTerm = ({ formattedClinics = [], clinicSearchTerm }) => {
  if (typeof clinicSearchTerm !== 'string' || !Array.isArray(formattedClinics)) {
    console.error(`Error: clinicSearchTerm=${clinicSearchTerm}; formattedClinics=${JSON.stringify(formattedClinics)}.`)
    throw new Error('An error occurred while filtering clinics by search term.')
  }

  if (formattedClinics?.length === 0 || clinicSearchTerm.trim() === '') {
    return formattedClinics
  }

  const escapedSearch = escapeRegexProtectedChars(clinicSearchTerm.toLowerCase())
  const filteredData = formattedClinics?.filter(clinic => {
    const hasClinicNameMatch = clinic?.clinic_name?.toLowerCase()?.match(escapedSearch)
    const hasClinicIdMatch = clinic?.clinicid?.toLowerCase()?.match(escapedSearch)
    return hasClinicNameMatch || hasClinicIdMatch
  })

  return filteredData
}

/**
 * Filters clinics by search term.
 *
 * @param {Object} options - The options object.
 * @param {string} options.clinicSearchTerm - The search term for filtering clinics.
 * @returns {string|undefined} - The filtered search term or undefined if the search term is empty.
 * @throws {Error} - If the clinicSearchTerm is not a string.
 */
export const clinicsBySearchTerm = ({ clinicSearchTerm }) => {
  if (typeof clinicSearchTerm !== 'string') {
    console.error(`Error: clinicSearchTerm=${clinicSearchTerm}.`)
    throw new Error('An error occurred while filtering clinics by search term.')
  }

  if (clinicSearchTerm.trim() === '') {
    return ''
  }

  return escapeRegexProtectedChars(clinicSearchTerm.toLowerCase())
}
