import { UNIT_NAME_MAP_BY_OPTION_VALUE } from './FilterContext.constants'

/**
 * Builds the selected unit of measurement string.
 *
 * @param {Object} options - The selected unit of measurement and unit of time.
 * @param {string} options.unitOfMeasurement - The selected unit of measurement.
 * @param {string} options.unitOfTime - The selected unit of time.
 * @returns {string} The selected unit of measurement string.
 */
export const buildSelectedUOM = ({ unitOfMeasurement, unitOfTime }) => {
  if (!unitOfMeasurement || !unitOfTime) {
    throw new Error('unitOfMeasurement and unitOfTime are required')
  }
  return `${UNIT_NAME_MAP_BY_OPTION_VALUE[unitOfMeasurement]} ${UNIT_NAME_MAP_BY_OPTION_VALUE[unitOfTime]}`
}
