import React from 'react'
import { getUserPreference } from '../../../../services/apis'
import { useToastContext } from '../../../ToastContext/useToastContext'
import { TOAST_IDS } from '../../../ToastContext/constants'

/**
 * Custom React hook for fetching user preferences.
 *
 * @param {object} options - Options for the hook.
 * @param {boolean} options.defaultIsLoading - The default loading state (helpful if we know we will fetch on page load).
 * @returns {object} - An object containing isLoading, error, fetchUserPreferences, defaultScenarioId, and updateDefaultScenarioId.
 * @returns {boolean} object.isLoading - Whether the user preferences are loading.
 * @returns {object} object.error - The error object.
 * @returns {function} object.fetchUserPreferences - Function to fetch user preferences.
 * @returns {string} object.defaultScenarioId - The default scenario ID.
 * @returns {function} object.updateDefaultScenarioId - Function to update the default scenario ID.
 * @throws {Error} - If accessToken or errorToastId is missing.
 */
export const useFetchUserPreferences = options => {
  const defaultIsLoading = options?.defaultIsLoading
  const { addToast } = useToastContext()

  const [isLoading, setIsLoading] = React.useState(defaultIsLoading)
  const [error, setError] = React.useState(null)
  const [defaultScenarioId, setDefaultScenarioId] = React.useState(null)

  /**
   * Fetches user preferences using the provided access token and error toast ID.
   *
   * @param {Object} options - The options object.
   * @param {string} options.accessToken - The access token for authentication.
   * @param {string} options.errorToastId - The ID of the error toast.
   * @returns {Promise<void>} - A promise that resolves when the user preferences are fetched.
   * @throws {Error} - If accessToken or errorToastId is missing.
   */
  const fetchUserPreferences = React.useCallback(
    async ({ accessToken, errorToastId }) => {
      try {
        if (!accessToken || !errorToastId) {
          throw new Error('accessToken and errorToastId are required')
        }
        setIsLoading(true)
        const res = await getUserPreference(accessToken)
        const defaultScenarioId =
          res?.result?.find(value => value.preference_name === 'default_scenarioid')?.preference_value || null
        setDefaultScenarioId(defaultScenarioId)
      } catch (error) {
        console.error(error)
        addToast({
          toastId: errorToastId,
          variant: 'error',
          msg: `Error loading user preferences - ${error?.message || 'unknown error'}`,
        })
        setDefaultScenarioId(null)
        setError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [addToast],
  )

  /**
   * Updates the default scenario ID.
   *
   * @param {string} updatedDefaultScenarioId - The updated default scenario ID.
   * @returns {Promise<void>} - A promise that resolves once the default scenario ID is updated.
   */
  const updateDefaultScenarioId = React.useCallback(
    async updatedDefaultScenarioId => {
      if (typeof updatedDefaultScenarioId === 'string') {
        return setDefaultScenarioId(updatedDefaultScenarioId)
      }

      return addToast({
        toastId: TOAST_IDS.updateDefaultScenarioError,
        variant: 'error',
        msg: `Error updating the default scenario.`,
      })
    },
    [addToast],
  )

  return {
    isLoading,
    error,
    defaultScenarioId,
    fetchUserPreferences,
    updateDefaultScenarioId,
  }
}
