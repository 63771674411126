/*
  
    Global Wait Spinner

    Expected Props:
        title : text string shown in center of circle spinner
        height: dimensional height for spinner 
*/

import React from 'react'
import { CircularProgress, Typography, Box } from '@mui/material'

export function Spinner({ width, title }) {
  return (
    <Box sx={{ position: 'relative', direction: 'column', display: 'inline-flex', backgroundColor: '#FFFFFF' }}>
      <CircularProgress size={width || '100%'} thickness={1.8} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography component="div" sx={{ fontSize: '8pt' }}>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
