import * as React from 'react'
import { createPortal } from 'react-dom'

import { ToastStackProvider } from './toastStackContext'
import styled from '@emotion/styled'

const ToastStackStyle = styled.div`
  width: 100%;
  height: 100%;
`

// Note that z-index set to 2 to be above the table headers for clinic explorer
const ToastStackToastsStyle = styled.div`
  .bn-toaststack-toasts {
    z-index: 2;
    position: fixed;
    width: 100%;
    bottom: 25px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .bn-toast {
      margin: 5px 0;
    }
  }
`

/**
 * The Toast Stack component.  This is a wrapper component that is used to render the toasts in the application.
 */
const ToastStack = ({ children }) => {
  // We need to re-render the componet when the html element changes in order
  // to pass it to the provider.  Using a state variable causes us to rerender when
  // the state changes.
  const [toastStackElement, setToastStackElement] = React.useState(null)

  const toastContainer = createPortal(
    <ToastStackToastsStyle>
      <div ref={element => setToastStackElement(element)} className="bn-toaststack-toasts" />
    </ToastStackToastsStyle>,
    document.body,
  )

  return (
    <ToastStackStyle className="bn-toaststack">
      <ToastStackProvider value={toastStackElement}>
        {children}
        {toastContainer}
      </ToastStackProvider>
    </ToastStackStyle>
  )
}

export default ToastStack
