export const MONTH_LABELS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

/**
 * DEFAULT_STATS is used when data is not available and is also used for the
 * "name" property during the loading state
 */
const DEFAULT_VALUE = 'N/A'
export const FALLBACK_CLINIC_STATS = [
  { name: 'Total Active Therapists', value: DEFAULT_VALUE },
  { name: 'Total Appointments', value: DEFAULT_VALUE },
  { name: 'Total Visits', value: DEFAULT_VALUE },
  { name: 'Total Billable Visits', value: DEFAULT_VALUE },
  { name: 'Total IE', value: DEFAULT_VALUE },
  { name: 'Total Units', value: DEFAULT_VALUE },
]

export const NO_DATA_LABEL = { label: 'No Data' }

export const FALLBACK_CLINIC_GRAPH_DATA = {
  labels: MONTH_LABELS,
  datasets: [NO_DATA_LABEL],
}
