const auth0Config = {
  domain: window.env.AUTH0_DOMAIN || 'locallogin.webpt.com',
  clientId: window.env.AUTH0_CLIENT_ID || 'FSkGZ12WWh0RFll9Dm8COM21MrOUAgg0',
  redirectUri: `${window.location.origin}`,
}

const apiVersions = {
  v1: 'v1',
  v2: 'v2',
}

const apiUrlConfig = {
  marketBenchmarkingApiUrl: window.env.BENCHMARKING_SERVICE_API_URL || 'http://localhost:3000/',
  marketBenchmarkingApiPythonUrl: window.env.BENCHMARKING_SERVICE_API_PYTHON_URL || 'http://localhost:5000/',
}

export function getConfig() {
  const auth0 = auth0Config
  const apiVersion = apiVersions
  const apiUrl = apiUrlConfig

  return {
    auth0: auth0,
    apiUrl: apiUrl,
    apiVersion: apiVersion,
  }
}

export { auth0Config }
