/**
 * Validates a scenario object by checking if it contains all the required keys.
 * Throws an error if any required key is missing.
 *
 * @param {Object} scenario - The scenario object to validate.
 * @throws {Error} If any required key is missing.
 * @returns {void}
 */
export const validateScenario = scenario => {
  const requiredKeys = [
    'id',
    'scenarioid',
    'created_by_userid',
    'scenario_title',
    'scenario_description',
    'create_date',
    'is_temp',
    'cached_benchmark_table',
    'default_scenario_flag',
    'portfolio_title',
  ]

  const scenarioKeys = Object.keys(scenario)

  requiredKeys.forEach(key => {
    if (!scenarioKeys.includes(key)) {
      throw new Error(`Missing required key for scenario: ${key}`)
    }
  })
}
