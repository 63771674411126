import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  LogarithmicScale,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'
import LoadingSkeleton from '../LoadingSkeleton'
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, LogarithmicScale)

const checkHasData = dataset => Array.isArray(dataset?.data) && dataset?.data?.length > 0
const checkHasSomeData = datasets => datasets.some(dataset => checkHasData(dataset))

const DefaultLoadingSkeleton = () => (
  <LoadingSkeleton variant="rectangular" width="100%" height="400px" data-testid="default-loading-radar-chart" />
)

/**
 * Renders a radar comparison chart.
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Indicates if the component is loading.
 * @param {Function} props.renderLoading - The function to render the loading state.
 * @param {Object} props.data - The data for the chart. It should be an object with the following properties:
 *   - labels (array of strings): The labels for the chart.
 *   - datasets (array of objects): The datasets for the chart. Each object in the array should have the following properties:
 *     - label (string, required): The label for the dataset.
 *     - data (array of numbers or strings): The data points for the dataset.
 *     - fill (boolean, required): Whether to fill the area under the line.
 *     - backgroundColor (string, required): The background color for the dataset.
 *     - borderColor (string, required): The border color for the dataset.
 *     - pointBackgroundColor (string, required): The background color for the points.
 *     - pointBorderColor (string, required): The border color for the points.
 *     - pointHoverBackgroundColor (string, required): The background color for the points when hovered.
 *     - pointHoverBorderColor (string, required): The border color for the points when hovered.
 * @param {Object} props.fallbackData - The fallback data for the chart. It should be an object with the following properties:
 *   - labels (array of strings, required): The labels for the chart.
 *   - datasets (array, required): The datasets for the chart. It should be an empty array.
 * @param {Object} props.radarChartProps - Additional optional props to pass to the Radar component.
 * @returns {JSX.Element} The rendered RadarChart component.
 */
export const RadarChart = ({ isLoading, renderLoading, data: rawData, fallbackData, ...radarChartProps }) => {
  const data = Array.isArray(rawData?.datasets) && Array.isArray(rawData?.labels) ? rawData : fallbackData
  const hasData = checkHasSomeData(data?.datasets)

  const options = {
    type: 'radar',
    data,
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        suggestedMin: !hasData ? 0 : false,
        suggestedMax: !hasData ? 1 : false,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
      },
    },
  }

  if (isLoading) {
    return renderLoading ? renderLoading() : <DefaultLoadingSkeleton />
  }

  return <Radar data={data} options={options} data-testid={`radar-chart-has-data-${hasData}`} {...radarChartProps} />
}

RadarChart.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  renderLoading: PropTypes.func,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        fill: PropTypes.bool.isRequired,
        backgroundColor: PropTypes.string.isRequired,
        borderColor: PropTypes.string.isRequired,
        pointBackgroundColor: PropTypes.string.isRequired,
        pointBorderColor: PropTypes.string.isRequired,
        pointHoverBackgroundColor: PropTypes.string.isRequired,
        pointHoverBorderColor: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }),
  fallbackData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.array.isRequired, // will be empty array
  }),
  radarChartProps: PropTypes.object,
}
