import React from 'react'
import { Box, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material'

const TextLoadingSkeleton = () => <Skeleton animation="wave" variant="text" sx={{ fontSize: '19px' }} width="50%" />

export default function PerformanceCard({ portfolioPerformance, benchmarkPerformance, children, isLoading }) {
  return (
    <Card sx={{ border: '1px solid #EBEBEB', width: '100%' }}>
      <CardContent sx={{ padding: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
              <Typography sx={{ fontSize: '13px' }}>Portfolio</Typography>
              {isLoading ? (
                <TextLoadingSkeleton />
              ) : (
                <Typography sx={{ fontSize: '19px', fontWeight: 700 }}>{portfolioPerformance}</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <Typography sx={{ fontSize: '13px' }}>Benchmark</Typography>
              {isLoading ? (
                <TextLoadingSkeleton />
              ) : (
                <Typography sx={{ fontSize: '19px', fontWeight: 700 }}>{benchmarkPerformance}</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
