import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React from 'react'

const CheckboxMenuV2 = ({ checkBoxOptions, onChange, children, verifyIsChecked }) => {
  const formControlLabels = checkBoxOptions.map(
    ({ id, checkboxAttributes = {}, label, formControlLabelAttributes = {} }) => {
      return (
        <FormControlLabel
          key={id}
          control={<Checkbox {...checkboxAttributes} />}
          onChange={onChange}
          label={label}
          checked={verifyIsChecked(id)}
          {...formControlLabelAttributes}
        />
      )
    },
  )
  return (
    <FormGroup>
      {children}
      {formControlLabels}
    </FormGroup>
  )
}
export default CheckboxMenuV2
