import React from 'react'
import Box from '@mui/material/Box'

import { useFilterContext } from '../../../../context/FilterContext/useFilterContext'
import { useAppContext } from '../../../../context/AppContext/useAppContext'
import { StaticScrollTable } from '../../../../components/Table'
import { DefaultScenario } from '../../../dataManagerUpdate/pageComponents/scenarioManagerCard/ScenarioTable/DefaultScenario/DefaultScenario'
import DeleteScenario from '../../../dataManagerUpdate/pageComponents/scenarioManagerCard/ScenarioTable/DeleteScenario'
import { SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE, SORT_COLUMN_BY_BOOLEAN } from '../../../../components/Table/tableSort'

export default function ScenarioListTable() {
  const { isLoadingFetchScenarios, scenarios } = useFilterContext()
  const { isLoadingFetchUserPreferences } = useAppContext()

  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        header: 'ID', // The text to display in the header cell
        accessorFn: row => row.id, // How the data should be accessed from the row object
        cell: info => info.getValue(), // How the data should be displayed in the cell
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 1, // To style width of column -> This is the shorthand for flex-grow, flex-shrink and flex-basis combined (see https://css-tricks.com/snippets/css/a-guide-to-flexbox/)
      },
      {
        id: 'scenario_title',
        header: 'Scenario Name',
        accessorFn: row => row.scenario_title,
        cell: info => info.getValue(),
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 2,
      },
      {
        id: 'portfolio_title',
        header: 'Portfolio',
        accessorFn: row => row.portfolio_title,
        cell: info => info.getValue(),
        enableSorting: true,
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE,
        flex: 2,
      },
      {
        id: 'default_scenario_flag',
        accessorFn: row => row.default_scenario_flag,
        header: () => <Box paddingX={1}>Default Scenario</Box>,
        cell: params => (
          <Box sx={{ width: '100%', height: '100%', paddingX: 1 }}>
            <DefaultScenario row={params.row.original} />
          </Box>
        ),
        enableSorting: true,
        invertSorting: true, // this makes it so that the "true" value appears first
        sortDescFirst: false,
        sortingFn: SORT_COLUMN_BY_BOOLEAN,
        flex: 2,
      },
      {
        id: 'delete',
        accessorKey: 'Delete',
        header: 'Delete',
        cell: params => <DeleteScenario row={params.row.original} iconButtonStyles={{ padding: 1 }} />,
        enableSorting: false,
        flex: 1,
      },
    ],
    [],
  )

  return (
    <StaticScrollTable
      memoizedData={scenarios}
      memoizedColumns={columns}
      isLoading={isLoadingFetchUserPreferences || isLoadingFetchScenarios}
      tableHeight={310}
      noRecordsOverlay="No scenarios found."
      initialSortState={[{ id: 'default_scenario_flag', desc: false }]}
    />
  )
}
