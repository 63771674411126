/**
 * Format the clinic data for the radar chart
 * @param {Array | undefined} targetClinicRadar - The clinic data
 * @returns {Array} - The formatted clinic data
 */
export const formatBestInClassClinicData = targetClinicRadar => {
  const clinicData = [
    targetClinicRadar?.[0]?.avg_scheduled_appointments || 0,
    targetClinicRadar?.[0]?.completed_appointments || 0,
    targetClinicRadar?.[0]?.billable_visits || 0,
    targetClinicRadar?.[0]?.ie_visits || 0,
    targetClinicRadar?.[0]?.total_units_billed_per_visit || 0,
  ]

  return clinicData.every(value => value === 0) ? [] : clinicData
}

/**
 * Adjust the radar chart data if there is no data to be an empty array
 * @param {Array | undefined} data - The radar chart data
 * @returns {Array} - The adjusted radar chart data
 */
export const adjustRadarChartDataForNoData = data => {
  if (!Array.isArray(data) || data.length === 0) return []
  // We only want to return the data is a number or a number written as a string
  return data.every(value => !Number.isNaN(parseInt(value, 10))) ? data : []
}

/**
 * Build the target data set item for the radar chart
 * @param {Object} param0 - The target data set item
 * @param {string} param0.label - The target data set item label
 * @param {Array} param0.data - The target data to use in a given dataset
 * @returns {Object} - The target data set item
 */
export const buildTargetDatasetItem = ({ label = '', data = [] } = { label: '', data: [] }) => ({
  label,
  data,
  fill: true,
  backgroundColor: 'rgba(255, 99, 132, 0.2)',
  borderColor: 'rgb(255, 99, 132)',
  pointBackgroundColor: 'rgb(255, 99, 132)',
  pointBorderColor: '#fff',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgb(255, 99, 132)',
})

/**
 * Build the clinic data set item for the radar chart
 * @param {Object} param0 - The clinic data set item
 * @param {Array} param0.clinics - The clinic data to use in a given dataset
 * @returns {Object} - The clinic data set item
 */
export const buildClinicDatasetItem = ({ clinics = [] }) => ({
  label: 'Clinic',
  data: clinics,
  fill: true,
  backgroundColor: 'rgba(54, 162, 235, 0.2)',
  borderColor: 'rgb(54, 162, 235)',
  pointBackgroundColor: 'rgb(54, 162, 235)',
  pointBorderColor: '#fff',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgb(54, 162, 235)',
})
