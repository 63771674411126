import React from 'react'
import { flexRender } from '@tanstack/react-table'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import ArrowIcon from '../../tableComponents/ArrowIcon'
import { ELLIPSIS_STYLES } from '../../Table.constants'

export const SortableTableHeaderCell = ({ header, colIndex, rowHeight }) => {
  const [isHovering, setIsHovering] = React.useState(false)

  const canSort = header.column.getCanSort()
  const sortValue = header.column.getIsSorted() // value is 'asc', 'desc', or false
  const shouldDisplayHoverIcon = canSort && isHovering && !sortValue
  const sortingHandler = header.column.getToggleSortingHandler()

  return (
    <TableCell
      aria-colindex={colIndex}
      component="div"
      onClick={sortingHandler}
      onKeyDown={e => e.key === 'Enter' && sortingHandler(e)} // allows for tabbing through options and checking/unchecking option with enter key on keyboard
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      role="columnheader"
      tabIndex={sortValue ? 0 : -1}
      sx={{
        alignItems: 'center',
        color: '#000',
        display: 'flex',
        flex: header.column.columnDef.flex || 1,
        height: rowHeight,
        maxWidth: '100%',
        paddingX: 1,
        paddingY: 0, // required because default padding is applied otherwise
        width: header.column.getSize(),
        '&:hover': {
          cursor: canSort ? 'pointer' : 'default',
        },
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          ...ELLIPSIS_STYLES,
        }}
      >
        <Box
          sx={{
            height: '100%',
            paddingRight: canSort ? 1 : 0,
            ...ELLIPSIS_STYLES,
          }}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
        </Box>
        {canSort && (
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderRadius: '50%',
              display: 'flex',
              height: '28px',
              width: '28px',
              justifyContent: 'center',
              '&:hover': {
                // this background color is applied when arrow is visible from hover OR when a sort is applied
                backgroundColor: sortValue || shouldDisplayHoverIcon ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0)',
              },
            }}
          >
            {shouldDisplayHoverIcon ? (
              <ArrowIcon direction="up" opacity={0.5} />
            ) : (
              <>
                {{
                  asc: <ArrowIcon direction="up" />,
                  desc: <ArrowIcon direction="down" />,
                }[sortValue] ?? null}
              </>
            )}
          </Box>
        )}
      </Box>
    </TableCell>
  )
}
