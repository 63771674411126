import React from 'react'
import { useFilterContext } from '../../context/FilterContext/useFilterContext'
import { useAppContext } from '../../context/AppContext/useAppContext'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import BenchmarkPerformanceCard from './pageComponents/BenchmarkPerformanceCard/BenchmarkPerformanceCard'
import BenchmarkTrendGraph from '../../components/BenchmarkTrendGraph/BenchmarkTrendGraph'
import PerformanceByGeography from './pageComponents/PerformanceByGeography/PerformanceByGeography'
import { buildSelectedUOM } from '../../context/FilterContext/filterContextHelpers'
import DimensionAnalysis from './pageComponents/DimensionAnalysis/DimensionAnalysis'
import withRouterWrapper from '../../components/withRouterWrapper/withRouterWrapper'
import TitledDropdown from '../../components/titledDropdown'
import LoadingSkeleton from '../../components/LoadingSkeleton'

const DEFAULT_METRIC_OPTION = {
  value: 'completed_appointments',
  name: 'Completed Appointments',
}

const DEFAULT_DIMENSION_OPTION = {
  value: 'clinic_feature_weekly_therapist_vol',
  name: 'Clinic Size',
}

const BenchmarkExplorer = () => {
  const theme = useTheme()
  const { menuOptions, isLoadingFetchMenuOptions } = useAppContext()
  const { unitOfMeasurement, unitOfTime } = useFilterContext()

  const [selectedMetric, setSelectedMetric] = React.useState(DEFAULT_METRIC_OPTION)
  const [selectedValueDisplay, setSelectedValueDisplay] = React.useState('actuals')
  const [selectedDimension, setSelectedDimension] = React.useState(DEFAULT_DIMENSION_OPTION)
  const [isLoadingTrendSpinner, setIsLoadingTrendSpinner] = React.useState(true)
  const [isLoadingSpinnerEfficiencyTable, setIsLoadingSpinnerEfficiencyTable] = React.useState(true)
  const [isLoadingBenchmarkByState, setIsLoadingBenchmarkByState] = React.useState(true)
  const [isLoadingByDimension, setIsLoadingByDimension] = React.useState(true)

  const handleSetSelectedValueDisplay = React.useCallback(
    id => {
      setSelectedValueDisplay(id)
    },
    [setSelectedValueDisplay],
  )

  const metricList = React.useMemo(
    () =>
      menuOptions
        ?.filter(opt => opt.menu === 'metrics')
        ?.map(filteredOpt => ({ value: filteredOpt.option_id, name: filteredOpt.option_value })) || [],
    [menuOptions],
  )

  const unitOfMeasure = React.useMemo(
    () => buildSelectedUOM({ unitOfMeasurement, unitOfTime }),
    [unitOfMeasurement, unitOfTime],
  )

  const isDisabled =
    isLoadingSpinnerEfficiencyTable || isLoadingTrendSpinner || isLoadingBenchmarkByState || isLoadingByDimension
  return (
    <>
      <Grid
        container
        padding={2}
        sx={{
          background: theme.palette.background.paper,
          display: 'flex',
          alignItems: 'center',
          marginBottom: 1,
          marginTop: { xs: 2, md: 0 },
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography sx={{ fontSize: '26px', fontWeight: 700 }}>Benchmark Explorer</Typography>

          <Box
            sx={{
              maxWidth: {
                xs: '100%',
                sm: '300px',
                md: '500px',
              },
              minHeight: '65px',
              height: '100%',
              width: '100%',
              paddingTop: 2,
            }}
          >
            <TitledDropdown
              id="benchmark-explorer-metric-dropdown"
              label="Efficiency Metric"
              isLoading={isLoadingFetchMenuOptions}
              isDisabled={isDisabled}
              menuOptions={metricList}
              value={selectedMetric.value}
              onChange={(e, i) => {
                setSelectedMetric({
                  value: e.target.value,
                  name: i.props.name,
                })
              }}
              selectStyles={{ borderRadius: '5px', width: '100%', height: '65px' }}
              formControlStyles={{ width: '100%' }}
              renderLoading={() => <LoadingSkeleton variant="rounded" height="65px" width="100%" />}
            />
          </Box>
        </Grid>
        <Grid item xs={0} sm={1} />
        <Grid item xs={12} sm={5}>
          <BenchmarkPerformanceCard
            unitOfMeasurement={unitOfMeasurement}
            unitOfTime={unitOfTime}
            selectedMetric={selectedMetric}
            isLoadingSpinnerEfficiencyTable={isLoadingSpinnerEfficiencyTable}
            setIsLoadingSpinnerEfficiencyTable={setIsLoadingSpinnerEfficiencyTable}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>Performance</Typography>
          </BenchmarkPerformanceCard>
        </Grid>
      </Grid>
      <Grid container sx={{ background: theme.palette.background.paper, marginBottom: 2 }}>
        <Grid item xs={12}>
          <BenchmarkTrendGraph
            selectedMetric={selectedMetric}
            selectedValueDisplay={selectedValueDisplay}
            handleSetSelectedValueDisplay={handleSetSelectedValueDisplay}
            isLoadingTrendSpinner={isLoadingTrendSpinner}
            setIsLoadingTrendSpinner={setIsLoadingTrendSpinner}
          />
        </Grid>
        <Grid item xs={12}>
          <PerformanceByGeography
            selectedMetric={selectedMetric}
            selectedValueDisplay={selectedValueDisplay}
            selectedDimension={selectedDimension}
            isLoadingBenchmarkByState={isLoadingBenchmarkByState}
            setIsLoadingBenchmarkByState={setIsLoadingBenchmarkByState}
          >
            <Box sx={{ padding: 2 }}>
              <Typography sx={{ fontSize: '16px' }}>Performance by Geography</Typography>
              <Typography color="#9FACB8" sx={{ fontWeight: 400, fontSize: 14 }}>
                {unitOfMeasure}
              </Typography>
            </Box>
          </PerformanceByGeography>
        </Grid>
      </Grid>
      <DimensionAnalysis
        handleDimensionSelect={(e, i) => {
          setSelectedDimension({
            value: e.target.value,
            name: i.props.name,
          })
        }}
        selectedValueDisplay={selectedValueDisplay}
        selectedDimension={selectedDimension}
        handleSetSelectedValueDisplay={handleSetSelectedValueDisplay}
        selectedMetric={selectedMetric}
        isLoadingByDimension={isLoadingByDimension}
        setIsLoadingByDimension={setIsLoadingByDimension}
      />
    </>
  )
}

export default withRouterWrapper(BenchmarkExplorer)({ shouldRedirectNewUser: true })
