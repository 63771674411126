/**
 * Checks whether the default scenario should be set as the selected scenario.
 *
 * @param {Object} options - The options object.
 * @param {Array} options.scenarios - The list of scenarios.
 * @param {string} options.selectedScenarioId - The ID of the selected scenario.
 * @param {string} options.nextSelectedScenarioId - The ID of the next selected scenario.
 * @returns {boolean} - Returns true if the default scenario should be set, otherwise false.
 */
export const checkShouldSetDefaultScenario = ({ scenarios, selectedScenarioId, nextSelectedScenarioId }) => {
  if (nextSelectedScenarioId) return false
  const scenarioIds = scenarios?.map(scenario => scenario.scenarioid)
  if (!selectedScenarioId && scenarios.length > 0) return true

  return !!selectedScenarioId && !scenarioIds.includes(selectedScenarioId)
}
