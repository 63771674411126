import { createTheme } from '@mui/material'
import WebFont from 'webfontloader'

// Call Google Webfonts
WebFont.load({
  google: {
    families: ['Lato:300,400,700', 'sans-serif'],
  },
})

export const globalTheme = createTheme({
  palette: {
    background: {
      default: '#F5F8F9',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Lato',
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          backgroundColor: 'unset',
          color: '#2B4561',
          top: -6,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          background: '#FFF',
          color: '#8796A6',
          fontSize: 14,
          margin: 0,
        },
        body: {
          fontSize: 14,
          padding: 1,
          border: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
          borderColor: '#96B6FB',
          height: '20px',
        },
        select: {
          minHeight: '0',

          width: '100%',
        },
        input: {
          minHeight: '0',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCardContent: {
      defaultProps: {
        sx: { padding: 0 },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '8pt',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '95px',
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: { boxShadow: 0 },
      },
      variants: [
        {
          props: { variant: 'contained', classes: 'active' },

          style: {
            backgroundColor: '#EBEBEB',
            borderRadius: '15px',

            color: '#282E34',
            fontFamily: 'Lato',
            fontSize: '10pt',
            margin: '3px',
            '&:hover': {
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'contained', classes: 'inactive' },

          style: {
            backgroundColor: '#FFFFFF',
            borderRadius: '15px',
            border: '1px solid #EBEBEB',
            color: '#282E34',
            margin: '3px',
            fontFamily: 'Lato',
            fontSize: '10pt',
            boxShadow: 0,
            '&:hover': {
              color: '#fff',
            },
          },
        },
        {
          props: { variant: 'outlined', classes: 'spaced' },

          style: {
            backgroundColor: 'transparent',
            borderRadius: '15px',

            color: '#354052',
            margin: '3px',
            fontFamily: 'Lato',
            fontSize: '10pt',
            boxShadow: 0,
          },
        },
      ],
    },
  },
})
