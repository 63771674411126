import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppContext } from '../../context/AppContext/useAppContext'
import { APP_PAGES_MAP } from '../navbar/navbar.constants'
import LoadingPage from '../../pages/loadingPage'

/**
 * A higher order component that wraps the given component with logic to handle
 * first time user redirects and clears query params added from auth0.
 *
 * IMPORTANT -> This component was written with the assumption that there is only 1 query params
 * and starting to introduce additional query params will require updating this component.
 *
 * @param {React.Component} WrappedComponent The component to wrap
 * @returns {React.Component} The wrapped component that accepts the same props as the original
 *
 * @example
 * import withRouterWrapper from './withRouterWrapper'
 * const My Component = () => {
 *  return <div>My Component</div>
 * }
 * export default withRouterWrapper(MyComponent)({ shouldRedirectNewUser: true })
 */
const withRouterWrapper = WrappedComponent => {
  return ({ shouldRedirectNewUser }) => {
    return function WithRouterWrapper(props) {
      const location = useLocation()
      const navigate = useNavigate()
      const { defaultScenarioId, isLoadingFetchUserPreferences } = useAppContext()

      React.useEffect(() => {
        if (isLoadingFetchUserPreferences || !location.search.includes('?code=')) return
        // The following line clears http://localhost:8443/?code=YQ727NxQZW2 added by auth0 on login
        navigate(location.pathname, { replace: true })
      }, [navigate, location.pathname, location.search, isLoadingFetchUserPreferences])

      React.useEffect(() => {
        if (isLoadingFetchUserPreferences || defaultScenarioId || !shouldRedirectNewUser) return

        const shouldRedirect = !defaultScenarioId && location.pathname !== APP_PAGES_MAP.DATA_MANAGER.path
        if (!shouldRedirect) return

        navigate(APP_PAGES_MAP.DATA_MANAGER.path)
      }, [location.pathname, defaultScenarioId, isLoadingFetchUserPreferences, navigate])

      if (isLoadingFetchUserPreferences && !shouldRedirectNewUser) {
        return <LoadingPage />
      }

      return <WrappedComponent {...props} />
    }
  }
}

export default withRouterWrapper
