import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, Box } from '@mui/material'
import TitledDropdown from '../../../../../components/titledDropdown'
import LoadingSkeleton from '../../../../../components/LoadingSkeleton'

/**
 * Renders the header component for the Best In Class Analysis page.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleYearSelect - The function to handle year selection.
 * @param {boolean} props.isLoadingYearList - Indicates if the year list is currently being loaded.
 * @param {string} props.selectedYear - The selected year.
 * @param {boolean} props.shouldDisableYearDropdown - Indicates if the year dropdown should be disabled.
 * @param {string} props.unitOfMeasure - The unit of measure.
 * @param {Array} props.yearList - The list of available years.
 * @returns {JSX.Element} The rendered component.
 */
export const BestInClassHeader = ({
  handleYearSelect,
  isLoadingYearList,
  selectedYear,
  shouldDisableYearDropdown = false,
  unitOfMeasure,
  yearList,
}) => (
  <Grid container>
    <Grid item xs={12} sm={8} lg={6} marginTop={1}>
      <Typography sx={{ fontWeight: 700, fontSize: '32px' }} display="flex" alignItems="center">
        Best In Class Analysis
      </Typography>
    </Grid>
    <Grid item xs={0} lg={3}></Grid>
    <Grid item xs={12} sm={4} lg={3} marginTop={1} sx={{ display: 'flex', direction: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ maxWidth: '400px' }}>
          <TitledDropdown
            id="best-in-class-analysis-year-dropdown"
            label="Year"
            isLoading={isLoadingYearList}
            menuOptions={yearList}
            value={selectedYear}
            onChange={handleYearSelect}
            formControlStyles={{ width: '100%' }}
            selectStyles={{ height: '56px', borderRadius: '5px', width: '100%' }}
            isDisabled={shouldDisableYearDropdown}
            renderLoading={() => (
              <LoadingSkeleton
                variant="rounded"
                width="100%"
                height="56px"
                data-testid="loading-best-in-class-year-dropdown"
              />
            )}
          />
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12} lg={12} sx={{ display: 'flex', direction: 'column', alignItems: 'center' }}>
      <Typography color="#9FACB8" sx={{ fontWeight: 400, fontSize: 14 }}>
        {unitOfMeasure}
      </Typography>
    </Grid>
    <Grid item xs={12} lg={6} marginTop={1}>
      <Typography sx={{ fontWeight: 400, fontSize: '13px' }}>
        The best-in-class analysis allows you to compare the operating characteristics of clinics who are top performers
        for a specific area of the metric funnel below.
      </Typography>
    </Grid>
  </Grid>
)

BestInClassHeader.propTypes = {
  handleYearSelect: PropTypes.func.isRequired,
  isLoadingYearList: PropTypes.bool.isRequired,
  selectedYear: PropTypes.number,
  shouldDisableYearDropdown: PropTypes.bool,
  unitOfMeasure: PropTypes.string, // e.g. 'Per Provider Per Month'
  yearList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.number,
    }),
  ),
}
