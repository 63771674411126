import React from 'react'
import PropTypes from 'prop-types'
import CircularButton from './CircularButton'

/**
 * Renders a group of circular buttons.
 *
 * @param {Object[]} options - The options for the circular buttons.
 * @param {string} options[].id - The unique identifier for the option.
 * @param {string} options[].displayText - The text to display on the button.
 * @param {string} selectedButtonId - The ID of the currently selected button.
 * @param {function} handleClick - The function to handle button click events.
 * @param {Object} buttonProps - Additional props to be passed to the circular buttons.
 * @returns {JSX.Element} The rendered circular button group.
 */
export const CircularButtonGroup = ({ options, selectedButtonId, handleClick, ...buttonProps }) => {
  const buttonGroup = options.map(option => {
    return (
      <CircularButton
        key={option.id}
        isActive={option.id === selectedButtonId}
        onClick={() => handleClick(option.id)}
        {...buttonProps}
      >
        {option.displayText}
      </CircularButton>
    )
  })

  return <>{buttonGroup}</>
}

CircularButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      displayText: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedButtonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleClick: PropTypes.func.isRequired,
  buttonProps: PropTypes.object,
}

export default CircularButtonGroup
