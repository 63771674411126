import PropTypes from 'prop-types'

export const formikPropTypes = {
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired, // should be overriden in form to be more specific
  getFieldHelpers: PropTypes.func.isRequired,
  getFieldMeta: PropTypes.func.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialErrors: PropTypes.objectOf(PropTypes.string).isRequired, // if used, the prop type should be more specific to form fields
  initialStatus: PropTypes.object,
  initialTouched: PropTypes.objectOf(PropTypes.bool).isRequired, // should be overriden in form to be more specific
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired, // should be overriden in form to be more specific
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  registerField: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  status: PropTypes.any,
  submitCount: PropTypes.number.isRequired,
  submitForm: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired, // should be overriden in form to be more specific
  unregisterField: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  validateOnBlur: PropTypes.bool.isRequired,
  validateOnChange: PropTypes.bool.isRequired,
  validateOnMount: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired, // should be overriden in form to be more specific
}
