import React from 'react'
import PropTypes from 'prop-types'
import { defaultScenario } from '../../../../../../services/apis'
import { useAuthContext } from '../../../../../../context/AuthContext/useAuthContext'
import { useFilterContext } from '../../../../../../context/FilterContext/useFilterContext'
import { TOAST_IDS } from '../../../../../../context/ToastContext/constants'
import { useToastContext } from '../../../../../../context/ToastContext/useToastContext'
import { useAppContext } from '../../../../../../context/AppContext/useAppContext'
import ChipStyle from '../../../../../../components/ChipStyle/ChipStyle'
import LoadingSkeleton from '../../../../../../components/LoadingSkeleton'
import { Stack } from '@mui/material'

/**
 * Component for deleting a Scenario.
 * @param {Object} props - The component props.
 * @param {Object} props.row - The row data for the Scenario.
 * @param {Object} props.row.create_date - The creation date of the Scenario.
 * @param {Object} props.row.created_by_userid - The user ID of the creator.
 * @param {Object} props.row.id - The ID of the Scenario.
 * @param {Object} props.row.list_of_clinics - The list of clinics associated with the Scenario.
 * @param {Object} props.row.Scenario_description - The description of the Scenario.
 * @param {Object} props.row.Scenario_title - The title of the Scenario.
 * @param {Object} props.row.Scenarioid - The ID of the Scenario.
 * @returns {JSX.Element} The delete Scenario component.
 */
export const DefaultScenario = ({ row }) => {
  const { accessToken } = useAuthContext()
  const { refetchScenarios } = useFilterContext()
  const { addToast } = useToastContext()
  const { updateDefaultScenarioId } = useAppContext()
  const [isLoading, setIsLoading] = React.useState(false)

  /**
   * Handles the click event when deleting a row.
   * @param {Object} row - The row data for the Scenario.
   * @returns {Promise<void>} A promise that resolves when the Scenario is deleted.
   */
  const handleDefaultRowClick = async row => {
    try {
      setIsLoading(true)
      await defaultScenario({ accessToken: accessToken, scenarioid: row.scenarioid })
      addToast({
        toastId: TOAST_IDS.setDefaultScenarioSuccess,
        variant: 'success',
        msg: `The default scenario has been set to "${row.scenario_title}".`,
      })

      // refetch Scenarios on success to update table rows
      await refetchScenarios({
        accessToken,
        errorToastId: TOAST_IDS.refetchScenariosErrForScenarioManagerCard,
      })
      await updateDefaultScenarioId(row.scenarioid)
    } catch (error) {
      console.error(error)
      addToast({
        toastId: TOAST_IDS.setDefaultScenarioError,
        variant: 'error',
        msg: error.message,
        truncateLength: 300, // increase truncate length so that more scenario names are visible with 422 error
      })
    } finally {
      setIsLoading(false)
    }
  }

  const isDefaultVal = row.default_scenario_flag
  if (isLoading) {
    return <LoadingSkeleton variant="rounded" height="35px" width="50%" sx={{ borderRadius: '35px' }} />
  }

  return (
    <Stack>
      {isDefaultVal ? (
        <ChipStyle
          data-testid={`default-scenario-${row.scenarioid}`}
          isDefault={isDefaultVal}
          sx={{ backgroundColor: '#808080', color: '#FFFFFF' }}
        />
      ) : (
        <ChipStyle
          data-testid={`default-scenario-${row.scenarioid}`}
          isDefault={isDefaultVal}
          onClick={() => handleDefaultRowClick(row)}
        />
      )}
    </Stack>
  )
}

DefaultScenario.propTypes = {
  row: PropTypes.shape({
    scenarioid: PropTypes.string.isRequired,
  }).isRequired,
}
