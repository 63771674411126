import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CreatePortfolioForm, {
  initialValuesForCreatePortfolioForm,
  createPortfolioValidationSchema,
  initialTouchedForCreatePortfolioForm,
} from '../../../../../forms/CreatePortfolioForm'
import { Formik } from 'formik'
import { Save } from '@mui/icons-material'
import BaseModalContent from '../../../../../components/modals/BaseModalContent'
import StyledFormDialog from '../../../../../components/modals/FormModal/StyledFormDialog'
import { TOAST_IDS } from '../../../../../context/ToastContext/constants'
import { useToastContext } from '../../../../../context/ToastContext/useToastContext'
import { useAuthContext } from '../../../../../context/AuthContext/useAuthContext'
import { useSidebarContext } from '../../../../../context/SidebarContext/useSidebarContext'
import { createPortfolio } from '../../../../../services/apis'
import { ApiError } from '../../../../../utils/ApiError'
import ErrorMessage from '../../../../../components/ErrorMessage'

/**
 * We use a no-op function to handle the onClose event for the modal
 * to require the member to either click the "X" button or to click the "Cancel" button.
 * With this function, users will not be able to click "ESC" key to exit or click outside the modal to exit.
 *
 */
const blockExitFromModal = () => undefined

/**
 * Renders the Create Portfolio Modal & Form.
 *
 * @returns {React.ReactNode} The Create Portfolio component.
 */
export function CreatePortfolio() {
  const { addToast } = useToastContext()
  const { accessToken } = useAuthContext()
  const { refetchPortfolios } = useSidebarContext()

  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false)
  const [formLevelError, setFormLevelError] = React.useState(undefined)

  const handleSubmit = async (formikValues, formikActions) => {
    setFormLevelError(undefined)
    const { setFieldError } = formikActions
    try {
      // Execute Save Call
      const createdPortfoio = await createPortfolio({
        accessToken,
        data: {
          portfolio_title: formikValues.portfolio_title,
          list_of_clinics: formikValues.list_of_clinics,
        },
      })

      setIsCreateDialogOpen(false)
      addToast({
        toastId: TOAST_IDS.createPortfolioSuccess,
        variant: 'success',
        msg: `Portfolio "${createdPortfoio?.portfolio_title}" was created successfully.`,
      })

      // refetch all the portfolios so that the created portfolio is displayed in the list (note that error is handled in refetchPortfolios)
      await refetchPortfolios({ accessToken, errorToastId: TOAST_IDS.getPortfoliosErrorForPortfolioCard })
    } catch (error) {
      if (error instanceof ApiError) {
        // check for field level errors
        if (['portfolio_title', 'list_of_clinics'].includes(error?.errorTarget)) {
          return setFieldError(error.errorTarget, error?.message) // sets error in appropriate form field
        }
      }

      setFormLevelError(error.message) // Note that this covers ApiError & Error because both have a message property
    }
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="info"
          variant="contained"
          role="button"
          onClick={() => setIsCreateDialogOpen(true)}
          sx={{ maxWidth: '200px' }}
        >
          Create Portfolio
        </Button>
      </Box>
      {/* 
          Note that StyledFormDialog does not render unless isCreateDialogOpen is true.
          Then, StyledFormDialog will render as a React portal. 
      */}
      <StyledFormDialog onClose={blockExitFromModal} open={isCreateDialogOpen}>
        <Formik
          initialValues={initialValuesForCreatePortfolioForm}
          initialTouched={initialTouchedForCreatePortfolioForm}
          onSubmit={handleSubmit}
          validationSchema={createPortfolioValidationSchema}
        >
          {formikProps => {
            return (
              <BaseModalContent
                handleClickCloseButton={() => setIsCreateDialogOpen(false)}
                hasContentDividers={true}
                modalTitle="Create Portfolio"
                closeIconBtnAttributes={{
                  'data-testid': 'create-portfolio-close-button',
                  disabled: formikProps.isSubmitting,
                }}
                renderModalFooter={() => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingX: 1,
                      marginY: 1,
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    {formLevelError && (
                      <ErrorMessage sx={{ fontSize: { xs: '12px', sm: '14px' }, paddingRight: 1 }}>
                        {formLevelError}
                      </ErrorMessage>
                    )}
                    <Box sx={{ minWidth: '240px' }}>
                      <Button
                        variant="outlined"
                        onClick={() => setIsCreateDialogOpen(false)}
                        sx={{ marginRight: 1 }}
                        color="error"
                        disabled={formikProps.isSubmitting}
                        data-testid="create-portfolio-cancel-button"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        startIcon={<Save />}
                        onClick={formikProps.handleSubmit}
                        disabled={formikProps.isSubmitting}
                        data-testid="create-portfolio-save-button"
                      >
                        Save Portfolio
                      </Button>
                    </Box>
                  </Box>
                )}
              >
                <CreatePortfolioForm {...formikProps} />
              </BaseModalContent>
            )
          }}
        </Formik>
      </StyledFormDialog>
    </>
  )
}
