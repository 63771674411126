const sortBySelected = (isRowASelected, isRowBSelected) => {
  if (isRowASelected && !isRowBSelected) return -1
  if (!isRowASelected && isRowBSelected) return 1
  return 0
}

export const composeSortBySelectedItems = ({ selectedValuesList, targetPropertyName }) => {
  return (rowA, rowB, columnIndex) => {
    const isRowASelected = selectedValuesList.includes(rowA?.original?.[targetPropertyName])
    const isRowBSelected = selectedValuesList.includes(rowB?.original?.[targetPropertyName])
    return sortBySelected(isRowASelected, isRowBSelected)
  }
}

/**
 * THE FOLLOWING ARE BUILT-IN FUNCTIONS FOR REACT TABLE SORTING
 * AND SHOULD ONLY BE USED IF FRONT-END SORTING IS ENABLED
 *
 * https://tanstack.com/table/latest/docs/guide/sorting#sorting-fns
 */

/**
 * Sorts by mixed alphanumeric values without case-sensitivity.
 * Slower, but more accurate if your strings contain numbers that need to be naturally sorted.
 */
export const SORT_COLUMN_BY_ALPHANUMERIC_INSENSITIVE = 'alphanumeric'

/**
 * Sorts by mixed alphanumeric values with case-sensitivity.
 * Slower, but more accurate if your strings contain numbers that need to be naturally sorted.
 */
export const SORT_COLUMN_BY_ALPHANUMERIC_SENSITIVE = 'alphanumericCaseSensitive'

/**
 * Sorts by text/string values without case-sensitivity.
 * Faster, but less accurate if your strings contain numbers that need to be naturally sorted.
 */
export const SORT_COLUMN_BY_TEXT_INSENTIVE = 'text'

/**
 * Sorts by text/string values with case-sensitivity. Faster, but less accurate if your strings contain numbers that need to be naturally sorted.
 */
export const SORT_COLUMN_TEXT_SENSITIVE = 'textCaseSensitive'

/**
 * Sorts by time, use this if your values are Date objects.
 */
export const SORT_COLUMN_BY_DATE_TIME = 'datetime'

/**
 * Sorts using a basic/standard a > b ? 1 : a < b ? -1 : 0 comparison.
 * This is the fastest sorting function, but may not be the most accurate.
 *
 * Recommended for boolean values.
 */
const SORT_BY_BASIC = 'basic'

// highlighting to use this value with boolean because fastest
export const SORT_COLUMN_BY_BOOLEAN = SORT_BY_BASIC
