import React from 'react'
import { Card, CardContent, Grid } from '@mui/material'

import StateMap from '../stateMap/stateMap.js'

export default function MapCard(props) {
  const data = props.data
  const geoMapStatesData = props.geoMapStatesData

  return (
    <Card sx={{ paddingX: 2, margin: 0 }}>
      <CardContent>
        <Grid
          container
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="center"
          padding={0}
          maxHeight="600px"
          maxWidth="100%"
        >
          {data ? (
            <StateMap
              data={data}
              geoMapStatesData={geoMapStatesData}
              selectedValueDisplay={props.selectedValueDisplay}
            />
          ) : null}
        </Grid>
        <Grid
          container
          display="flex"
          direction="column"
          alignItems="center"
          justifyContent="center"
          paddingBottom={0}
          maxHeight="600px"
          maxWidth="90%"
        >
          <Grid item lg={4} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            {props.selectedValueDisplay === 'yoy' ? (
              <color-legend
                range='[
                  "#cfebff",
                  "#b7dbf8",
                  "#a0caf1",
                  "#8bbaeb",
                  "#78a9e4",
                  "#6798dc",
                  "#5887d4",
                  "#4c75cb",
                  "#4463c0",
                  "#3f51b5"]'
                domain={props.metadata.domain}
                tickFormat=".0%"
                titleText=""
                scaleType="continuous"
              ></color-legend>
            ) : (
              <color-legend
                range='[
                  "#cfebff",
                  "#b7dbf8",
                  "#a0caf1",
                  "#8bbaeb",
                  "#78a9e4",
                  "#6798dc",
                  "#5887d4",
                  "#4c75cb",
                  "#4463c0",
                  "#3f51b5"]'
                domain={props.metadata.domain}
                tickFormat=".2f"
                titleText=""
                scaleType="continuous"
              ></color-legend>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
