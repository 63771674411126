import React from 'react'
import { Card, CardContent, Box, Skeleton } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export default function LineGraphCard({
  children,
  data,
  height = '500px',
  isLoading,
  marginBottom,
  selectedBenchmark,
  selectedValueDisplay,
}) {
  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: function () {
            if (selectedValueDisplay === 'yoy') {
              return '% Change,  Year Over Year'
            } else if (selectedValueDisplay === 'actuals') {
              return selectedBenchmark
            } else if (selectedValueDisplay === 'index') {
              return [selectedBenchmark, '(Index, Actuals vs. Benchmark)']
            }
          },
          font: {
            family: 'Lato', // Add your font here to change the font of your y axis
          },
        },
        ticks: {
          font: {
            family: 'Lato', // Add your font here to change the font of your y axis
          },

          callback: function (label) {
            let updatedLabel = label * 100

            if (selectedValueDisplay === 'yoy') {
              return Math.round(updatedLabel * 100) / 100 + '%'
            } else {
              return Math.round(label * 100) / 100
            }
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'Lato', // Add your font here to change the font of your y axis
          },
        },
      },
    },

    responsive: true,

    maintainAspectRatio: false,
    animations: {
      tension: {
        duration: 500,
        easing: 'linear',
        from: 0.25,
        to: 0,
        loop: false,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (item, data) {
            let updatedLabel = item.raw * 100
            if (selectedValueDisplay === 'yoy') {
              return Math.round(updatedLabel * 100) / 100 + '%'
            } else {
              return Math.round(item.raw * 100) / 100
            }
          },
        },
      },
      legend: {
        align: 'left',
        position: 'bottom',
        font: {
          family: 'Lato', // Add your font here to change the font of your y axis
        },
      },
      title: {
        display: false,
      },
    },
  }

  return (
    <Card sx={{ marginBottom: marginBottom, padding: 2, minHeight: height }}>
      <CardContent>
        {children}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBlock: 0,
            maxWidth: '100%',
            maxHeight: height,
          }}
        >
          <>
            {isLoading ? (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={height} />
            ) : (
              <>
                {data?.labels && data?.datasets ? (
                  <Line height={height} options={options} data={{ labels: data?.labels, datasets: data?.datasets }} />
                ) : null}
              </>
            )}
          </>
        </Box>
      </CardContent>
    </Card>
  )
}
