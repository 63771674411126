import React from 'react'
import PropTypes from 'prop-types'
import Edit from '@mui/icons-material/Edit'
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import { Box, IconButton } from '@mui/material'
import { useSidebarContext } from '../../../../../../context/SidebarContext/useSidebarContext'
import DeletePortfolio from '../DeletePortfolio'

import { InfiniteScrollTable } from '../../../../../../components/Table'
import { rowsWithRowId1, rowsWithRowId2, rowsWithRowId3 } from './mockApiData'

const TABLE_HEIGHT = 310
const ICON_BUTTON_PADDING = 1
const totalRowCount = 60

const mockFetchData = async ({ start, fetchSize, sorting }) => {
  console.log('MOCK_FETCH_PARAMS', {
    start,
    fetchSize,
    sorting,
  })
  if (start === 0) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          data: rowsWithRowId1,
          meta: {
            totalRowCount: totalRowCount,
          },
        })
        // resolve({
        //   data: [],
        //   meta: {
        //     totalRowCount: 0,
        //   },
        // })
      }, 1000)
    })
  }

  if (start === 20) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          data: rowsWithRowId2,
          meta: {
            totalRowCount: totalRowCount,
          },
        })
      }, 1000)
    })
  }

  if (start === 40) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          data: rowsWithRowId3,
          meta: {
            totalRowCount: totalRowCount,
          },
        })
      }, 1000)
    })
  }
}

/**
 * Renders a table component for displaying portfolios.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isUnitTest - A flag to determine if the component is being rendered in a unit test.
 * @param {Function} props.handleEditRowClick - The function to handle when a row is clicked.
 * @returns {JSX.Element} The rendered table component.
 */
export function InfiniteScrollPortfolioTable({ handleEditRowClick }) {
  const columns = React.useMemo(
    () => [
      {
        id: 'id',
        accessorFn: row => row.id,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 1,
        header: () => <span>ID</span>,
        sortDescFirst: false,
        // footer: props => props.column.id,
      },
      {
        id: 'portfolio_title',
        accessorFn: row => row.portfolio_title,
        cell: info => info.getValue(),
        enableSorting: true,
        flex: 3,
        header: 'Portfolio Title',
        // footer: props => props.column.id,
      },
      {
        id: 'edit',
        accessorKey: 'Edit',
        header: () => <Box paddingX={ICON_BUTTON_PADDING}>Edit</Box>, // We do this so that the padding matches the icon button padding for alignment purposes
        cell: params => (
          <IconButton
            data-testid={`edit-portfolio-${params.row.portfolioid}`}
            onClick={() => handleEditRowClick(params.row)}
            sx={{ color: '#707070', cursor: 'pointer', padding: ICON_BUTTON_PADDING }}
          >
            <Edit />
          </IconButton>
        ),
        enableSorting: false,
        flex: 1,
      },
      {
        id: 'delete',
        accessorKey: 'Delete',
        header: () => <Box paddingX={ICON_BUTTON_PADDING}>Delete</Box>, // We do this so that the padding matches the icon button padding for alignment purposes
        cell: params => <DeletePortfolio row={params.row} iconButtonStyles={{ padding: ICON_BUTTON_PADDING }} />, // what renders in a given table cell
        enableSorting: false,
        flex: 1,
      },
    ],
    [handleEditRowClick],
  )

  const FETCH_SIZE = 20

  const [sorting, setSorting] = React.useState([{ id: 'id', desc: false }])

  // react-query has a useInfiniteQuery hook that is perfect for this use case
  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
    queryKey: [
      'portfolio',
      sorting, //refetch when sorting changes
    ],
    queryFn: async ({ pageParam = 0 }) => {
      const start = pageParam * FETCH_SIZE
      return await mockFetchData({ start, fetchSize: FETCH_SIZE, sorting })
    },
    initialPageParam: 0,
    getNextPageParam: (_lastGroup, groups) => groups.length,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  /**
   * ALTHOUGH SORTING DOES NOT APPEAR TO WORK IN THE UI, IT WILL AFTER USING A REAL ENDPOINT.
   * IF YOU LOOK AT mockFetchData, YOU WILL SEE THAT THE SORT VALUE GETS PASSED AS A PARAM
   */
  return (
    <InfiniteScrollTable
      data={data}
      columns={columns}
      fetchNextPage={fetchNextPage}
      isFetching={isFetching}
      isLoading={isLoading} // This is really "isInitialLoad" and isFetching is true when loading more records while this is false
      sorting={sorting}
      setSorting={setSorting}
      tableHeight={TABLE_HEIGHT}
      fetchMoreThreshold={500} // this value will vary depending on the height of the table, table rows, and the number of items fetched
      renderFetchingMsg={table => {
        if (isLoading) return null // do not display fetching message on initial load when no data (loading overlay will handle this)
        return (
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Loading portfolios...
          </Box>
        )
      }}
      noRecordsOverlay={<div>No portfolios found.</div>}
    />
  )
}

InfiniteScrollPortfolioTable.propTypes = {
  handleEditRowClick: PropTypes.func.isRequired,
}
