import React from 'react'
import { AppContext } from './AppContext'

/**
 * useAppContext is a custom hook that returns the AppContext, which includes any data
 * that is needed across the entire application or data for expensive requests.
 *
 * @returns {object} AppContext
 * @returns {object.string} object.defaultScenarioId - the default scenario id
 * @returns {object.boolean} object.isLoadingFetchMenuOptions - whether the menu options are loading
 * @returns {object.boolean} object.isLoadingFetchUserPreferences - whether the user preferences are loading
 * @returns {object.Array} object.menuOptions - the menu options
 * @returns {object.function} object.updateDefaultScenarioId - function to update the default scenario id
 * @returns {object.boolean} object.isLoadingFetchClinics - whether the clinics are loading
 * @returns {object.Array} object.clinicsSorted - the sorted clinics
 * @returns {object.object} object.fetchMenuOptionsError - the error from fetching menu options
 * @returns {object.object} object.fetchUserPreferencesError - the error from fetching user preferences
 * @returns {object.object} object.fetchClinicsError - the error from fetching clinics
 *
 * @throws {Error} Throws an error if used outside of the AppContext provider.
 */
export const useAppContext = () => {
  const context = React.useContext(AppContext) // see AppContextProvider for values that are returned

  if (context === undefined) {
    throw new Error('useAppContext must be used within the AppContext Provider')
  }

  return context
}
