import * as React from 'react'
import { createPortal } from 'react-dom'

import { useToastStack } from './ToastStack'
import { ToastVisual } from './ToastVisual'
import styled from '@emotion/styled'

/**
 * The default timeout in seconds for each variant of toast.
 */
const defaultTimeout = {
  default: Number(window?.env?.TOAST_DEFAULT_TIMEOUT_IN_S || 5),
  success: Number(window?.env?.TOAST_SUCCESS_TIMEOUT_IN_S || 5),
  warning: Number(window?.env?.TOAST_WARNING_TIMEOUT_IN_S || 5),
  error: Number(window?.env?.TOAST_ERROR_TIMEOUT_IN_S || 5),
}

/**
 * The style of the container which will contain the ToastVisual.
 * This element should position the contained ToastVisual when
 * injected at the end of the document body.
 */
const ToastPortalStyle = styled.div`
  position: fixed;
  width: 100%;
  bottom: 25px;
  text-align: center;
`

/**
 * A toast component which can be used to display notifications to the user.
 *
 * @param props The props for the toast.
 * @param props.variant The variant of the toast.
 * @param props.open Whether the toast is open.
 * @param props.timeout How long to show the toast for in seconds.  Set to 0 for no limit.
 * @param props.portalClassName Classname to be applied to the portal containing the Toast
 * @param props.portalId ID to be applied to the portal containing the Toast.
 * @param props.portalStyle Styles to be applied to the portal containing the Toast.
 * @param props.toastId The id of the toast. Used to identify the toast in the stack.
 * @param props.onRequestClose Called when the toast wants to close.  Either from a timeout, or from the close button.
 * @param props.children The content of the toast.
 */
const Toast = ({
  variant,
  open,
  timeout,
  portalClassName,
  portalId,
  portalStyle,
  toastId,
  onRequestClose,
  children,
  ...visualProps
}) => {
  if (timeout === undefined) {
    timeout = defaultTimeout[variant || 'default']
  }

  const toastStack = useToastStack()

  // We need a reference to onRequestClose to avoid restarting the timer
  //  if the function changes.  This can happen if someone calls
  //  us without using React.useCallback.
  const onRequestCloseRef = React.useRef()
  // Keep the ref up to date.
  React.useEffect(() => {
    onRequestCloseRef.current = onRequestClose
  }, [onRequestClose])

  // Create the timer if we open.
  //  This will restart the timer if the timeout changes.
  React.useEffect(() => {
    if (open && timeout) {
      let token = setTimeout(() => {
        const onRequestClose = onRequestCloseRef.current
        if (onRequestClose) {
          onRequestClose(toastId)
        }
        token = null
      }, timeout * 1000)
      return () => {
        if (token) {
          clearTimeout(token)
        }
      }
    }
  }, [open, timeout, toastId])

  if (!open) {
    return null
  }

  const visual = (
    <ToastVisual {...visualProps} variant={variant} onRequestClose={onRequestClose} toastId={toastId}>
      {children}
    </ToastVisual>
  )

  if (toastStack) {
    /*
    If we have a toast stack, portal the toast visual into it.
    We do not need ToastPortalStyle  in this case as the toast stack
    is responsible for our positioning.
    */
    return createPortal(visual, toastStack)
  } else {
    /*
    If we do not have a toast stack, we need to make our own portal.

    Inject the toast by targeting the document body with a portal.

    This will inject the component stack as the last child
    of the body, thereby placing it last in the natural tab order.

    The ToastPortalStyle is responsible for positioning the ToastVisual
    at the bottom center of the screen, with the ToastVisual
    containing the actual visual element of the Toast.
    */
    return createPortal(
      <ToastPortalStyle id={portalId} className={portalClassName} style={portalStyle}>
        {visual}
      </ToastPortalStyle>,
      document.body,
    )
  }
}

export default Toast
