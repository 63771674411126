import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid } from '@mui/material'
import BestInClassHeader from './BestInClassHeader'
import BestInClassCharts from './BestInClassCharts'
import { targetClinicRadarPropTypes } from '../../clinicExplorer.proptypes'
import { BEST_IN_CLASS_RADAR_LABELS } from './BestInClassAnalysis.constants'
import {
  adjustRadarChartDataForNoData,
  buildClinicDatasetItem,
  buildTargetDatasetItem,
  formatBestInClassClinicData,
} from './BestInClassAnalysis.helpers'

export const BestInClassAnalysis = ({
  benchmarkRadarChartGroup,
  handleYearSelect,
  isLoadingBestInClassCharts,
  isLoadingYearList,
  labelsRadarChartGroup,
  portfolioRadarChartGroup,
  selectedYear,
  shouldDisableYearDropdown = false,
  targetClinicRadar,
  unitOfMeasure,
  yearList,
}) => {
  const clinics = formatBestInClassClinicData(targetClinicRadar)
  const radarChartBenchmark = adjustRadarChartDataForNoData(benchmarkRadarChartGroup)
  const radarChartPortfolio = adjustRadarChartDataForNoData(portfolioRadarChartGroup)

  const finalBenchmarkData = {
    labels: labelsRadarChartGroup || BEST_IN_CLASS_RADAR_LABELS,
    datasets: [
      buildTargetDatasetItem({ label: 'Benchmark', data: clinics?.length ? radarChartBenchmark : [] }),
      buildClinicDatasetItem({ clinics }),
    ],
  }

  const finalPortfolioData = {
    labels: labelsRadarChartGroup || BEST_IN_CLASS_RADAR_LABELS,
    datasets: [
      buildTargetDatasetItem({ label: 'Portfolio', data: clinics?.length ? radarChartPortfolio : [] }),
      buildClinicDatasetItem({ clinics }),
    ],
  }

  return (
    <Card sx={{ marginBottom: 2, padding: 2 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <BestInClassHeader
              isLoadingYearList={isLoadingYearList}
              yearList={yearList}
              selectedYear={selectedYear}
              handleYearSelect={handleYearSelect}
              unitOfMeasure={unitOfMeasure}
              shouldDisableYearDropdown={shouldDisableYearDropdown}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: 2 }}>
            <BestInClassCharts
              isLoading={isLoadingBestInClassCharts}
              finalBenchmarkData={finalBenchmarkData}
              finalPortfolioData={finalPortfolioData}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

BestInClassAnalysis.propTypes = {
  benchmarkRadarChartGroup: PropTypes.arrayOf(PropTypes.string),
  handleYearSelect: PropTypes.func.isRequired,
  isLoadingBestInClassCharts: PropTypes.bool.isRequired,
  isLoadingYearList: PropTypes.bool.isRequired,
  labelsRadarChartGroup: PropTypes.arrayOf(PropTypes.oneOf(BEST_IN_CLASS_RADAR_LABELS)),
  portfolioRadarChartGroup: PropTypes.arrayOf(PropTypes.string),
  selectedYear: PropTypes.number,
  shouldDisableYearDropdown: PropTypes.bool,
  targetClinicRadar: PropTypes.arrayOf(
    PropTypes.shape({
      avg_scheduled_appointments: targetClinicRadarPropTypes['avg_scheduled_appointments'].isRequired,
      completed_appointments: targetClinicRadarPropTypes['completed_appointments'].isRequired,
      billable_visits: targetClinicRadarPropTypes['billable_visits'].isRequired,
      ie_visits: targetClinicRadarPropTypes['ie_visits'].isRequired,
      total_units_billed_per_visit: targetClinicRadarPropTypes['total_units_billed_per_visit'].isRequired,
    }).isRequired,
  ),
  unitOfMeasure: PropTypes.string.isRequired,
  yearList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
}
